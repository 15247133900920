import { Theme, withTheme } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import InviteFriendsScreen from '@pbl/pbl-react-web-components/lib/invite/v2/InviteScreen';
import styles from 'assets/jss/modules/earn/EarnScreenStyle';
import constants from 'config/constants';
import { useIsLoggedIn } from 'hooks/useIsLoggedIn';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getActivityByKey } from 'redux/reducers/activity/actions';
import { RootState } from 'redux/RootState';
import { scrollToTheTop } from 'utils/htmlUtil';

interface IInterestsScreenProps extends WithStyles<typeof styles> {
  theme: Theme;
}

const InviteScreen: React.FC<IInterestsScreenProps> = ({ classes }) => {
  const [inviteCode, setInviteCode] = useState<string>('');
  const [inviteUrl, setInviteUrl] = useState<string>('');
  const [inviteFriendsActivity, setInviteFriendsActivity] = useState<any>(null);
  const [inviteAcceptedActivity, setInviteAcceptedActivity] = useState<any>(null);
  const { account } = useSelector((state: RootState) => state.authentication);
  const isLoggedIn = useIsLoggedIn();

  useEffect(() => {
    document.title = 'Invite Friends';
    scrollToTheTop();
    fetchData();
  }, []);

  const fetchData = async () => {
    const inviteActivity = await getActivityByKey(constants.INVITE_FRIENDS_ACTIVITY_KEY, isLoggedIn);
    const inviteActivityAccepted = await getActivityByKey(constants.INVITE_ACCEPTED_ACTIVITY_KEY, isLoggedIn);
    const code = account?.inviteCode;
    setInviteCode(code as string);
    setInviteUrl(`${constants.WEB_APP_URL}/register/${code || ''}`);
    setInviteFriendsActivity(inviteActivity);
    setInviteAcceptedActivity(inviteActivityAccepted);
  };

  return (
    <div className={classes.container}>
      <InviteFriendsScreen
        inviteCode={inviteCode}
        inviteUrl={inviteUrl}
        inviteFriendsActivity={inviteFriendsActivity}
        inviteAcceptedActivity={inviteAcceptedActivity}
      />
    </div>
  );
};

export default withStyles(styles)(withTheme(InviteScreen));
