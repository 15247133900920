import { Grid, Hidden, withStyles } from '@material-ui/core';
import { WithStyles } from '@material-ui/styles';
import { IReward, RewardType } from '@pbl/pbl-react-core/lib/models/reward/types';
import { Hideable } from '@pbl/pbl-react-web-components/lib/hideable';
import { NBSP } from 'config/constants';
import { useRewardListData } from 'hooks/providers/useRewardListData';
import RewardCard from 'modules/home/components/RewardCard';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { isEnded, isEndingSoon } from 'utils/rewardUtil';
import styles from './RewardSectionStyle';

interface Props extends WithStyles<typeof styles> {
  type: RewardType;
  onRewardSelect: (reward: IReward) => void;
}

const RewardSectionComponent = ({ type }: Props) => {
  const { t } = useTranslation();
  const { rewards, couponDetails, featuredDetails } = useRewardListData(type);

  const getBanner = (r: IReward, idx: number, featured: boolean) => {
    const showEndedBanner = isEnded(r.end);
    const showEndingSoonBanner = r.type === RewardType.DRAW && isEndingSoon(r.endingSoon, r.entryEnd);
    const showSoldOutBanner = featured
      ? featuredDetails[idx] && featuredDetails[idx]?.remaining === 0
      : couponDetails[idx] && couponDetails[idx]?.remaining === 0;
    return showEndedBanner
      ? { showBanner: true, bannerText: t(`reward.rewardStatus.ended`), styleType: 'GrayedOut' }
      : showEndingSoonBanner
      ? { showBanner: true, bannerText: t(`reward.rewardStatus.endingSoon`), styleType: 'WhiteHighlight' }
      : showSoldOutBanner
      ? { showBanner: true, bannerText: t('reward.soldOut'), styleType: 'GrayedOut' }
      : {};
  };

  const rewardList = React.useMemo(
    () =>
      rewards?.map((r, idx: number) => {
        const banner = getBanner(r, idx, false);
        const textDescription = r.summary?.trim() || NBSP;
        return (
          <Grid item={true} xs={12} sm={12} md={4} key={`reward_card_${idx}`}>
            <RewardCard reward={r} banner={banner} categoryName={type} preview={textDescription} />
          </Grid>
        );
      }),
    [rewards, type, couponDetails, t]
  );

  return (
    <>
      <Hideable show={Boolean(rewards?.length)}>
        <Hidden smDown={true}>
          <Grid container={true} spacing={4} style={{ marginTop: 45 }}>
            {rewardList}
          </Grid>
        </Hidden>
        <Hidden mdUp={true}>
          <Grid container={true} spacing={4}>
            {rewardList}
          </Grid>
        </Hidden>
      </Hideable>
    </>
  );
};

export const RewardSection: any = withStyles(styles)(RewardSectionComponent);
