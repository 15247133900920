import { IError } from '@pbl/pbl-react-core/lib/models/app';
import { HistoryActivityList, HistoryDrawList, HistoryRewardsList, TicketEntryHistoryList } from '@pbl/pbl-react-core/lib/models/history';
import filterSections from '@pbl/pbl-react-core/lib/models/history/filters';
import {
  FETCH_DRAWS_BY_IDS,
  GET_TOTAL_PENDING_POINTS,
  IHistoryInitialState,
  IHistoryPage,
  IHistoryRewardPage,
  RESET_SELECTED_HISTORY_ITEM,
  RESET_STATE,
  RESET_USER_ACTIVITY_HISTORY,
  RESET_USER_TICKETS_ENTRY_HISTORY,
  SECOND_CHANCE_TICKET_GAME_HISTORY,
  SET_SELECTED_HISTORY_ITEM,
  USER_ACTIVITY_HISTORY,
  USER_ACTIVITY_HISTORY_FILTER,
  USER_DRAWS_HISTORY,
  USER_REWARDS_HISTORY,
  USER_TICKETS_ENTRY_HISTORY
} from '@pbl/pbl-react-core/lib/models/history/types';
import { IDispatchAction } from 'redux/reducers';
import FiltersEnhancer from 'shared/components/filters/filters.reducers-enhancer';
import { FAILURE, REQUEST, SUCCESS } from '../../action-type.util';

export interface IHistoryState extends IHistoryInitialState {
  historyPagination: { pages: number};
}

const INITIAL_STATE: IHistoryState = {
  loading: false,
  historyActivities: new HistoryActivityList(),
  historyRewardsPage: {} as IHistoryRewardPage,
  historyRewards: new HistoryRewardsList(),
  historyTicketEntries: new TicketEntryHistoryList(),
  errorMessage: {} as IError,
  ticketHistoryPage: {} as IHistoryPage,
  selectedHistoryItem: null,
  activitiesLastPage: false,
  rewardsLastPage: false,
  filters: filterSections,
  pendingPoints: 0,
  drawHistoryPage: {} as IHistoryPage,
  historyDraws: new HistoryDrawList(),
  drawSecondChanceTicketHistory: [],
  digitalGamesTokenHistory: [],
  historyPagination: { pages: 0 }
};

export type HistoryState = Readonly<IHistoryState>;
export default (state: HistoryState = INITIAL_STATE, action: IDispatchAction): HistoryState => {
  switch (action.type) {
    case REQUEST(USER_ACTIVITY_HISTORY):
    case REQUEST(USER_ACTIVITY_HISTORY_FILTER):
    case REQUEST(USER_REWARDS_HISTORY):
    case REQUEST(USER_TICKETS_ENTRY_HISTORY):
    case REQUEST(FETCH_DRAWS_BY_IDS):
    case REQUEST(USER_DRAWS_HISTORY):
    case REQUEST(SECOND_CHANCE_TICKET_GAME_HISTORY):
      return {
        ...state,
        loading: true
      };
    case SUCCESS(SECOND_CHANCE_TICKET_GAME_HISTORY):
      return {
        ...state,
        drawSecondChanceTicketHistory: action.payload
      };
    case SUCCESS(USER_ACTIVITY_HISTORY):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        activitiesLastPage: action.payload.lastPage,
        historyActivities: action.payload.content
      };
    case SUCCESS(USER_ACTIVITY_HISTORY_FILTER):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        activitiesLastPage: action.payload.lastPage,
        historyActivities: action.payload.content
      };
    case SUCCESS(USER_REWARDS_HISTORY):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        historyRewards: action.payload.content,
        rewardsLastPage: action.payload.rewardsLastPage,
        historyPagination: action.payload.paging
      };
    case SUCCESS(GET_TOTAL_PENDING_POINTS):
      return {
        ...state,
        pendingPoints: action.payload
      };
    case SUCCESS(USER_TICKETS_ENTRY_HISTORY):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        historyTicketEntries: action.payload.data,
        ticketHistoryPage: action.payload.page
      };
    case SUCCESS(FETCH_DRAWS_BY_IDS):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        selectedHistoryItem: action.payload
      };
    case SUCCESS(USER_DRAWS_HISTORY):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        historyDraws: action.payload.data,
        drawHistoryPage: action.payload.page
      };
    case FAILURE(USER_ACTIVITY_HISTORY):
    case FAILURE(USER_REWARDS_HISTORY):
    case FAILURE(USER_TICKETS_ENTRY_HISTORY):
    case FAILURE(FETCH_DRAWS_BY_IDS):
    case FAILURE(GET_TOTAL_PENDING_POINTS):
    case FAILURE(USER_DRAWS_HISTORY):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload
      };
    case FAILURE(SECOND_CHANCE_TICKET_GAME_HISTORY):
      return {
        ...state,
        errorMessage: action.payload
      };
    case SET_SELECTED_HISTORY_ITEM:
      return {
        ...state,
        selectedHistoryItem: action.payload
      };
    case RESET_SELECTED_HISTORY_ITEM:
      return {
        ...state,
        selectedHistoryItem: null
      };
    case RESET_STATE:
      return { ...INITIAL_STATE };
    case RESET_USER_ACTIVITY_HISTORY:
      return {
        ...state,
        historyActivities: new HistoryActivityList(),
        activitiesLastPage: false
      };
    case RESET_USER_TICKETS_ENTRY_HISTORY:
      return {
        ...state,
        historyTicketEntries: new TicketEntryHistoryList()
      };
    default:
      return FiltersEnhancer.processFilters('history', state, action) as IHistoryState;
  }
};
