import { Grid, Hidden, Theme, withStyles, WithStyles } from '@material-ui/core';
import { DrawState, IPromotion, PromotionType } from '@pbl/pbl-react-core/lib/models/draws/types';
import DrawScreen from '@pbl/pbl-react-web-components/lib/draws/draws-screen';
import { CardContentLoader, LoadError, Title } from '@pbl/pbl-react-web-components/lib/package';
import styles from 'assets/jss/modules/draws/DrawsScreenStyle';
import constants from 'config/constants';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { fetchProgressivePrizes, fetchPromotions, selectPromotion } from 'redux/reducers/draws/actions';
import ScrollToTopOnMount from 'shared/components/routes/ScrollToTopOnMount';
import DrawCard from './components/DrawCard';

type PropsConnected = ConnectedProps<typeof connector>;

export interface IDrawsScreenProps extends PropsConnected, RouteComponentProps, WithTranslation, WithStyles<typeof styles> {
  draws: DrawState;
  theme: Theme;
}

interface IDrawsScreenState {
  pageLoading: boolean;
}

const mapStateToProps = ({ draws }) => ({
  draws
});

const mapDispatchToProps = {
  fetchPromotions,
  selectPromotion,
  fetchProgressivePrizes
};

class PromotionsScreen extends React.Component<IDrawsScreenProps, IDrawsScreenState> {
  private prizeCheck: number | null = null;

  constructor(props: IDrawsScreenProps) {
    super(props);
    this.state = {
      pageLoading: false
    };
  }

  public async componentDidMount() {
    document.title = 'Promotions';
    await this.getEntities();
  }

  public updateProgressivePrize = async () => {
    if (!!this.props.draws.featuredPromotion) {
      if (this.props.draws.featuredPromotion.promotionType === PromotionType.PROGRESSIVE) {
        await this.props.fetchProgressivePrizes();
      }
    }
    if (!!this.props.draws.promotions) {
      this.props.draws.promotions.map(async promotion => {
        if (promotion.promotionType === PromotionType.PROGRESSIVE) {
          await this.props.fetchProgressivePrizes();
        }
      });
    }
  };

  public componentWillUnmount = async () => {
    if (!!this.prizeCheck) {
      clearInterval(this.prizeCheck);
    }
    this.prizeCheck = null;
  };

  public getEntities = async () => {
    this.setState({ pageLoading: true });
    await this.props.fetchPromotions(0);
    await this.updateProgressivePrize();
    this.prizeCheck = window.setInterval(this.updateProgressivePrize, constants.HOME_SCREEN_FETCH_DRAW_INTERVAL);
    this.setState({ pageLoading: false });
  };

  public getNextPage = async (pageNumber: number) => {
    await this.props.fetchPromotions(pageNumber);
  };

  public navigateToTicketEntry = () => {
    this.props.history.push('/ticket-entry', { from: this.props.location.pathname });
  };

  public renderDrawsScreenTitle = (): React.ReactNode => (
    <Grid item={true} xs={12}>
      <div className={this.props.classes.header}>
        <Title
          title={'Promotions'}
          titleColor={'textPrimary'}
          buttonText={this.props.t('games.enterTicket')}
          buttonTextTitle={this.props.t('games.ticketEntry')}
          icon={'icon-draws'}
          navigateToTicketEntry={this.navigateToTicketEntry}
        />
      </div>
    </Grid>
  );

  public getDrawCard = (promotion: IPromotion, onClick: () => void) => (
    <Grid item={true} xs={12} sm={6} lg={4} key={promotion.id}>
      <DrawCard
        name={promotion.name}
        image={promotion.fullImage ? promotion.fullImage : ''}
        description={promotion.description}
        onClick={onClick}
        iconName={'icon-draws'}
        banner={{
          showBanner: !!promotion.getPromotionStatus() || (!!promotion.progressivePrize && promotion.isJackpotReached()),
          bannerText: !!promotion.getPromotionStatus()
            ? this.props.t(`draws.drawStatus.${promotion.getPromotionStatus()}`)
            : this.props.t('draws.progressive.maxJackPot'),
          styleType: promotion.isEnded() ? 'GrayedOut' : promotion.isEndingSoon() ? 'WhiteHighlight' : 'Active'
        }}
        promotionType={promotion.promotionType}
        promotionProgressivePrize={promotion.progressivePrize}
      />
    </Grid>
  );

  // private onFilterDone = async (filters: IFilters) => {
  //   this.props.replaceFilers(filters);
  //   await this.props.fetchDrawsByType({ type: this.state.drawType, page: 0, size: constants.DRAWS_PER_PAGE });
  // };
  //
  // private onRemoveSelectedFilter = async (entityName: string, sectionKey: string, filterKey: string) => {
  //   this.props.toggleFilter(entityName, sectionKey, filterKey);
  //   await this.props.fetchDrawsByType({ type: this.state.drawType, page: 0, size: constants.DRAWS_PER_PAGE });
  // };

  private onCardClicked = (promotion: IPromotion): void => {
    this.props.selectPromotion(promotion);
    this.props.history.push('/promotion/' + promotion.id);
  };

  public render() {
    const { draws, classes } = this.props;

    if (draws.errorMessage) {
      return (
        <div className={classes.container}>
          <LoadError onReloadIconPress={this.getEntities} text="Something went wrong while loading the content!" />
        </div>
      );
    }

    if (this.state.pageLoading) {
      const contentLoader = (
        <Grid item={true} xs={12} sm={6} md={4}>
          <CardContentLoader loaderStyle={'loyalty'} />
        </Grid>
      );

      return (
        <div className={classes.container}>
          <ScrollToTopOnMount />
          {this.renderDrawsScreenTitle()}
          <Grid container={true} spacing={4}>
            {contentLoader}
            <Hidden smDown={true}>{contentLoader}</Hidden>
            <Hidden xsDown={true}>{contentLoader}</Hidden>
          </Grid>
        </div>
      );
    }

    return (
      <div className={classes.container}>
        <DrawScreen
          renderTitle={this.renderDrawsScreenTitle}
          promotions={draws.promotions}
          page={draws.promotionPage}
          loading={draws.loading}
          featuredPromotion={draws.featuredPromotion}
          getNextPage={this.getNextPage}
          getCard={this.getDrawCard}
          onCardClicked={this.onCardClicked}
          itemsPerPage={constants.DRAWS_PER_PAGE}
          featuredTitleWrap={true}
          hackShowFeatureSecondChanceContent={undefined}
          disablePagination={undefined}
        />
      </div>
    );
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export default withRouter(connector(withStyles(styles)(withTranslation()(PromotionsScreen))));
