import { createStyles, Theme } from '@material-ui/core';
import { container, coloredShadow } from 'assets/jss/material-kit-pro-react.jsx';

const styles = (theme: Theme) =>
  createStyles({
    gameCardImage: {
      backgroundSize: 'cover'
    },
    root: {
      ...container,
      display: 'flex',
      flex: 1,
      flexDirection: 'column'
    },
    // @ts-ignore
    coloredShadow,
    centered: {
      display: 'flex',
      justifyContent: 'center'
    },
    header: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2)
    },
    imageContainer: {
      overflow: 'hidden',
      maxHeight: 480,
      [theme.breakpoints.down('lg')]: {
        maxHeight: 450
      },
      [theme.breakpoints.down('md')]: {
        maxHeight: 400
      },
      [theme.breakpoints.down('xs')]: {
        maxHeight: 360
      }
    },
    iframe: {
      height: '100%',
      width: '100%',
      maxWidth: '100%',
      [theme.breakpoints.down('md')]: {
        height: '100%',
        width: '100%',
        maxWidth: '100%',
        margin: 0,
        maxHeight: '95%',
        position: 'absolute'
      },
      border: 0
    }
  });

export default styles;
