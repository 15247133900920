import { Button, Card, CardMedia, Hidden, LinearProgress, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { IErrorDTO, IErrorParams } from '@pbl/pbl-react-core/lib/models/app';
import { IContent, IUploadResponse } from '@pbl/pbl-react-core/lib/models/upload/types';
import { createMediaURL } from '@pbl/pbl-react-core/lib/utils/mediaUtil';
import BonusPoints from '@pbl/pbl-react-web-components/lib/bonus-points/BonusPoints';
import YoullEarnMsg from '@pbl/pbl-react-web-components/lib/bonus-points/YoullEarnMsg';
import { EarnPointsModal } from '@pbl/pbl-react-web-components/lib/package';
import ReadMore from '@pbl/pbl-react-web-components/lib/read-more/ReadMore';
import DetailTitle from '@pbl/pbl-react-web-components/lib/title/DetailTitle';
import noImage from 'assets/img/no-image.svg';
import styles from 'assets/jss/modules/earn/UploadPhotoScreenStyle';
import classNames from 'classnames';
import constants from 'config/constants';
import { useIsLoggedIn } from 'hooks/useIsLoggedIn';
import { useScrollToTop } from 'hooks/useScrollToTop';
import { useActivityDetailsData } from 'modules/earn/hooks/useActivityDetailsData';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import { showMessageBar } from 'redux/reducers/app/actions';
import { isAuthenticated } from 'redux/reducers/authentication/actions';
import { setIsFormDirty } from 'redux/reducers/forms-metadata/actions';
import { fetchUserPoints } from 'redux/reducers/ledger/actions';
import { uploadContent } from 'redux/reducers/upload/actions';
import { convertMegabyteToByte } from 'utils/conversionUtils';

interface IProps extends WithStyles<typeof styles>, WithTranslation {
  content: IContent | null;
}

const UploadPhotoScreen: React.FunctionComponent<IProps> = ({ classes, t, content }) => {
  const { activityId }: Record<string, string> = useParams();
  const { pathname } = useLocation();
  const { push: navigate } = useHistory();
  const dispatch = useDispatch();
  const loggedIn: boolean = useIsLoggedIn();

  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [imgSrc, setImgSrc] = useState<string | ArrayBuffer | null>(null);
  const [fileError, setFileError] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [isCancelled, setIsCancelled] = useState<boolean>(false);
  const [cancelToken, setCancelToken] = useState<any>({});
  const [activityCompleted, setActivityCompleted] = useState<boolean>(false);
  const [maximumLimitReached, setMaxLimitReached] = useState<boolean>(false);
  const [uploadResult, setUploadResult] = useState<IUploadResponse>({} as IUploadResponse);

  const onNavigate = useCallback(() => navigate('/earn', { from: pathname }), [navigate, pathname]);
  const navigateToActivities = useCallback((message?: IErrorParams) => navigate('/earn', { message }), [navigate]);

  const showInvalidError = useCallback(() => {
    navigateToActivities({
      message: t('activities.activityUnavailable'),
      type: 'warning',
      messageTimeout: 3000
    });
  }, [navigateToActivities, t]);

  const { activity } = useActivityDetailsData(activityId, showInvalidError);
  const extraDataInfo = activity?.extraData
    ?.filter(item => item.key === 'additionalInformation')
    ?.map(item => item.value.toString())
    .join('');
  const context = extraDataInfo ? extraDataInfo : activity?.description;
  const hasBonusPoints = activity && activity.bonusAmount && activity.bonusAmount > 0;

  useEffect(() => {
    if (activityId.length > 0) {
      if (activity?.frequencyCap) {
        setMaxLimitReached(activity.frequencyCap.limitReached);
      }
    } else {
      showInvalidActivityError();
    }
  }, [activity, activityId.length]);

  useScrollToTop();

  const showInvalidActivityError = async () => {
    navigate('/earn', {
      message: {
        message: `This activity is not available.`,
        type: 'error',
        messageTimeout: 10000
      }
    });
  };

  if (!activity) {
    return null;
  }

  const onDismissEarnPointsModal = (): void => {
    navigate('/earn');
  };

  const onFileSelected = (event): void => {
    if (content && content.contentConfig) {
      const allowedExtensions = content?.contentConfig.allowedExtensions.split(',');
      const selectedFiles = event.target.files;
      if (selectedFiles != null && selectedFiles.length > 0) {
        const fileSelected = selectedFiles[0];
        const fileExtension = selectedFiles[0].type.split('/')[1];
        if (
          allowedExtensions.every(ext => fileExtension !== ext) ||
          fileSelected.size > convertMegabyteToByte(content.contentConfig.maxFileSize)
        ) {
          setFileError(true);
        } else {
          setFileError(false);
          setIsCancelled(false);
          setSelectedFile(fileSelected);
        }

        const reader = new FileReader();
        reader.onloadend = () => {
          if (!!fileSelected && reader.result) {
            setImgSrc(reader.result);
          } else {
            setFileError(true);
            setCancelToken(null);
            setIsCancelled(false);
          }
        };
        reader.readAsDataURL(fileSelected);
      }
    }
  };

  const onUploadPressed = async () => {
    isAuthenticated();
    if (!loggedIn) {
      navigate('/login', { from: pathname });
      return;
    }
    setLoading(true);
    setUploadProgress(0);
    setIsCancelled(false);
    if (!!content && !!selectedFile) {
      await uploadContent(content.id, selectedFile, cancelToken, onUploadProgress).then(data => validateResponse(data));
      dispatch(fetchUserPoints());
      setLoading(false);
    }
  };

  const validateResponse = async (data: IUploadResponse | IErrorDTO) => {
    if (data && 'errorKey' in data && data.errorKey) {
      dispatch(
        showMessageBar({
          message: data.errorKey,
          type: 'error',
          messageTimeout: 10000
        })
      );
      setErrorMessage(data.errorKey);
    } else if ((data as any)?.detail?.includes('invalidAccess')) {
      dispatch(
        showMessageBar({
          message: t('error.activity.invalidAccess'),
          type: 'error',
          messageTimeout: 10000
        })
      );
    } else if (data && activity) {
      setUploadResult(data as IUploadResponse);
      setIsFormDirty(false);
      if ('awarded' in data && data.awarded) {
        setActivityCompleted(true);
      } else {
        setErrorMessage('Failed to Upload Photo');
      }
    }
  };

  const onCancelPressed = (): void => {
    if (cancelToken) {
      cancelToken.cancel();
      setIsCancelled(true);
    }
  };

  const onUploadProgress = (progress: any): void => {
    setUploadProgress(Math.round((progress.loaded / progress.total) * 100));
  };

  let preview;
  if (!fileError && !!imgSrc) {
    preview = <CardMedia component="img" image={imgSrc.toString()} className={classes.uploadedImg} title="Preview of image to upload" />;
  } else {
    preview = (
      <Typography variant="body1" color="textPrimary">
        Select photo to preview
      </Typography>
    );
  }

  const image = !!activity && !!activity.imageUrl ? createMediaURL(constants.BASE_URL, constants.MEDIA_API, activity.imageUrl) : noImage;
  const uploadButton =
    !loading && !fileError && imgSrc ? (
      <div>
        <div className={classes.button} />
        <Button variant="contained" color="primary" className={classes.button} onClick={onUploadPressed}>
          Upload
        </Button>
      </div>
    ) : null;

  const browseChangeButton = !loading ? (
    <Button variant={fileError || !imgSrc ? 'contained' : 'outlined'} color="primary" className={classes.button} component="label">
      {fileError || !imgSrc ? 'Browse to Select' : 'Change Photo'}
      <input type="file" style={{ display: 'none' }} accept="image/*" onChange={onFileSelected} />
    </Button>
  ) : null;

  const uploadText = !loading ? (
    <Typography color="error" className={classes.progressText}>
      {fileError || !imgSrc ? '' : 'Click UPLOAD to earn points'}
    </Typography>
  ) : null;

  const cancelButton = loading ? (
    <div>
      <LinearProgress variant="determinate" color="secondary" value={uploadProgress} />
      <Typography variant="body2" color="textPrimary" className={classes.progressText}>
        {uploadProgress}% Uploaded
      </Typography>
      <Typography variant="body2" color="textPrimary" className={classes.pointsText}>
        You will earn points once the upload finishes successfully
      </Typography>

      <div className={classes.button} />
      <Button variant="outlined" color="primary" className={classes.button} onClick={onCancelPressed}>
        Cancel
      </Button>
    </div>
  ) : null;

  const selectGrid = activity ? (
    <Grid className={classes.uploadGrid} container={true} spacing={2}>
      <Grid item={true} xs={6} className={classes.previewGridWrapper}>
        {!fileError && !!imgSrc ? (
          <Grid className={classes.previewTextWrapper}>
            <Typography variant="subtitle1" color="textPrimary" className={classes.previewText}>
              {' '}
              Photo Preview{' '}
            </Typography>
          </Grid>
        ) : null}
        <Grid className={classes.previewGrid}>
          <Grid>{preview}</Grid>
        </Grid>
      </Grid>
      <Grid item={true} xs={6} className={classes.selectGrid}>
        {fileError && (
          <div>
            <Typography variant="body2" color="error" className={classes.errorText}>
              <Trans i18nKey="error.uploadPhoto.uploadError" components={{ 1: <br /> }}>
                {t('error.uploadPhoto.uploadError')}
              </Trans>
            </Typography>
          </div>
        )}
        {!isCancelled && errorMessage && loggedIn && (
          <Typography variant="body2" color="error" className={classes.errorText}>
            {t(errorMessage)}
          </Typography>
        )}
        {isCancelled && (
          <Typography variant="body2" color="error" className={classes.cancelledText}>
            Upload cancelled
          </Typography>
        )}
        {browseChangeButton}
        {uploadButton}
        {uploadText}
        {cancelButton}
        <div className={classNames({ [classes.centered]: true, [classes.pointsText]: true })}>
          <YoullEarnMsg noneBonusPoints={activity.amount} bonusPoints={activity.bonusAmount ? activity.bonusAmount + activity.amount : 0} />
        </div>
      </Grid>
    </Grid>
  ) : (
    <></>
  );

  return (
    <div>
      {uploadResult && uploadResult.total_points ? (
        <EarnPointsModal pointEarned={uploadResult.total_points} isVisible={activityCompleted} onClose={onDismissEarnPointsModal} />
      ) : null}
      {loggedIn && !errorMessage}
      <Grid className={classes.container} container={true} spacing={2}>
        <DetailTitle title={activity && activity.title} linkText={'Earn'} navigateTo={onNavigate} />
        <Hidden mdDown={true}>
          <div className={classes.imageCard}>
            <CardMedia className={classes.image} image={image}>
              {hasBonusPoints ? <BonusPoints /> : null}
            </CardMedia>
          </div>
          {maximumLimitReached ? (
            <Typography variant={'subtitle1'} color={'error'}>
              Maximum limit reached!
            </Typography>
          ) : (
            <div className={classes.uploadCard}>{selectGrid}</div>
          )}
        </Hidden>
        <Hidden lgUp={true}>
          <Grid item={true} xs={12} sm={8} lg={true}>
            <Card className={classes.imageCard}>
              {activity && (
                <CardMedia className={classes.image} image={image} title={`${activity.title}`}>
                  {hasBonusPoints ? <BonusPoints /> : null}
                </CardMedia>
              )}
            </Card>
            {maximumLimitReached ? (
              <Typography variant={'subtitle1'} color={'error'}>
                Maximum limit reached!
              </Typography>
            ) : (
              <Card className={classes.uploadCard}>{selectGrid}</Card>
            )}
          </Grid>
        </Hidden>
        <Grid item={true} xs={12}>
          <ReadMore context={context as string} />
        </Grid>
      </Grid>
    </div>
  );
};

export default withTranslation()(withStyles(styles)(UploadPhotoScreen));
