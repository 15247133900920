import { CardMedia, Grid, Hidden, WithStyles, withStyles } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import { assets } from '@pbl/pbl-react-web-components/lib/config/assets';
import styles from 'assets/jss/modules/home/HomeScreenStyle';
import classNames from 'classnames';
import constants from 'config/constants';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import SectionHeading from './SectionHeading';

interface IProps extends WithStyles<typeof styles>, WithTranslation {
  onCardClicked: any;
}

const InformationOnlySection: React.FunctionComponent<IProps> = ({ classes, onCardClicked, t }) => (
  <div className={classNames(classes.section)}>
    <div className={classNames(classes.sectionHeading)}>
      <img alt="star" src={assets.internalAssets.starImg} />
      <SectionHeading
        title={t('home.informationTitle')}
        linkRoute="/information-list"
        linkTitle={t('home.informationLink')}
        linkLabel={t('home.informationLinkLabel')}
      />
    </div>
    <Grid container={true} direction="row" alignItems="center" className={classes.sectionList}>
      <Grid item={true} xs={12}>
        <Hidden xsDown={true}>
          <Link tabIndex={0} component="button" onClick={onCardClicked}>
            <CardMedia
              component="img"
              image={constants.INFORMATION_ONLY_WEB_IMAGE}
              className={classes.informationOnlyTileMedia}
              alt={t('home.informationLinkLabel')}
            />
          </Link>
        </Hidden>
        <Hidden smUp={true}>
          <Link tabIndex={0} component="button" onClick={onCardClicked}>
            <CardMedia
              component="img"
              image={constants.INFORMATION_ONLY_MOBILE_IMAGE}
              className={classes.informationOnlyTileMedia}
              alt={t('home.informationLinkLabel')}
            />
          </Link>
        </Hidden>
      </Grid>
    </Grid>
  </div>
);

export default withTranslation()(withStyles(styles)(InformationOnlySection));
