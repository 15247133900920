import { Grid, WithStyles, withStyles } from '@material-ui/core';
import { IActivity } from '@pbl/pbl-react-core/lib/models/activity/types';
import styles from 'assets/jss/modules/home/HomeScreenStyle';
import classNames from 'classnames';
import constants from 'config/constants';
import { IHomeEarnActivity } from 'config/homeScreenEarnActivities';
import ActivityListItem from 'modules/earn/components/ActivityListItem';
import { useNavigateToActivity } from 'modules/earn/hooks/useNavigateToActivity';
import EarnCard from 'modules/home/components/EarnCard';
import SectionHeading from 'modules/home/components/SectionHeading';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

interface IProps extends WithStyles<typeof styles>, WithTranslation {
  activities: IHomeEarnActivity[];
  earnActivities: IActivity[];
}

const EarnSection: React.FunctionComponent<IProps> = ({ classes, t, activities, earnActivities }) => {
  const { navigateToActivity } = useNavigateToActivity();
  const activitiesToRender: IActivity[] = earnActivities
    .filter(activity => activity.visibilityWeb)
    .filter((_1, index) => index < constants.HOME_SCREEN_EARN_ACTIVITIES_COUNT);

  return (
    <div className={classNames(classes.section)}>
      <SectionHeading
        title={t('home.earnSectionTitle')}
        linkRoute="/earn"
        linkTitle={t('home.earnSectionLink')}
        linkLabel={t('home.earnSectionLinkLabel')}
      />

      <Grid container={true} direction="row" alignItems="center" className={classes.sectionList}>
        {activitiesToRender &&
          activitiesToRender.map(activity => {
            const onSelect = () => navigateToActivity(activity);

            return (
              <Grid key={activity.id} xs={12} md={4} item={true} className={classes.sectionCard}>
                {<ActivityListItem activity={activity} onPress={onSelect} />}
              </Grid>
            );
          })}
      </Grid>

      <Grid container={true} direction="row" alignItems="center" className={classes.sectionList}>
        {activities.map(activity => (
          <Grid key={activity.activityId} xs={12} md={4} item={true} className={classes.sectionCard}>
            {<EarnCard activity={activity} />}
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default withTranslation()(withStyles(styles)(EarnSection));
