/* tslint:disable:no-console */
import MomentUtils from '@date-io/moment';
import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { jssPreset } from '@material-ui/styles';
import { ICoreConfig, react_core_config } from '@pbl/pbl-react-core/lib/config/config';
import { API, NGLAPI, NGLAPIv2 } from '@pbl/pbl-react-core/lib/services';
import { DateFormatterType } from '@pbl/pbl-react-core/lib/utils/DateTimeFormatter';
import * as NumberUtil from '@pbl/pbl-react-core/lib/utils/NumberUtil';
import { now } from '@pbl/pbl-react-core/lib/utils/TimeUtil';
import { assets, config } from '@pbl/pbl-react-web-components/lib/package';
import playersClubLogo from 'assets/img/az_players_club_white_logo.png';
import cvvHelper from 'assets/img/card-cvv.png';
import claimItAllSecondaryImage from 'assets/img/collect-them-all/claim-it-all-2-lg.png';
import claimItAllImage from 'assets/img/collect-them-all/claim-it-all-lg.png';
import collectCheckLogo from 'assets/img/icons-check@1x.png';
import tagBg from 'assets/img/sweepstakes/arcade-tag-bg.svg';
import convertArrow from 'assets/img/sweepstakes/arrow-right-yellow.svg';
import TokenPrimary from 'assets/img/sweepstakes/icons-lucky-lounge-token-primary-color.png';
import TokenSecondary from 'assets/img/sweepstakes/icons-lucky-lounge-token-secondary-color.png';
import luckyLoungeLogo from 'assets/img/sweepstakes/logos-lucky-lounge-vertical-gold.png';
import constants, { RemoteConstants } from 'config/constants';
import { getTicketTab } from 'config/ticket';
import validation from 'config/validation';
import virtualTourConfiguration from 'config/VirtualTourConfig';
import { create } from 'jss';
import React from 'react';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import { JssProvider } from 'react-jss';
import { Provider } from 'react-redux';
import { Router } from 'react-router';
import { PersistGate } from 'redux-persist/integration/react';
import { persistentStore, store } from 'redux/store';
import getTheme from 'shared/theme/custom';
import { addRequestTransform, addResponseTransform, apiConfiguration } from 'utils/apiConfig';
import { addNGL2ResponseTransform, addNGLRequestTransform, apiNGLConfiguration, apiNGLConfigurationv2 } from 'utils/apiNGLConfig';
import jssVendorPrefixer from 'utils/jssVendorPrefix';
import NavigationUtils from 'utils/NavigationUtils';
import AppLayout from './AppLayout';

const jss = create({ plugins: [...jssPreset().plugins, jssVendorPrefixer()] });
// @ts-ignore
window.fbAsyncInit = () => {
  document.dispatchEvent(new Event('FBObjectReady'));
};

class App extends React.Component {
  constructor(props) {
    super(props);
    const coreConfig = {
      lotteryTimeZone: constants.LOTTERY_TIME_ZONE,
      minDateOfBirth: now(constants.LOTTERY_TIME_ZONE).subtract(120, 'years').toDate(),
      maxDateOfBirth: now(constants.LOTTERY_TIME_ZONE).subtract(21, 'years').toDate(),
      currencyFormat: constants.CURRENCY_FORMAT,
      currencyFormatWithDecimal: constants.CURRENCY_FORMAT_WITH_DECIMAL,
      instantGameType: constants.INSTANT_GAMES_TYPE_ID,
      gameType: constants.DEFAULT_GAME_TYPE,
      showGameTypeFilter: constants.SHOW_GAME_TYPE_FILTER,
      defaultDateFormat: constants.DEFAULT_DATE_FORMAT,
      dateFormatter: {
        historyDateFormat: DateFormatterType.DateFormat,
        drawDrawDateFormat: DateFormatterType.DateFormat,
        drawStartEndDateFormat: DateFormatterType.DateFormat
      },
      addressBaseUrl: constants.ADDRESS_URL,
      pointsFormat: constants.POINTS_FORMAT,
      promoEndingSoonDays: constants.DRAW_ENDING_SOON_PERIOD,
      arcadeGameType: constants.ARCADE_GAME_TYPE,
      lotteryId: constants.LOTTERY_ID,
      skipGender: constants.SKIP_GENDER,
      skipPhone: constants.SKIP_PHONE
    } as ICoreConfig;
    config.coreConfig = coreConfig ;
    react_core_config.internalConfig = coreConfig;
    // @ts-ignore
    assets.internalAssets = {
      noImage: constants.BASE_IMAGE_URL + 'no-image.svg',
      pointsEarnedImg: constants.BASE_IMAGE_URL + 'pointsEarnedImg.png',
      noFeaturedImg: constants.BASE_IMAGE_URL + 'no-image-featured.svg',
      facebookLogo: constants.BASE_IMAGE_URL + 'facebook-logo.png',
      instagramLogo: constants.BASE_IMAGE_URL + 'instagram-logo.png',
      twitterLogo: constants.BASE_IMAGE_URL + 'twitter-logo.png',
      rewardDownloadLogo: constants.BASE_IMAGE_URL + 'reward-download.svg',
      rewardRedeemLogo: constants.BASE_IMAGE_URL + 'rewardRedeemed.svg',
      kenoHotNumberImg: constants.BASE_IMAGE_URL + 'hot-ball.png',
      kenoColdNumberImg: constants.BASE_IMAGE_URL + 'cold-ball.png',
      playLogo: require('../src/assets/img/play-icon.png').default,
      preAssignedPointsImg: require('../src/assets/img/bonus-points.png').default,
      correctIcon: require('../src/assets/img/Correct.png').default,
      incorrectIcon: require('../src/assets/img/Incorrect.png').default,
      activeLegendImg: require('../src/assets/img/active-legend.png').default,
      endedLegendImg: require('../src/assets/img/ended-legend.png').default,
      emptyCart: require('../src/assets/img/empty-cart-background.svg').default,
      arcadeTagBG: tagBg,
      tokenPrimary: TokenPrimary,
      tokenSecondary: TokenSecondary,
      cvvHelper,
      freeClaim: require('../src/assets/img/sweepstakes/free-ticket.png').default,
      otwImage: require('../src/assets/img/One-To-Win-Game_Image.png').default,
      otwLogo: require('../src/assets/img/Onetowinlogo.png').default,
      ftffImage: require('../src/assets/img/ftffImage.png').default,
      ftffLogo: require('../src/assets/img/ftffLogo.png').default,
      starImg: require('../src/assets/img/did-you-know-star.png').default,
      luckyLoungeLogoWhite: luckyLoungeLogo,
      convertArrow,
      playersClubLogoWhite: playersClubLogo,
      collectCheckLogoIcon: collectCheckLogo,
      claimItAll: claimItAllImage,
      claimItAllSecondary: claimItAllSecondaryImage,
      superBonusImg: null,
      shieldIcon: require('../src/assets/img/sweepstakes/id-verification/icons-lucky-lounge-secure.png').default,
      iconAlertWarning: require('../src/assets/img/sweepstakes/icon-alert-warning.svg').default,
      quickDrawImages: {
        AZ_MRL: {
          '01': require('../src/assets/img/quick-draw/AZ_MRL-1.jpg').default,
          '02': require('../src/assets/img/quick-draw/AZ_MRL-2.jpg').default,
          '03': require('../src/assets/img/quick-draw/AZ_MRL-3.jpg').default,
          '04': require('../src/assets/img/quick-draw/AZ_MRL-4.jpg').default,
          '05': require('../src/assets/img/quick-draw/AZ_MRL-5.jpg').default,
          '06': require('../src/assets/img/quick-draw/AZ_MRL-6.jpg').default
        }
      }
    };

    // @ts-ignore
    config.internalConfig = {
      CURRENCY_FORMAT: constants.CURRENCY_FORMAT,
      GOOGLE_PLACES_API_KEY: constants.GOOGLE_PLACES_API_KEY,
      POINTS_FORMAT: constants.POINTS_FORMAT,
      PHONE_MASK: constants.PHONE_MASK,
      HISTORY_FILTER_START_DAYS: constants.HISTORY_FILTER_START_DAYS,
      MEDIA_API: constants.MEDIA_API,
      BASE_URL: constants.BASE_URL,
      TICKET_TABS_DATA: getTicketTab(false, false),
      TICKET_FOCUS_NEXT_FIELD: true,
      DATE_FORMAT: constants.DATE_FORMAT,
      DATE_TIME_FORMAT: constants.DATE_TIME_FORMAT,
      REWARDS_MAXIMUM: constants.REWARDS_MAXIMUM,
      BUTTON_BACKGROUND: 'primary',
      TABLE_CELL_BACKGROUND: 'primary',
      EARN_ICON: 'icon-earn',
      DRAW_ICON: 'icon-draws',
      REWARDS_ICON: 'icon-rewards',
      DISABLE_RIPPLE: false,
      SHOW_DRAW_DATE: true,
      SHOW_REWARD_TYPE_COLUMN: true,
      HIDE_REWARDS_INFO: constants.HIDE_REWARDS_INFO,
      SHOW_REWARD_COLUMN: false,
      SHOW_IMAGE_PREFIX: false,
      codeBlockInstructionsRegistrationLink:
        'Share this invitation link with a friend. The invite code will automatically apply when your friend registers for a Players Club account.',
      codeBlockInstructionsRegistrationPage: "Invite a friend to join Players Club with the invite code above and you'll BOTH earn points.",
      SHOW_USER_POINTS: constants.SHOW_USER_POINTS,
      COUPON_BACKGROUND: constants.COUPON_BACKGROUND,
      GAME_DRAWS_PER_PAGE: constants.GAME_DRAWS_PER_PAGE,
      DRAW_ENDING_SOON_PERIOD: constants.DRAW_ENDING_SOON_PERIOD,
      JACKPOT_RESULTS_PER_PAGE: constants.JACKPOT_RESULTS_PER_PAGE,
      NEW_MEGA_MILLION_ID: constants.NEW_MEGA_MILLION_ID,
      PROMO_COMING_SOON_PERIOD: 0,
      IGNORE_ACCENT_DIGITAL_REVEAL: true,
      MAX_FREE_ENTRIES: 1,
      PAYMENT: {
        NAME_VALIDATION: validation.holderNameValidator,
        ZIP_VALIDATION: validation.zipCodeValidator,
        PAYSAFE_ENV: '',
        PAYSAFE_API_KEY: ''
      },
      VIRTUALTOUR: virtualTourConfiguration,
      SHOW_TITLE_ABOVE_IMAGE: constants.SHOW_TITLE_ABOVE_IMAGE,
      SHOW_SUPER_BONUS_DIALOG: constants.SHOW_SUPER_BONUS_DIALOG,
      SHOW_BONUS_POINTS: constants.SHOW_BONUS_POINTS,
      SHOW_TICKETDISCLAIMER: constants.SHOW_TICKETDISCLAIMER,
      SMALL_DRAW_GAMES_IMAGE_HEIGHT: constants.SMALL_DRAW_GAMES_IMAGE_HEIGHT,
      SHOW_TRANSACTION_ID: false,
      HIDE_PENDING_MODAL: false
    };
    // Fetch remote constants
    RemoteConstants.sync();
  }

  public async componentDidMount(): Promise<void> {
    // try to sync remote config on app mount.
    setTimeout(async () => {
      try {
        await RemoteConstants.syncConfig();
      } catch (e) {}
    }, 100);
    this.initFacebookAPI();
    const analyticCodes = constants.GOOGLE_ANALYTICS_CODE.includes(`;;`)
      ? constants.GOOGLE_ANALYTICS_CODE.split(`;;`)
      : [constants.GOOGLE_ANALYTICS_CODE];

    analyticCodes.forEach(analyticCode => {
      ReactGA.initialize(analyticCode, { debug: constants.CONSOLE_DEBUG });
    });

    ReactGA.event({ category: 'Load', action: 'Application Loaded' });

    const tagManagerCodes = constants.GOOGLE_TAG_MANAGER_ID.includes(`;;`)
      ? constants.GOOGLE_TAG_MANAGER_ID.split(`;;`)
      : [constants.GOOGLE_TAG_MANAGER_ID];
    tagManagerCodes.forEach(tagManagerCode => {
      TagManager.initialize({
        gtmId: tagManagerCode
      });
    });

    ReactPixel.init(constants.FACEBOOK_PIXEL_ID); // Add pixelId
    API.setup(apiConfiguration, { requestTransFormation: { addRequestTransform, addResponseTransform } });
    NGLAPI.setup(apiNGLConfiguration, { requestTransFormation: { addRequestTransform: addNGLRequestTransform } });
    NGLAPIv2.setup(apiNGLConfigurationv2, { requestTransFormation: { addRequestTransform: addNGLRequestTransform, addResponseTransform: addNGL2ResponseTransform } });
    const link = await RemoteConstants.getString('INVITE_INSTRUCTIONS_CODE');
    if (link) {
      config.internalConfig.codeBlockInstructionsRegistrationLink = link;
    }
    const code = await RemoteConstants.getString('INVITE_INSTRUCTIONS_NO_CODE');
    if (code) {
      config.internalConfig.codeBlockInstructionsRegistrationPage = code;
    }

    NumberUtil.loadNewCurrencyFormat();
  }

  public render() {
    return (
      <JssProvider jss={jss} classNamePrefix={'az-'}>
        <MuiThemeProvider theme={getTheme()}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Provider store={store}>
              <PersistGate persistor={persistentStore}>
                <Router history={NavigationUtils.setupRouter()}>
                  <React.Fragment>
                    <CssBaseline />
                    <AppLayout />
                  </React.Fragment>
                </Router>
              </PersistGate>
            </Provider>
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </JssProvider>
    );
  }

  private initFacebookAPI = () => {
    // @ts-ignore
    if (window.FB) {
      console.log('facebook - sending startup options');
      // @ts-ignore
      window.FB.init({
        appId: constants.FACEBOOK_APP_ID,
        cookie: true,
        xfbml: true,
        version: 'v3.2'
      });
    } else {
      window.setTimeout(this.initFacebookAPI, 300);
    }
  };
}

export default App;
