import { CLAIM_FREE_ENTRY, IDraw, IPromotion } from '@pbl/pbl-react-core/lib/models/draws/types';
import {
  FETCH_ENTRIES,
  FETCH_FREE_ENTRIES,
  GET_ACTIVE_DRAW,
  GET_ALL_DRAWS,
  GET_REWARD,
  GET_REWARD_LIST,
  GET_REWARD_WITH_FREQUENCY_CAP,
  ICoupon,
  IReward,
  IRewardDetails,
  REDEEM_REWARD,
  RESET_REWARD,
  RewardType
} from '@pbl/pbl-react-core/lib/models/reward/types';
import { CodeService, FrequencyService, PromotionsService } from '@pbl/pbl-react-core/lib/services';
import { RewardService } from '@pbl/pbl-react-core/lib/services/reward-service';
import constants from 'config/constants';
import moment from 'moment-timezone';
import { FAILURE, REQUEST, SUCCESS } from 'redux/action-type.util';
import { GetStateMethod } from 'redux/reducers';
import { clearMessages, toggleLoading } from 'redux/reducers/app/actions';
import { fetchUserPoints } from 'redux/reducers/ledger/actions';

export interface PageInfo {
  first: boolean;
  last: boolean;
  totalPages: number;
  number: number;
}

export interface RewardResponse {
  content: IReward[];
  page: PageInfo;
}
export const fetchRewards = async (mobileOnly: boolean, featured: boolean, type?: string): Promise<RewardResponse | undefined> => {
  try {
    const data = await RewardService.getRewards(mobileOnly, featured, type);

    return data as RewardResponse;
  } catch (e) {
    console.error(e);
  }
};

export const fetchUserRewards = async (mobileOnly: boolean, featured: boolean, type?: RewardType): Promise<RewardResponse | undefined> => {
  try {
    const data = await RewardService.getUserRewards(mobileOnly, featured, type);

    return data as RewardResponse;
  } catch (e) {
    console.error(e);
  }
};

export const fetchHomePageRewards = () => async (dispatch: any) => {
  try {
    dispatch({
      type: REQUEST(GET_REWARD_LIST)
    });

    const featuredRewards = await RewardService.getRewards(false, true);
    const nonFeaturedRewards = await RewardService.getRewards(false, false);
    const rewards = [...featuredRewards, ...nonFeaturedRewards];

    if (rewards.length > 0) {
      for (let i = 0; i < rewards.length && i < constants.HOME_SCREEN_REWARD_COUNT; i++) {
        const reward = rewards[i];
        if (reward.type === RewardType.COUPON) {
          const coupon = await fetchCoupon(reward.key);
          if (coupon) {
            reward.remaining = coupon.remaining;
          }
        }
      }
    }

    dispatch({
      type: SUCCESS(GET_REWARD_LIST),
      payload: rewards
    });
  } catch (e) {
    dispatch({
      type: FAILURE(GET_REWARD_LIST),
      payload: e
    });
    console.error(e);
  }
};

export const fetchHomePageUserRewards = () => async (dispatch: any) => {
  try {
    dispatch({
      type: REQUEST(GET_REWARD_LIST)
    });

    const featuredRewards = await RewardService.getUserRewards(false, true);
    const nonFeaturedRewards = await RewardService.getUserRewards(false, false);
    const rewards = [...featuredRewards, ...nonFeaturedRewards];

    if (rewards.length > 0) {
      for (let i = 0; i < rewards.length && i < constants.HOME_SCREEN_REWARD_COUNT; i++) {
        const reward = rewards[i];
        if (reward.type === RewardType.COUPON) {
          const coupon = await fetchCoupon(reward.key);
          if (coupon) {
            reward.remaining = coupon.remaining;
          }
        }
      }
    }

    dispatch({
      type: SUCCESS(GET_REWARD_LIST),
      payload: rewards
    });
  } catch (e) {
    dispatch({
      type: FAILURE(GET_REWARD_LIST),
      payload: e
    });
    console.error(e);
  }
};

export const getRewards = () => async (dispatch: any) => {
  try {
    dispatch({
      type: REQUEST(GET_REWARD_LIST)
    });

    const featuredRewards = await RewardService.getRewards(false, true);
    const nonFeaturedRewards = await RewardService.getRewards(false, false);
    const rewards = [...featuredRewards, ...nonFeaturedRewards];

    if (rewards.length > 0) {
      for (const reward of rewards) {
        if (reward.type === RewardType.COUPON) {
          const coupon = await fetchCoupon(reward.key);
          if (coupon) {
            reward.remaining = coupon.remaining;
          }
        }
      }
    }

    dispatch({
      type: SUCCESS(GET_REWARD_LIST),
      payload: rewards
    });
  } catch (e) {
    dispatch({
      type: FAILURE(GET_REWARD_LIST),
      payload: e
    });
    console.error(e);
  }
};

export const getUserRewards = () => async (dispatch: any) => {
  try {
    dispatch({
      type: REQUEST(GET_REWARD_LIST)
    });

    const featuredRewards = await RewardService.getUserRewards(false, true);
    const nonFeaturedRewards = await RewardService.getUserRewards(false, false);
    const rewards = [...featuredRewards, ...nonFeaturedRewards];

    if (rewards.length > 0) {
      for (const reward of rewards) {
        if (reward.type === RewardType.COUPON) {
          const coupon = await fetchCoupon(reward.key);
          if (coupon) {
            reward.remaining = coupon.remaining;
          }
        }
      }
    }

    dispatch({
      type: SUCCESS(GET_REWARD_LIST),
      payload: rewards
    });
  } catch (e) {
    dispatch({
      type: FAILURE(GET_REWARD_LIST),
      payload: e
    });
    console.error(e);
  }
};

export const fetchRewardList = () => async (dispatch: any) => {
  await dispatch({
    type: GET_REWARD_LIST,
    payload: RewardService.getRewardList('web')
  }).catch(error => {
    console.error(error);
  });
};
export const fetchReward = (rewardId: string) => async (dispatch: any) => {
  try {
    await dispatch({
      type: GET_REWARD,
      payload: RewardService.fetchRewardDetails(rewardId)
    });
  } catch (error) {
    console.error(error);
  }
};

export const fetchRewardById = async (rewardId: string): Promise<IRewardDetails | undefined> => {
  try {
    const reward = await RewardService.fetchRewardDetails(rewardId);
    return reward;
  } catch (error) {
    console.error(error);
  }
};

export const fetchRewardWithFrequencyCap = (rewardId: string) => async (dispatch: any) => {
  try {
    await dispatch({
      type: GET_REWARD_WITH_FREQUENCY_CAP,
      payload: RewardService.getRewardWithFrequencyCap(rewardId)
    });
  } catch (error) {
    console.error(error);
  }
};

export const fetchRewardWithFrequencyCapById = async (rewardId: string): Promise<IRewardDetails | undefined> => {
  try {
    const reward = RewardService.getRewardWithFrequencyCap(rewardId);
    return reward;
  } catch (error) {
    console.error(error);
  }
};

export const getActiveDraw = (promotionId: number) => async (dispatch: any) => {
  try {
    await dispatch({
      type: GET_ACTIVE_DRAW,
      payload: PromotionsService.fetchActiveDraw(promotionId)
    });
  } catch (error) {
    console.error(error);
  }
};

export const getActiveDrawByPromotionId = async (promotionId: number): Promise<IDraw | undefined> => {
  try {
    return await PromotionsService.fetchActiveDraw(promotionId);
  } catch (error) {
    console.error(error);
  }
};

export const fetchEntries = async (drawId: number): Promise<number | undefined> => {
  try {
    return await PromotionsService.fetchSecondChanceDrawEntries(drawId);
  } catch (error) {
    console.error(error);
  }
};

export const fetchCoupon = async (rewardKey: string): Promise<ICoupon | undefined> => {
  try {
    return await CodeService.getDetails(rewardKey);
  } catch (error) {
    console.error(error);
  }
};

export const fetchRedemption = async (rewardKey: string): Promise<ICoupon | undefined> => {
  try {
    return await FrequencyService.getGlobalFrequency(rewardKey);
  } catch (error) {
    console.error(error);
  }
};

export const redeemCoupon = async (
  rewardKey: string,
  errorCallback: (error: any) => void
): Promise<{ numPoints: number; code: string } | undefined> => {
  try {
    return await CodeService.redeem(rewardKey);
  } catch (e) {
    console.error(e);
    errorCallback(e);
  }
};

export const getAllDraws = (promotionId: number, pageNumber: number, pageSize: number) => async (dispatch: any) => {
  try {
    await dispatch({
      type: GET_ALL_DRAWS,
      payload: PromotionsService.getDrawsforPromotionId(promotionId, pageNumber, pageSize)
    });
  } catch (error) {
    console.error(error);
  }
};

export const fetchRewardFreeEntries = (drawId: number) => async (dispatch: any) => {
  dispatch({
    type: FETCH_FREE_ENTRIES,
    payload: PromotionsService.countFreeEntry(drawId)
  }).catch(error => {
    console.error(error);
  });
};

export const fetchAllEntries = (drawId: number) => async (dispatch: any, getState: GetStateMethod) => {
  const {
    reward: { allDraws }
  } = getState();
  const draw = allDraws.find(x => x.id === drawId);
  dispatch({
    type: FETCH_ENTRIES,
    payload: draw ? draw.entries : 0
  });
};

export const redeemReward =
  (rewardId: string, quantity: number, successCallback: (message: any) => void, errorCallback: (error: any) => void) =>
  async (dispatch: any) => {
    try {
      dispatch(
        toggleLoading({
          spinning: true
        })
      );
      const response = await RewardService.redeemRewardV2(rewardId, quantity);
      dispatch({
        type: SUCCESS(REDEEM_REWARD),
        payload: response
      });
      dispatch(fetchUserPoints());
      successCallback(response);
    } catch (error) {
      errorCallback(error);
      dispatch({
        type: FAILURE(REDEEM_REWARD),
        payload: {
          error
        }
      });
    } finally {
      dispatch(
        toggleLoading({
          spinning: false
        })
      );
    }
  };

export const resetReward = () => (dispatch: any) => {
  dispatch(clearMessages());
  dispatch({
    type: RESET_REWARD
  });
};

export const claimFreeEntry =
  (id: string, recaptcha: string, successCallback: (message: any) => void, errorCallback: (error: any) => void) =>
  async (dispatch: any, getState: GetStateMethod) => {
    try {
      const {
        reward: { freeEntriesRequestSubmission }
      } = getState();
      const now = moment();
      if (freeEntriesRequestSubmission && now.diff(freeEntriesRequestSubmission, 'seconds') < 60) {
        errorCallback({ errorKey: 'error.reward.freeEntryTrace' });
        return;
      }
      dispatch({
        type: REQUEST(CLAIM_FREE_ENTRY)
      });

      const response = await RewardService.claimFreeEntry(id, recaptcha);
      dispatch({
        type: SUCCESS(CLAIM_FREE_ENTRY),
        payload: response
      });
      successCallback(response);
    } catch (error) {
      errorCallback(error);
      dispatch({
        type: FAILURE(CLAIM_FREE_ENTRY),
        payload: {
          error
        }
      });
    }
  };

export const getPromotionByPromotionId = async (promotionId: number): Promise<IPromotion | undefined> => {
  try {
    return await PromotionsService.fetchPromotionDetails(promotionId);
  } catch (error) {
    console.error(error);
  }
};
