import { Grid, Theme, Typography, WithStyles } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import withTheme from '@material-ui/core/styles/withTheme';
import { IErrorParams } from '@pbl/pbl-react-core/lib/models/app';
import { IDrawDetailState } from '@pbl/pbl-react-core/lib/models/draws/types';
import { IReward } from '@pbl/pbl-react-core/lib/models/loyalty/types';
import { now, zonedDateTimeFor } from '@pbl/pbl-react-core/lib/utils/TimeUtil';
import PastDrawDetail from '@pbl/pbl-react-web-components/lib/draw/PastDrawDetail';
import RedeemConfirmationModal from '@pbl/pbl-react-web-components/lib/reward-detail/RedeemConfirmationModal';
import RedeemModal from '@pbl/pbl-react-web-components/lib/reward-detail/RedeemModal';
import RewardCodesModal from '@pbl/pbl-react-web-components/lib/reward-detail/RewardCodesModal';
import RewardDigitalDownloadModal from '@pbl/pbl-react-web-components/lib/reward-detail/RewardDigitalDownloadModal';
import DetailTitle from '@pbl/pbl-react-web-components/lib/title/DetailTitle';
import styles from 'assets/jss/modules/draws/DrawDetailScreenStyle';
import constants from 'config/constants';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IRootState } from 'redux/reducers';
import { clearLoading, clearMessages, showMessageBar, toggleLoading } from 'redux/reducers/app/actions';
import {
  fetchDrawEntries,
  fetchDrawPrizes,
  fetchDrawPrizesWithContent,
  fetchDrawPrizesWithWinners,
  fetchDrawWithAdditionalInformation,
  fetchEligibleGames,
  fetchLatestDrawHistory
} from 'redux/reducers/draws/actions';
import {
  fetchReward,
  fetchRewards,
  fetchUserPoints,
  fetchUserRewards,
  redeemReward,
  resetError,
  resetSelectedReward
} from 'redux/reducers/loyalty/actions';
import ScrollToTopOnMount from 'shared/components/routes/ScrollToTopOnMount';

interface IMatchParams {
  [k: string]: string;
}

type PropsConnected = ConnectedProps<typeof connector>;

export interface IPastDrawDetailScreen
  extends PropsConnected,
    RouteComponentProps<IMatchParams>,
    WithTranslation,
    WithStyles<typeof styles> {
  theme: Theme;
  drawDetail: IDrawDetailState;
}

interface IState {
  showModal: boolean;
  showModalConfirmation: boolean;
  redeemQuantity: number;
  showCodesModal: boolean;
  showDigitalDownloadModal: boolean;
}

class PastDrawDetailScreen extends React.Component<IPastDrawDetailScreen, IState> {
  public state = {
    showModal: false,
    showModalConfirmation: false,
    redeemQuantity: 0,
    showCodesModal: false,
    showDigitalDownloadModal: false
  };

  public componentWillMount() {
    if (!this.props.drawDetail.draw) {
      this.props.history.goBack();
    }
    this.props.resetSelectedReward();
  }

  public async componentDidMount() {
    const {
      drawDetail: { draw }
    } = this.props;
    if (draw) {
      if (!!draw.externalId) {
        this.props.fetchReward(draw.externalId);
      }
      this.props.fetchDrawPrizes(draw.id);
      this.props.fetchDrawWithAdditionalInformation(draw.id);
      this.props.fetchLatestDrawHistory(draw.id);
      if (this.props.isLoggedIn) {
        await this.props.fetchUserPoints();
        await this.props.fetchUserRewards();
      } else {
        await this.props.fetchRewards();
      }
    }
  }

  public render() {
    const { isLoggedIn, classes, t } = this.props;

    return (
      <>
        <ScrollToTopOnMount />
        <Grid className={classes.container} container={true} spacing={2}>
          <PastDrawDetail
            drawDetail={this.props.drawDetail}
            isLoggedIn={isLoggedIn}
            fetchDrawPrizesWithContent={this.retrieveContentForDrawPrizes}
            fetchDrawPrizesWithWinners={this.retrieveWinnersForDrawPrizes}
            renderTitle={this.renderDrawTitle}
            selectedReward={this.props.selectedReward}
            userPoints={this.props.loyalty.userPoints}
            showPrizesLink={true}
            onRedeem={this.onRedeem}
            entries={this.props.loyalty.rewardEntries}
            rulesIcon={undefined}
          />
        </Grid>
        <div>
          <RedeemConfirmationModal
            isVisible={this.state.showModalConfirmation}
            onClose={this.closeModalConfirmationDialog}
            maxWidth="sm"
            email="winners@azlottery"
            text={t('rewards.confirmationMessage')}
            buttonStyle={undefined}
            image={undefined}
            textStyle={undefined}
            title={undefined}
          />
          {this.getRedeemModal()}
          {this.getCodesModal()}
          {this.getDigitalDownloadModal()}
        </div>
      </>
    );
  }

  private renderDrawTitle = (): React.ReactNode => {
    const {
      drawDetail: { draw },
      classes,
      history
    } = this.props;
    if (!draw) {
      return null;
    }
    const drawEnded = draw.end && zonedDateTimeFor(draw.end).isBefore(now());
    const endingSoon =
      draw.end && !drawEnded && !zonedDateTimeFor(draw.end).isAfter(now().add(constants.UPCOMING_GAME_PERIOD, 'days').endOf('day'));
    const alert = drawEnded ? 'Drawing Ended' : endingSoon ? 'Ending Soon' : null;
    const onNavigatePastDraws = () => {
      history.push('/past-draws');
    };
    return (
      <>
        <DetailTitle title={draw.name} linkText={'Past Rewards'} navigateTo={onNavigatePastDraws} />
        <Grid className={classes.subtitle} item={true} xs={12}>
          <Typography className={classes.alert} variant="body1" color="error">
            {alert}
          </Typography>
        </Grid>
      </>
    );
  };

  private getDigitalDownloadModal = (): React.ReactNode => {
    if (this.props.isLoggedIn && this.state.showDigitalDownloadModal) {
      const { selectedReward, rewardDigitalDownload } = this.props;
      if (!selectedReward) {
        return null;
      }
      return (
        <RewardDigitalDownloadModal
          title={selectedReward.title}
          open={this.state.showDigitalDownloadModal}
          downloadLink={rewardDigitalDownload as string}
          onClose={this.navigateWithRedeemSuccess}
        />
      );
    }
    return null;
  };

  private getRedeemModal = (): React.ReactNode => {
    if (this.props.isLoggedIn && this.state.showModal) {
      const { selectedReward } = this.props;
      if (!selectedReward && this.props.maxRedeemAvailable <= 0) {
        return null;
      }
      return (
        <RedeemModal
          maxRedeemAvailable={this.props.maxRedeemAvailable}
          onRedeem={this.requestRedeem}
          onError={this.showRedeemError}
          onQuantityChange={this.onRedeemQuantityChange}
          redeemablePoints={this.props.redeemablePoints}
          open={this.state.showModal}
          quantity={this.state.redeemQuantity}
          selectedReward={selectedReward as IReward}
          rewardTerms={selectedReward && selectedReward.reward_terms}
          onClose={this.onModalClose}
          hideEndsInMessage={true}
          disableSlider={undefined}
          hideRedeemableQuantity={undefined}
        />
      );
    }
    return null;
  };

  private getCodesModal = (): React.ReactNode => {
    if (this.props.isLoggedIn && this.state.showCodesModal) {
      const { selectedReward } = this.props;
      if (!selectedReward) {
        return null;
      }
      return (
        <RewardCodesModal
          open={this.state.showCodesModal}
          email={`${this.props.userEmail}`}
          rewardCodes={this.props.rewardCodes}
          selectedReward={selectedReward}
          onClose={this.navigateWithRedeemSuccess}
        />
      );
    }
    return null;
  };

  private retrieveContentForDrawPrizes = async (): Promise<void> => {
    const {
      drawDetail: { draw }
    } = this.props;
    if (draw) {
      await this.props.fetchDrawPrizesWithContent(draw.id);
    }
  };

  private retrieveWinnersForDrawPrizes = async (): Promise<void> => {
    const {
      drawDetail: { draw }
    } = this.props;
    if (draw) {
      await this.props.fetchDrawPrizesWithWinners(draw.id);
    }
  };

  private navigateWithRedeemSuccess = async () => {
    await this.onModalClose();
    if (this.props.selectedReward && !!this.props.selectedReward.sweepstake_winners) {
      this.setState({ showModalConfirmation: true });
    } else {
      await this.navigateToRewards({
        message: `Congratulations! You have successfully redeemed reward.`,
        type: 'success'
      });
    }
  };

  public closeModalConfirmationDialog = async () => {
    this.setState({ showModalConfirmation: false });
    await this.navigateToRewards();
  };

  private navigateToRewards = async (message?: IErrorParams) => {
    const { history } = this.props;
    await history.push('/past-draws', { message });
  };

  private onRedeem = async () => {
    if (!this.props.isLoggedIn) {
      this.props.history.push('/login', { from: this.props.location });
      return;
    }
    await this.props.clearMessages();
    this.setState({ showModal: true, redeemQuantity: 1 });
  };

  private showRedeemError = async (errorMessage: any) => {
    await this.props.showMessageBar({
      message: errorMessage,
      type: 'error',
      messageTimeout: 10000
    });
  };

  private requestRedeem = async () => {
    const { selectedReward } = this.props;

    if (!selectedReward) {
      return;
    }
    await this.props.toggleLoading({
      spinning: true
    });
    await this.setState({ showModal: false });
    await this.props.resetError();
    await this.props.redeemReward(selectedReward.id, this.state.redeemQuantity);

    setTimeout(async () => {
      const { errorMessage } = this.props;
      if (errorMessage) {
        await this.showRedeemError(this.getErrorMessage(errorMessage));
      } else {
        await this.showRedeemSuccess();
      }
      await this.props.resetError();
      await this.props.clearLoading();
    }, 1000);
  };

  private onRedeemQuantityChange = (quantity: number) => {
    this.setState({ redeemQuantity: quantity });
  };

  private getErrorMessage = (error: any) => {
    if (error) {
      if (error.status === 'FORBIDDEN') {
        if (!!error.message) return error.message;
        return error.error;
      }
    }
  };

  private showRedeemSuccess = async () => {
    const { rewardCodes, rewardDigitalDownload } = this.props;

    if (!!rewardCodes && rewardCodes.length > 0) {
      await this.setState({ showCodesModal: true });
      return;
    }

    if (!!rewardDigitalDownload) {
      await this.setState({ showDigitalDownloadModal: true });
      return;
    }
    await this.navigateWithRedeemSuccess();
  };

  private onModalClose = () => {
    this.setState({ showModal: false, showCodesModal: false, showDigitalDownloadModal: false });
  };
}

const mapStateToProps = ({ drawDetail, authentication: { account, accessToken }, loyalty }: IRootState) => {
  const isLoggedIn: boolean = !!accessToken && accessToken.length > 0 && !!account && !!account.email;
  const userEmail: string = isLoggedIn ? account.email : '';
  const redeemablePoints = isLoggedIn ? loyalty.userPoints.redeemablePoints : 0;

  let maxRedeemAvailable = 0;

  if (!!loyalty.selectedReward) {
    maxRedeemAvailable = loyalty.selectedReward.max_select_quantity || loyalty.selectedReward.max_per_user || constants.REWARDS_MAXIMUM;

    if (loyalty.selectedReward.freq_cap != null && maxRedeemAvailable > loyalty.selectedReward.freq_cap) {
      maxRedeemAvailable = loyalty.selectedReward.freq_cap;
    }

    if (loyalty.selectedReward.current_quantity != null && maxRedeemAvailable > loyalty.selectedReward.current_quantity) {
      maxRedeemAvailable = loyalty.selectedReward.current_quantity;
    }

    if (maxRedeemAvailable > redeemablePoints / loyalty.selectedReward.num_points) {
      maxRedeemAvailable = Math.floor(redeemablePoints / loyalty.selectedReward.num_points);
    }
  }

  return {
    drawDetail,
    loyalty,
    isLoggedIn,
    rewards: isLoggedIn ? loyalty.userRewards : loyalty.rewards,
    selectedReward: loyalty.selectedReward,
    errorMessage: loyalty.errorMessage,
    rewardCodes: loyalty.rewardCodes,
    rewardDigitalDownload: loyalty.rewardDigitalDownload,
    userEmail,
    redeemablePoints,
    maxRedeemAvailable
  };
};

const mapDispatchToProps = {
  fetchDrawWithAdditionalInformation,
  fetchDrawPrizes,
  fetchDrawPrizesWithContent,
  fetchDrawPrizesWithWinners,
  fetchLatestDrawHistory,
  fetchEligibleGames,
  showMessageBar,
  fetchDrawEntries,
  fetchRewards,
  fetchUserRewards,
  fetchUserPoints,
  clearMessages,
  toggleLoading,
  clearLoading,
  redeemReward,
  fetchReward,
  resetSelectedReward,
  resetError
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default withRouter(connector(withStyles(styles)(withTranslation()(withTheme(PastDrawDetailScreen)))));
