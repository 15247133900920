import { Activity } from '@pbl/pbl-react-core/lib/models/loyalty';
import { GamesService, InteractiveGameService, LoyaltyService } from '@pbl/pbl-react-core/lib/services';
import { parseJson } from '@pbl/pbl-react-web-components/lib/utils/JsonUtils';
import constants from 'config/constants';
import { FAILURE, REQUEST, SUCCESS } from 'redux/action-type.util';
import { showMessageBar } from '../app/actions';

export const GET_GAME_CONFIG = 'interactive-games/GET_GAME_CONFIG';
export const UPDATE_GAME_CONFIG = 'interactive-games/UPDATE_GAME_CONFIG';
export const GET_PLAY_FOR_POINTS_ACTIVITY = 'interactive-games/GET_PLAY_FOR_POINTS_ACTIVITY';
export const FETCH_DIGITAL_REVEAL_PLAYABLE = 'interactive-games/FETCH_DIGITAL_REVEAL_PLAYABLE';
export const START_GAME = 'interactive-games/START_GAME';
export const END_GAME = 'interactive-games/END_GAME';
export const SKIP_GAME = 'interactive-games/END_GAME';

export const getGameConfig = (extraData: any, gameType: string) => async (dispatch: any) => {
  try {
    dispatch({ type: REQUEST(GET_GAME_CONFIG) });

    let gameConfig;
    let playForPointsActivity: Activity | null = null;
    if (extraData) {
      if (extraData.is_play_for_fun_only && extraData.is_play_for_fun_only === 'true') {
        const result = await GamesService.getGameConfig(extraData.game_number);

        const payload = await InteractiveGameService.generatePlayForFunToken(extraData.digital_reveal, result.prizes);

        if (!!payload) {
          gameConfig = {
            ...result,
            ...payload,
            face: parseJson(payload.face),
            gameConfig: parseJson(payload.gameConfig),
            prizes: result.prizes
          };
        }
      } else if (extraData.is_play_for_fun && extraData.is_play_for_fun === 'true') {
        playForPointsActivity = Activity.JsonToActivity(await LoyaltyService.getActivity(extraData.points_activity_id));

        if (
          gameType === 'prize' &&
          playForPointsActivity &&
          playForPointsActivity.freq_cap !== null &&
          playForPointsActivity.user_num_completions !== null &&
          playForPointsActivity.freq_cap > playForPointsActivity.user_num_completions
        ) {
          gameConfig = await GamesService.getGameConfig(extraData.points_game_number);
        }
      }
    }

    if (!gameConfig) {
      gameConfig = await GamesService.getGameConfig(extraData.game_number);
    }

    dispatch({
      type: SUCCESS(GET_GAME_CONFIG),
      payload: { gameConfig, playForPointsActivity }
    });
  } catch (error) {
    dispatch({ type: FAILURE(GET_GAME_CONFIG) });
  }
};

export const getPlayForPointsActivity = (extraData: any) => async (dispatch: any) => {
  try {
    dispatch({ type: REQUEST(GET_PLAY_FOR_POINTS_ACTIVITY) });
    let playForPointsActivity: Activity | null = null;
    if (extraData && extraData.is_play_for_fun && extraData.is_play_for_fun === 'true') {
      playForPointsActivity = Activity.JsonToActivity(await LoyaltyService.getActivity(extraData.points_activity_id));
    }
    dispatch({
      type: SUCCESS(GET_PLAY_FOR_POINTS_ACTIVITY),
      payload: { playForPointsActivity }
    });
  } catch (error) {
    dispatch({ type: FAILURE(GET_PLAY_FOR_POINTS_ACTIVITY) });
  }
};

export const updateGame = (gameConfigId: string) => (dispatch: any) => {
  dispatch({
    type: UPDATE_GAME_CONFIG,
    payload: GamesService.updateGame(gameConfigId)
  }).catch(error => {
    dispatch(showMessageBar({ message: error.payload ? error.payload.errorKey : 'Error to load game config', type: 'error' }));
  });
};

export const startGame = (digitalRevealToken: string) => (dispatch: any) => {
  dispatch({
    type: START_GAME,
    payload: InteractiveGameService.startGame(digitalRevealToken)
  }).catch(error => {
    dispatch(showMessageBar({ message: error.payload.errorKey, type: 'error' }));
  });
};

export const endGame = (digitalRevealToken: string) => (dispatch: any) => {
  dispatch({
    type: END_GAME,
    payload: InteractiveGameService.endGame(digitalRevealToken)
  }).catch(error => {
    dispatch(showMessageBar({ message: error.payload.errorKey, type: 'error' }));
  });
};

export const skipGame = (digitalRevealToken: string) => (dispatch: any) => {
  dispatch({
    type: SKIP_GAME,
    payload: InteractiveGameService.skipGame(digitalRevealToken)
  }).catch(error => {
    dispatch(showMessageBar({ message: error.payload.errorKey, type: 'error' }));
  });
};

export const fetchPlayableDigitalRevealGames = (page: number) => async (dispatch: any) => {
  try {
    dispatch({
      type: FETCH_DIGITAL_REVEAL_PLAYABLE,
      payload: InteractiveGameService.getPlayableTokens(constants.HISTORY_ITEMS_PER_PAGE, page)
    });
  } catch (e: any) {
    dispatch(showMessageBar({ message: e.payload.errorKey, type: 'error' }));
  }
};
