import { ProfileData } from '@pbl/pbl-react-core/lib/models/profile';
import {
  AddressType,
  GET_INSTAGRAM_ACCESS_TOKEN,
  GET_PROFILE,
  GET_TWITTER_OAUTH_TOKEN,
  GET_TWITTER_REQUEST_TOKEN,
  IVerifyId,
  IVerifyStatus,
  RENEW_IDOLOGY_URL,
  SOCIAL_CONNECT,
  UPDATE_PROFILE,
  VERIFY_PROFILE,
  VERIFY_STATUS
} from '@pbl/pbl-react-core/lib/models/profile/types';
import { PlayerDetails } from '@pbl/pbl-react-core/lib/models/store/types';
import { ProfileService, SocialService } from '@pbl/pbl-react-core/lib/services';
import constants from 'config/constants';
import { FAILURE, SUCCESS } from 'redux/action-type.util';
import { clearLoading, clearMessages, showMessageBar, toggleLoading } from 'redux/reducers/app/actions';
import { getAccount } from 'redux/reducers/authentication/actions';
import { GetStateMethod } from '../index';

export const getProfile = () => async (dispatch: any) => {
  try {
    dispatch(toggleLoading({ spinning: true }));
    await dispatch({
      type: GET_PROFILE,
      payload: ProfileService.getProfileV2()
    });
  } catch (e: any) {
    dispatch(showMessageBar({ message: e.payload?.error, type: 'error' }));
  } finally {
    dispatch(clearLoading());
  }
};

export const updateProfile = (data: any) => async (dispatch: any, getState: GetStateMethod) => {
  try {
    dispatch(clearMessages());
    const {
      profile: { userProfile }
    } = getState();

    const requestData = ProfileData.getPutRequest(data, userProfile, constants.LOTTERY_ID);
    await dispatch({
      type: UPDATE_PROFILE,
      payload: ProfileService.updateProfileV2(requestData)
    });

    const {
      profile: { updateSucceeded }
    } = getState();

    if (updateSucceeded) {
      dispatch(showMessageBar({ message: 'profile.updated' }));
      dispatch(getAccount());
    }
  } catch (e: any) {
    dispatch(showMessageBar({ message: e.payload.error, type: 'error' }));
  }
};

export const updateBillingAddress = (data: any) => async (dispatch: any, getState: GetStateMethod) => {
  try {
    dispatch(clearMessages());
    const {
      profile: { userProfile }
    } = getState();

    const requestData = ProfileData.getPutRequestBilling(data, userProfile, constants.LOTTERY_ID);
    await dispatch({
      type: UPDATE_PROFILE,
      payload: ProfileService.updateProfileV1(requestData)
    });
    const {
      profile: { updateSucceeded }
    } = getState();
    if (updateSucceeded) {
      dispatch(showMessageBar({ message: 'profile.updated' }));
      dispatch(getAccount());
    }
  } catch (e: any) {
    dispatch(showMessageBar({ message: e.payload.error, type: 'error' }));
  }
};

export const socialConnect = (provider: string, data: any) => async (dispatch: any) => {
  try {
    dispatch(clearMessages());
    dispatch(toggleLoading({ spinning: true }));

    await dispatch({
      type: SOCIAL_CONNECT,
      payload: ProfileService.socialConnect(provider, data)
    });

    await dispatch(getAccount());
  } catch (e: any) {
    dispatch(showMessageBar({ message: e.payload.error, type: 'error' }));
  } finally {
    dispatch(toggleLoading({ spinning: false }));
  }
};

export const refreshAccount = () => async (dispatch: any) => {
  try {
    dispatch(clearMessages());
    dispatch(toggleLoading({ spinning: true }));

    await dispatch(getAccount());
  } catch (e: any) {
    dispatch(showMessageBar({ message: e.payload.error, type: 'error' }));
  } finally {
    dispatch(toggleLoading({ spinning: false }));
  }
};

export const twitterGetRequestToken = () => async (dispatch: any) => {
  try {
    await dispatch({
      type: GET_TWITTER_REQUEST_TOKEN,
      payload: SocialService.getTwitterRequestToken()
    });
  } catch (e) {
    throw e;
  }
};

export const twitterGetOAuthToken = (oauthVerifier: string, oauthToken: string) => async (dispatch: any) => {
  try {
    await dispatch({
      type: GET_TWITTER_OAUTH_TOKEN,
      payload: SocialService.getTwitterOauthToken(oauthVerifier, oauthToken)
    });
  } catch (e) {
    throw e;
  }
};

export const instagramGetOAuthToken = (code: string, redirectUrl: string) => async (dispatch: any) => {
  try {
    await dispatch({
      type: GET_INSTAGRAM_ACCESS_TOKEN,
      payload: SocialService.getInstagramRequestToken(code, redirectUrl)
    });
  } catch (e) {
    throw e;
  }
};

export const verifyIdentity = (playerDetails: PlayerDetails, suc: (verifyId: IVerifyId) => void, err: (e: any) => void) => async (dispatch: any, getState: GetStateMethod) => {
  try {
    const {
      profile: { userProfile }
    } = getState();
    const payload = await ProfileService.verifyId({
        id: userProfile.id,
        lotteryId: constants.LOTTERY_ID,
        login: userProfile.login,
        firstName: playerDetails.firstName,
        lastName: playerDetails.lastName,
        birthDate: playerDetails.dateOfBirth,
        email: playerDetails.email,
        phones: [{ phone: playerDetails.phone1 }],
        addresses: [{
            id: userProfile.addresses?.filter(x => x.addressTypeId === AddressType.HOME)?.map(x => x.id)?.[0] || null,
            address1: playerDetails.address1,
            state: playerDetails.state,
            city: playerDetails.city,
            countryCode: playerDetails.countryCode,
            country: playerDetails.country,
            zipCode: playerDetails.zip,
            stateCode: playerDetails.stateCode
          }
        ],
        ssnLast4: playerDetails.ssnLast4
      }
      );

      await dispatch({
        type: SUCCESS(VERIFY_PROFILE),
        payload
      });
    suc?.(payload);
    if (payload.verified) {
      await dispatch(showMessageBar({ message: 'profile.updated' }));
    }
  } catch (e) {
    console.error('Unable to verify identity', e);
    err?.(e);
  }
};

export const verifyStatus = (success?: (status: IVerifyStatus) => void) => async (dispatch: any) => {
  try {
    const response = await ProfileService.verifyStatus();
    await dispatch({
      type: SUCCESS(VERIFY_STATUS),
      payload: response
    });
    success?.(response);
  } catch (e) {
    console.error('Unable to verify identity status', e);
  }
};

export const renewUrl = (success: (status: IVerifyId) => void, error: () => void) => async (dispatch: any) => {
  try {
    dispatch(toggleLoading({ spinning: true }));
    const response = await ProfileService.renewIdologyUrl();
    await dispatch({
      type: SUCCESS(RENEW_IDOLOGY_URL),
      payload: response
    });
    success(response);
  } catch (e: any) {
    await dispatch({
      type: FAILURE(RENEW_IDOLOGY_URL)
    });
    error();
  } finally {
  dispatch(clearLoading());
}
};
