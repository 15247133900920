import Grid from '@material-ui/core/Grid';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { IErrorParams } from '@pbl/pbl-react-core/lib/models/app';
import EarnActivityHeader from '@pbl/pbl-react-web-components/lib/earn/earn-activity-header';
import { EarnPointsModal } from '@pbl/pbl-react-web-components/lib/package';
import styles from 'assets/jss/modules/survey/SurveyScreenStyle';
import { useIsLoggedIn } from 'hooks/useIsLoggedIn';
import { useScrollToTop } from 'hooks/useScrollToTop';
import { useActivityDetailsData } from 'modules/earn/hooks/useActivityDetailsData';
import React, { useCallback } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import { credits } from 'redux/reducers/activity/actions';
import { showMessageBar } from 'redux/reducers/app/actions';

interface IProps extends WithStyles<typeof styles>, WithTranslation {}

const ViewURLScreen: React.FC<IProps> = ({ classes, t }) => {
  const { activityId }: Record<string, string> = useParams();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { push: navigate } = useHistory();

  const onNavigate = useCallback(() => navigate('/earn', { from: pathname }), [navigate, pathname]);
  const navigateToActivities = useCallback((message?: IErrorParams) => navigate('/earn', { message }), [navigate]);

  const showInvalidError = useCallback(() => {
    navigateToActivities({
      message: t('activities.activityUnavailable'),
      type: 'warning',
      messageTimeout: 3000
    });
  }, [navigateToActivities, t]);

  const { activity, initData } = useActivityDetailsData(activityId, showInvalidError);
  const loggedIn: boolean = useIsLoggedIn();

  const [activityComplete, setActivityCompleted] = React.useState(false);
  useScrollToTop();

  const onViewUrl = () => {
    if (!loggedIn) {
      navigate('/login', { from: pathname });

      return;
    }
    if (activity?.externalLink) {
      window.open(activity?.externalLink);

      dispatch(
        credits(
          activity.key,
          () => {
            initData();
            setActivityCompleted(true);
          },
          (e: any) => {
            dispatch(
              showMessageBar({
                message: e?.payload?.detail?.includes('invalidAccess') ? t('error.activity.invalidAccess') : t(e?.payload?.errorKey),
                type: 'error'
              })
            );
          }
        )
      );
    }
  };

  const onDismissEarnPointsModal = (): void => {
    if (activity?.frequencyCap?.limitReached) {
      navigate('/earn');

      return;
    }

    setActivityCompleted(false);
  };

  if (!activity) {
    return null;
  }

  return (
    <div className={classes.container}>
      {activityComplete && (
        <EarnPointsModal
          pointEarned={activity.amount + (activity.bonusAmount ?? 0)}
          isVisible={activityComplete}
          onClose={onDismissEarnPointsModal}
        />
      )}
      <Grid className={classes.container} container={true} spacing={2}>
        <Grid item={true} xs={12}>
          <EarnActivityHeader
            data={{
              image: activity.imageUrl,
              title: activity.title,
              description: activity.description,
              points: activity.amount,
              preview_url: activity.videoUrl ? activity.videoUrl : undefined,
              isCoverImage: true,
              extraData: activity?.extraData
            }}
            bonusPoints={activity.bonusAmount ? activity.bonusAmount + activity.amount : 0}
            limitReached={loggedIn && !!activity?.frequencyCap?.limitReached}
            navigateTo={onNavigate}
            onPress={onViewUrl}
            buttonText={'View'}
            buttonLabel={'Link opens in a new browser tab'}
            buttonLabelIcon={'open_in_new'}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default withTranslation()(withStyles(styles)(ViewURLScreen));
