import { CLEAR_APP_STATE } from '@pbl/pbl-react-core/lib/models/app';
import {
  CLEAR_AUTHENTICATION_ERROR,
  GET_ACCOUNT,
  GET_USER,
  LOGIN,
  RESET_AUTHENTICATION,
  SEND_ACCOUNT_ACTIVATION_INSTRUCTIONS,
  SEND_PASSWORD_RESET_INSTRUCTIONS
} from '@pbl/pbl-react-core/lib/models/authentication';
import { AccountService, ProfileService, SessionService } from '@pbl/pbl-react-core/lib/services';
import _ from 'lodash';
import { AuthService } from 'utils/auth-service';
import NavigationUtils from 'utils/NavigationUtils';
import { FAILURE, REQUEST, SUCCESS } from '../../action-type.util';
import { reactivateAccount } from '../account-reactivation/actions';
import { clearLoading, clearMessages, showMessageBar, toggleLoading } from '../app/actions';
import { DispatchMethod, GetStateMethod } from '../index';

export const getAccount = () => ({
  type: GET_ACCOUNT,
  payload: ProfileService.getProfileV2()
});

export const loginWithToken = (
  accessToken: string,
  userId: string,
  refreshToken?: string,
  successCallback?: () => void,
  errorCallback?: () => void
) => async (dispatch: any, getState: GetStateMethod) => {
  try {
    dispatch(toggleLoading({ spinning: true }));

    await dispatch(getAccount());

    const {
      authentication: { account }
    } = getState();
    if (!_.some(_.values(account), val => !_.isEmpty(val))) {
      loginError(new Error('profile not found'), errorCallback, dispatch, true);
    } else {
      dispatch({
        type: SUCCESS(LOGIN),
        payload: { access_token: accessToken, refresh_token: refreshToken }
      });

      AuthService.updateLocalStorage(accessToken, refreshToken, userId);

      const userData = {
        email: '',
        uid: userId
      }; // get minimal user info from jwt token

      dispatch({
        type: GET_USER,
        payload: userData
      }); // save in the redux store and persist
      if (successCallback) {
        successCallback();
      }
      dispatch(clearMessages());
    }
  } catch (e) {
    loginError(e, errorCallback, dispatch, false);
  } finally {
    dispatch(toggleLoading({ spinning: false }));
  }
};

export const login = (
  username: string,
  password: string,
  rememberMe: boolean,
  successCallback: () => void,
  errorCallback?: () => void
) => async (dispatch: any, getState: GetStateMethod) => {
  try {
    dispatch({
      type: REQUEST(LOGIN)
    });
    dispatch(toggleLoading({ spinning: true }));

    const payload = await AuthService.login({ username, password, rememberMe });
    await SessionService.status();
    // @ts-ignore
    await dispatch(getAccount(payload.access_token));

    const {
      authentication: { account }
    } = getState();
    if (!_.some(_.values(account), val => !_.isEmpty(val))) {
      loginError(new Error('profile not found'), errorCallback, dispatch, true);
    } else {
      dispatch({
        type: SUCCESS(LOGIN),
        payload
      });
      const { access_token: accessToken, refresh_token: refreshToken } = payload;
      // @ts-ignore
      const decodedToken = AuthService.decodeToken(accessToken) as {login: string, email: string};

      AuthService.updateLocalStorage(accessToken, refreshToken, decodedToken.login);

      const userData = {
        // @ts-ignore
        email: decodedToken.email,
        // @ts-ignore
        uid: decodedToken.login
      }; // get minimal user info from jwt token

      dispatch({
        type: GET_USER,
        payload: userData
      }); // save in the redux store and persist
      successCallback();
      // exponea
      // @ts-ignore
      if (window.exponea && decodedToken.login) {
        // @ts-ignore
        window.exponea.identify({ user_key: decodedToken.login });
      }
      dispatch(clearMessages());
    }
  } catch (e) {
    loginError(e, errorCallback, dispatch, false);
  } finally {
    dispatch(toggleLoading({ spinning: false }));
  }
};

const loginError = (e, errorCallback, dispatch: any, isProfileEmpty: boolean) => {
  dispatch({
    type: FAILURE(LOGIN),
    payload: e
  });

  if (isProfileEmpty) {
    dispatch(
      showMessageBar({
        message: 'error.uaaAuthentication.emptyProfile',
        type: 'error'
      })
    );
  } else {
    if (e.payload && e.payload.additionalInformation) {
      const errorKey = e.payload.additionalInformation.errorKey;
      const message = e.payload.additionalInformation.message;

      switch (errorKey) {
        case 'error.uaaAuthentication.notActivated':
          dispatch(
            showMessageBar({
              message: 'error.uaaAuthentication.notActivated',
              type: 'error'
            })
          );
          break;
        case 'error.uaaAuthentication.lockout':
          dispatch(showAccountLockedMessage());
          break;
        case 'error.uaaAuthentication.lockoutAttempt':
          dispatch(showAccountLockedAttemptMessage());
          break;
        case 'error.uaaAuthentication.warning':
          const remainingAuthAttempts = e.payload.additionalInformation.remainingAuthAttempts;
          dispatch(
            showMessageBar({
              message: 'error.uaaAuthentication.warning',
              type: 'warning',
              messageParams: { remainingAuthAttempts }
            })
          );
          break;
        case 'error.uaaAuthentication.suspended':
          dispatch(reactivateAccount(true));
          break;
        case 'error.uaaAuthentication.invalid':
        default:
          if (message && message === 'error.uaaAuthentication.lockout') {
            dispatch(showAccountLockedMessage());
          } else {
            dispatch(
              showMessageBar({
                message: 'error.uaaAuthentication.invalid',
                type: 'error'
              })
            );
          }
          break;
      }
    }
  }
  if (errorCallback) {
    errorCallback();
  }
};

export const logout = () => async (dispatch: any) => {
  try {
    dispatch({
      type: REQUEST('authentication/LOGOUT')
    });
    await AuthService.logout(); // clear cookies and API cache
  } catch (e: any) {
    dispatch(showMessageBar({ message: e.payload.error, type: 'error', messageTimeout: 10000 }));
  } finally {
    dispatch({
      type: SUCCESS('authentication/LOGOUT')
    });
    /*
     * Persisted states have to be cleared prior to calling CLEAR_APP_STATE.
     * Otherwise redux-persist will rehydrate with the saved states
     */
    await dispatch(reset()); // clear authentication state

    await dispatch({ type: CLEAR_APP_STATE }); // clear app state

    NavigationUtils.replace('/redirect-back', { redirect: NavigationUtils.currentState() });

    AuthService.removeLocalStorage();
  }
};

export const sendPasswordResetInstructions = (emailAddress: string) => async (dispatch: any) => {
  try {
    const payload = await AccountService.sendPasswordResetInstructions(emailAddress);
    dispatch(clearMessages());
    dispatch({
      type: SUCCESS(SEND_PASSWORD_RESET_INSTRUCTIONS),
      payload
    });
    NavigationUtils.replace('/forgot-password-sent', { email: emailAddress });
  } catch (e: any) {
    if (e.payload && e.payload.errorKey) {
      const message = e.payload.errorKey;
      switch (message) {
        case 'error.uaaUser.resetPasswordLockout':
          dispatch(showAccountLockedMessage());
          break;
        case 'error.uaaUser.resetPasswordSuspended':
          dispatch(showAccountSuspendedMessage());
          break;
        case 'error.email.notfound':
          dispatch(showEmailNotFoundMessage());
          break;
        default:
          NavigationUtils.replace('/forgot-password-sent', { email: emailAddress });
          break;
      }
    } else {
      dispatch(showUnknownErrorMessage());
    }
  }
};

export const sendAccountActivationInstructions = (emailAddress: string) => async (dispatch: any) => {
  try {
    const payload = await AccountService.sendAccountActivationInstructions(emailAddress);
    dispatch({
      type: SUCCESS(SEND_ACCOUNT_ACTIVATION_INSTRUCTIONS),
      payload
    });
    NavigationUtils.replace('/activate-email-sent', { email: emailAddress });
  } catch (e: any) {
    if (e.payload && e.payload.errorKey) {
      const message = e.payload.errorKey;
      switch (message) {
        case 'error.uaaUser.resendActivationLockout':
          dispatch(showAccountLockedMessage());
          break;
        case 'error.uaaUser.resendActivationSuspended':
          dispatch(showAccountSuspendedMessage());
          break;
        case 'error.email.notfound':
          dispatch(showResentEmailNotFoundErrorMessage());
          break;
        default:
          NavigationUtils.replace('/activate-email-sent', { email: emailAddress });
          break;
      }
    } else {
      dispatch(showUnknownErrorMessage());
    }
  }
};

export const isAuthenticated = () => async (dispatch: any, getState: GetStateMethod) => {
  const isTokenValid = await AuthService.isAuthenticated();
  if (!isTokenValid && getState().authentication.accessToken) {
    dispatch(logout());
  }
  return isTokenValid;
};

export const reset = () => (dispatch: DispatchMethod) => {
  dispatch({
    type: RESET_AUTHENTICATION
  });
};

export const resetErrors = () => (dispatch: DispatchMethod) => {
  dispatch({
    type: CLEAR_AUTHENTICATION_ERROR
  });
};

export const deactivateAccount = () => async (dispatch: any) => {
  try {
    dispatch(clearMessages());
    dispatch(toggleLoading({ spinning: true }));

    await AccountService.deactivateAccount();

    await dispatch(logout());

    dispatch(showMessageBar({ message: 'account.deactivate.confirmationMsg' }));

    NavigationUtils.push('/');
  } catch (e: any) {
    dispatch(showMessageBar({ message: e.payload.title, type: 'error' }));
  } finally {
    dispatch(clearLoading());
  }
};

const showAccountLockedMessage = () =>
  showMessageBar({
    message: 'account.locked',
    type: 'error'
  });

const showAccountLockedAttemptMessage = () =>
  showMessageBar({
    message: 'account.lockedAttempt',
    type: 'error'
  });

const showAccountSuspendedMessage = () =>
  showMessageBar({
    message: 'account.suspended',
    type: 'error'
  });

const showEmailNotFoundMessage = () =>
  showMessageBar({
    message: 'account.emailNotFound',
    type: 'error'
  });

const showUnknownErrorMessage = () =>
  showMessageBar({
    message: 'error.general',
    type: 'error'
  });

const showResentEmailNotFoundErrorMessage = () =>
  showMessageBar({
    message: 'Please enter valid email address',
    type: 'error'
  });
