import Grid from '@material-ui/core/Grid';
import { Theme, withStyles, WithStyles, withTheme } from '@material-ui/core/styles';
import { IErrorParams } from '@pbl/pbl-react-core/lib/models/app';
import { IPromotion } from '@pbl/pbl-react-core/lib/models/draws/types';
import { IGame } from '@pbl/pbl-react-core/lib/models/games/types';
import AppSpinner from '@pbl/pbl-react-web-components/lib/app-spinner/AppSpinner';
import DrawGameDetails from '@pbl/pbl-react-web-components/lib/games/DrawGameDetails';
import DrawGameTabs from '@pbl/pbl-react-web-components/lib/games/DrawGameTabs';
import { RelatedGames, RelatedGamesDialog } from '@pbl/pbl-react-web-components/lib/package';
import DetailTitle from '@pbl/pbl-react-web-components/lib/title/DetailTitle';
import styles from 'assets/jss/modules/games/GamesScreenStyle';
import constants from 'config/constants';
import moment from 'moment';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { showMessageBar } from 'redux/reducers/app/actions';
import { selectPromotion } from 'redux/reducers/draws/actions';
import {
  fetchGame,
  fetchPromotionsForGame,
  fetchRelatedGames,
  getAllRelatedGames,
  getJackpotResultsForTheGame,
  getLatestJackpotResultForTheGame,
  resetGameState
} from 'redux/reducers/games/actions';
import { scrollToTheTop } from 'utils/htmlUtil';

interface IMatchParams {
  gameId: string;
}

export interface IDrawGameDetailScreenProps
  extends StateProps,
    DispatchProps,
    WithTranslation,
    WithStyles<typeof styles>,
    RouteComponentProps<IMatchParams> {
  theme: Theme;
}

interface IDrawGameDetailScreenState {
  showModal: boolean;
  gameId: number;
}

class DrawGameDetailScreen extends React.Component<IDrawGameDetailScreenProps, IDrawGameDetailScreenState> {
  constructor(props: IDrawGameDetailScreenProps) {
    super(props);
    this.state = {
      showModal: false,
      gameId: 0
    };
  }

  public componentDidMount = async () => {
    document.title = 'Quick Draw Game Details';
    const {
      match: { params }
    } = this.props;
    if (params.gameId.length > 0) {
      scrollToTheTop();
      await this.loadGame(params.gameId);
    } else {
      await this.showInvalidGameError();
    }
  };

  public componentWillUnmount = async () => {
    await this.props.resetGameState();
  };

  public selectGame = async (game: IGame) => {
    scrollToTheTop();
    if (game.gameTypeId === 2) {
      this.props.history.push(`/games-draw/${game.id}`);
    } else {
      this.props.history.push(`/games/${game.id}`);
    }
    await this.props.resetGameState();
    await this.loadGame(game.id);
  };

  public render(): React.ReactNode {
    const { classes, history, location } = this.props;
    const { selectedGame, loading, relatedGames, allRelatedGames, nextJackpotResult, latestJackpotResult, jackpotResults, jackpotPage } =
      this.props.games;
    const onNavigate = () => {
      history.push('/games');
    };
    const navigateToTicketEntry = () => {
      history.push('/ticket-entry', { from: location.pathname });
    };
    if (!selectedGame) {
      return null;
    }

    const jackpotResultsFiltered = jackpotResults.filter(el => {
      const twelveMonthsAgo = moment().subtract(12, 'months');
      const isWithinLast12Months = moment(el.drawDate).isAfter(twelveMonthsAgo);
      return isWithinLast12Months;
    });

    const filterGames = (games: any, allRelatedGame: any) => {
      const hasNewMegaMillionsGame = allRelatedGame.some(
        game => game.gameId === constants.NEW_MEGA_MILLION_GAME_ID ||
        selectedGame.gameId === constants.NEW_MEGA_MILLION_GAME_ID
      );

      return hasNewMegaMillionsGame ? games.filter(game => game.gameId !== constants.OLD_MEGA_MILLIONS_GAME_ID) : games;
    };

    const isNewMegaMillionsGame = allRelatedGames.some(
      game => game.gameId === constants.NEW_MEGA_MILLION_GAME_ID ||
      selectedGame.gameId === constants.NEW_MEGA_MILLION_GAME_ID
    );

    if (isNewMegaMillionsGame && selectedGame.gameId === constants.OLD_MEGA_MILLIONS_GAME_ID) {
      return null;
  }

    const filterAllRelatedGames = filterGames(allRelatedGames, allRelatedGames);
    const filterRelatedGames = filterGames(relatedGames, allRelatedGames);

    const jackpotResultsForGame = selectedGame.gameId === constants.NEW_MEGA_MILLION_GAME_ID ? jackpotResultsFiltered : jackpotResults;
    return (
      <div className={classes.root}>
        <Grid container={true}>
          {loading && <AppSpinner label={'Loading Game...'} />}
          <RelatedGamesDialog
            title={'Related Games'}
            relatedGames={filterAllRelatedGames}
            selectGame={this.selectGame}
            handleClose={this.onModalClose}
            openDialog={this.state.showModal}
          />
          <DetailTitle
            title={selectedGame.name}
            linkText={'Games'}
            navigateTo={onNavigate}
            buttonText={'Enter Now'}
            onPress={navigateToTicketEntry}
          />
          <DrawGameDetails selectedGame={selectedGame} latestJackpotResult={latestJackpotResult} nextJackpotResult={nextJackpotResult} />
          <DrawGameTabs
            jackpotResults={jackpotResultsForGame}
            draws={this.props.games.draws}
            drawsPage={this.props.games.drawsPage}
            jackpotPage={jackpotPage}
            getNextDrawsPage={this.getNextDrawsPage}
            getNextJackpotResultsPage={this.getNextJackpotResultsPage}
            onDrawClicked={this.onDrawClicked}
            topPrizeType={selectedGame.topPrizeType}
          />
          <RelatedGames
            relatedGames={filterRelatedGames}
            selectGame={this.selectGame}
            showRelatedGames={this.onModalOpen}
            title={this.props.t('gameDetails.relatedGamesTitle')}
          />
        </Grid>
      </div>
    );
  }

  private loadGame = async gameId => {
    await this.setState({ gameId });
    await Promise.all([
      this.props.fetchGame(gameId),
      this.props.getJackpotResultsForTheGame(gameId, 0),
      this.props.getLatestJackpotResultForTheGame(gameId),
      this.props.fetchPromotionsForGame(gameId, 0),
      this.props.fetchRelatedGames(gameId),
      this.props.getAllRelatedGames(gameId)
    ]);
  };

  public getNextDrawsPage = async (pageNumber: number) => {
    await this.props.fetchPromotionsForGame(this.state.gameId, pageNumber);
  };
  public getNextJackpotResultsPage = async (pageNumber: number) => {
    await this.props.getJackpotResultsForTheGame(this.state.gameId, pageNumber);
    scrollToTheTop();
  };
  private showInvalidGameError = async () => {
    await this.navigateToGames({
      message: `This game is not available.`,
      type: 'warning',
      messageTimeout: 3000
    });
  };

  private navigateToGames = async (message?: IErrorParams) => {
    const { history } = this.props;
    await history.push('/games', { message });
  };
  private onModalOpen = () => {
    this.setState({ showModal: true });
  };
  private onModalClose = () => {
    this.setState({ showModal: false });
  };

  private onDrawClicked = (promotion: IPromotion): void => {
    this.props.history.push('/promotion/' + promotion.id);
  };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

const mapStateToProps = ({ games }) => ({
  games
});

const mapDispatchToProps = {
  fetchGame,
  fetchPromotionsForGame,
  fetchRelatedGames,
  getAllRelatedGames,
  resetGameState,
  showMessageBar,
  selectPromotion,
  getJackpotResultsForTheGame,
  getLatestJackpotResultForTheGame
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-ignore
)(withTheme(withTranslation()(withRouter(withStyles(styles)(DrawGameDetailScreen)))));
