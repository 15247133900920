import {
  IPreferenceCategory,
  IPreferencesState,
  PREFERENCES_FETCH,
  PREFERENCES_FETCH_OPTIONS,
  PREFERENCES_LOAD,
  PREFERENCES_SAVE,
  PREFERENCES_TOGGLE,
  PREFERENCES_TOGGLE_ALL,
  PREFERENCES_UPDATE_SUB_CATEGORY
} from '@pbl/pbl-react-core/lib/models/preferences';
import { PreferencesService } from '@pbl/pbl-react-core/lib/services';
import _ from 'lodash';
import { clearMessages, showMessageBar } from 'redux/reducers/app/actions';
import { DispatchMethod, GetStateMethod } from '../index';

export const fetchOptions = () => async (dispatch: any) => {
  try {
    await dispatch({
      type: PREFERENCES_FETCH_OPTIONS,
      payload: PreferencesService.fetchOptions()
    });
  } catch (e: any) {
    dispatch(showMessageBar({ message: e.payload.error, type: 'error' }));
  }
};

export const fetchMyPreferences = () => async (dispatch: any, getState: GetStateMethod) => {
  try {
    dispatch(clearMessages());
    const {
      authentication: { account }
    } = getState();
    const uid = account ? account.login : '';

    await dispatch({
      type: PREFERENCES_FETCH,
      payload: PreferencesService.fetch(uid)
    });
  } catch (e: any) {
    dispatch(showMessageBar({ message: e.payload.error, type: 'error' }));
  }
};

export const loadMyPreferences = () => async (dispatch: any, getState: GetStateMethod) => {
  try {
    const { preferences } = getState();
    const filteredPrefs = _.filter(preferences.categories, (cat: IPreferenceCategory) => {
      if (cat.subCategories && cat.subCategories.length > 0) {
        cat.subCategories = _.filter(cat.subCategories, subCat => subCat.channel === 'EMAIL' || subCat.channel === 'OMNI');
      }
      cat['mainCat'] = true;
      if (cat.channel === 'EMAIL' || cat.channel === 'OMNI') return cat;
    });
    dispatch({
      type: PREFERENCES_LOAD,
      payload: filteredPrefs || {}
    });
  } catch (e: any) {
    dispatch(showMessageBar({ message: e.payload.error, type: 'error' }));
  }
};

export const toggleAllPreference = () => (dispatch: DispatchMethod) => {
  dispatch({
    type: PREFERENCES_TOGGLE_ALL
  });
};

export const togglePreference = (pref: string) => (dispatch: DispatchMethod) => {
  dispatch({
    type: PREFERENCES_TOGGLE,
    payload: pref
  });
};

const flattenCategories = (list: IPreferenceCategory[]) =>
  _.reduce(
    list,
    (result, current) => {
      const parent = {
        key: current.key,
        tag: current.tag,
        channel: current.channel,
        booleanValue: current.booleanValue || false,
        value: current.value,
        options: current.options
      };
      const subCategories = _.map(current.subCategories || [], ({ key, tag, channel, booleanValue, value, options }) => ({
        key,
        tag,
        channel,
        booleanValue: booleanValue || false,
        value,
        options
      }));
      return [...result, parent, ...subCategories];
    },
    [] as IPreferenceCategory[]
  );

export const savePreferences = () => async (dispatch: any, getState: GetStateMethod) => {
  try {
    dispatch(clearMessages());

    const {
      authentication: { account },
      preferences: { tempState }
    } = getState();

    if (!_.isEmpty(account)) {
      const formCategories = flattenCategories((tempState as IPreferencesState).categories);
      const uid = account ? account.login : '';
      await dispatch({
        type: PREFERENCES_SAVE,
        payload: PreferencesService.save(uid, formCategories)
      });
      dispatch(showMessageBar({ message: 'preferences.updated' }));
    }
  } catch (e: any) {
    dispatch(showMessageBar({ message: e.payload.error, type: 'error' }));
  }
};

export const updateSubCategory = (category?: IPreferenceCategory) => (dispatch: DispatchMethod) => {
  dispatch({
    type: PREFERENCES_UPDATE_SUB_CATEGORY,
    payload: category
  });
};
