import { filters } from '@pbl/pbl-react-core/lib/models/arcades/filters';
import {
  ArcadesGameState,
  DEBIT_ARCADE,
  END_DIGITAL_REVEAL_ARCADE,
  END_NGL_GAME,
  END_NGL_GAME_FUN,
  GET_ARCADE,
  GET_ARCADE_DENOMINATIONS,
  GET_ARCADE_GAMES,
  GET_DIGITAL_REVEAL_ARCADE,
  GET_NGL_GAME_DATA,
  GET_NGL_SESSION,
  IArcadeInitialState,
  PLAY_FOR_FUN,
  RESET_ARCADE,
  RESET_STATE,
  SET_NGL_TOKEN,
  START_NGL_GAME,
  START_NGL_GAME_FUN,
  START_NGL_GAME_SESSION,
  START_NGL_GAME_SESSION_FUN,
  UNAUTHENTICATED_USER_GAME_DATA
} from '@pbl/pbl-react-core/lib/models/arcades/types';
import * as _ from 'lodash';
import { IDispatchAction } from 'redux/reducers';
import FiltersEnhancer from 'shared/components/filters/filters.reducers-enhancer';
import { FAILURE, REQUEST, SUCCESS } from '../../action-type.util';

const INITIAL_STATE: IArcadeInitialState = {
  filters
} as IArcadeInitialState;

export default (state: ArcadesGameState = INITIAL_STATE, action: IDispatchAction): ArcadesGameState => {
  switch (action.type) {
    case REQUEST(GET_ARCADE_GAMES):
    case REQUEST(GET_ARCADE):
    case REQUEST(GET_DIGITAL_REVEAL_ARCADE):
    case REQUEST(DEBIT_ARCADE):
    case REQUEST(GET_ARCADE_DENOMINATIONS):
    case REQUEST(END_DIGITAL_REVEAL_ARCADE):
    case REQUEST(GET_NGL_GAME_DATA):
    case REQUEST(START_NGL_GAME_SESSION):
    case REQUEST(START_NGL_GAME):
    case REQUEST(END_NGL_GAME):
      return {
        ...state,
        loading: true
      };
    case SUCCESS(GET_ARCADE_GAMES):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        games: action.payload.parsedGames,
        showErrorLoadingGame: false
      };
    case SUCCESS(GET_ARCADE):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        selectedGame: action.payload,
        showErrorLoadingGame: false
      };
    case SUCCESS(GET_DIGITAL_REVEAL_ARCADE):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        digitalRevealTokenDetails: action.payload,
        showErrorLoadingGame: false
      };
    case SUCCESS(DEBIT_ARCADE):
    case SUCCESS(PLAY_FOR_FUN):
      return {
        ...state,
        loading: false,
        showErrorLoadingGame: false,
        errorMessage: null,
        sweepstakesGameConfig: action.payload
      };
    case SUCCESS(GET_ARCADE_DENOMINATIONS):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        denominations: action.payload ? _.orderBy(action.payload.denominations, 'value', 'asc') : [],
        showErrorLoadingGame: false
      };
    case SUCCESS(END_DIGITAL_REVEAL_ARCADE):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        showErrorLoadingGame: false
      };
    case SUCCESS(GET_NGL_GAME_DATA):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        selectedNGLGame: action.payload.response,
        showErrorLoadingGame: false
      };
    case SUCCESS(START_NGL_GAME_SESSION):
      return {
        ...state,
        loading: false,
        showErrorLoadingGame: false,
        errorMessage: null,
        sessionDataNGL: action.payload
      };
    case SUCCESS(START_NGL_GAME):
      return {
        ...state,
        loading: false,
        showErrorLoadingGame: false,
        errorMessage: null,
        sessionDataNGL: { clientRequestToken: action.payload.clientRequestToken, clientSessionId: action.payload.clientSessionId },
        playDataNGLGame: action.payload.result
      };
    case SUCCESS(END_NGL_GAME):
      return {
        ...state,
        loading: false,
        showErrorLoadingGame: false,
        errorMessage: null,
        sessionDataNGL: { clientRequestToken: action.payload.clientRequestToken, clientSessionId: action.payload.clientSessionId }
      };
    case SUCCESS(START_NGL_GAME_SESSION_FUN):
      return {
        ...state,
        loading: false,
        showErrorLoadingGame: false,
        errorMessage: null,
        sessionDataNGLFun: action.payload
      };
    case SUCCESS(START_NGL_GAME_FUN):
      return {
        ...state,
        loading: false,
        showErrorLoadingGame: false,
        errorMessage: null,
        sessionDataNGLFun: { clientRequestToken: action.payload.clientRequestToken, clientSessionId: action.payload.clientSessionId },
        playDataNGLGame: action.payload.result
      };
    case SUCCESS(END_NGL_GAME_FUN):
      return {
        ...state,
        loading: false,
        showErrorLoadingGame: false,
        errorMessage: null,
        sessionDataNGLFun: { clientRequestToken: action.payload.clientRequestToken, clientSessionId: action.payload.clientSessionId }
      };
    case SUCCESS(SET_NGL_TOKEN):
      return {
        ...state,
        showErrorLoadingGame: false,
        launchToken: action.payload.subjectToken
      };
    case SUCCESS(GET_NGL_SESSION):
      return {
        ...state,
        showErrorLoadingGame: false,
        sessionToken: action.payload
      };
    case RESET_ARCADE:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        selectedGame: null,
        sweepstakesGameConfig: null,
        digitalRevealTokenDetails: null,
        denominations: null,
        sessionDataNGL: null,
        sessionDataNGLFun: null,
        playDataNGLGame: null,
        selectedNGLGame: null,
        showErrorLoadingGame: false
      };
    case FAILURE(GET_ARCADE_GAMES):
    case FAILURE(GET_ARCADE):
    case FAILURE(GET_DIGITAL_REVEAL_ARCADE):
    case FAILURE(DEBIT_ARCADE):
    case FAILURE(GET_ARCADE_DENOMINATIONS):
    case FAILURE(END_DIGITAL_REVEAL_ARCADE):
    case FAILURE(GET_NGL_GAME_DATA):
    case FAILURE(START_NGL_GAME_SESSION):
    case FAILURE(START_NGL_GAME):
    case FAILURE(END_NGL_GAME):
    case FAILURE(START_NGL_GAME_SESSION_FUN):
    case FAILURE(START_NGL_GAME_FUN):
    case FAILURE(END_NGL_GAME_FUN):
      return {
        ...state,
        loading: false,
        showErrorLoadingGame: true,
        errorMessage: action.payload
      };
    case RESET_STATE:
      return {
        ...INITIAL_STATE
      };
    case UNAUTHENTICATED_USER_GAME_DATA:
      return {
        ...state,
        gameType: action.payload.gameType,
        deferredPlay: action.payload.deferredPlay,
        wagerIndex: action.payload.wagerIndex,
        deferredGame: action.payload.deferredGame
      };
    default:
      return FiltersEnhancer.processFilters('arcade', state, action) as IArcadeInitialState;
  }
};
