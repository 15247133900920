import { Button, Hidden, Link, Theme, Tooltip, Typography, withTheme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { IActivity } from '@pbl/pbl-react-core/lib/models/activity/types';
import { IShoppingCartItem } from '@pbl/pbl-react-core/lib/models/store/types';
import ConvertToTokensModal from '@pbl/pbl-react-web-components/lib/arcade/ConvertToTokensModal';
import {
  AppSpinner,
  ArcadeCurrency,
  ConversionContainer,
  Icon,
  RedeemConfirmationModal,
  RedeemFailedModal,
  ShoppingCart,
  StoreCard,
  ThresHoldMessageModal
} from '@pbl/pbl-react-web-components/lib/package';
import TokenPrimary from 'assets/img/sweepstakes/icons-lucky-lounge-token-primary-color@2x.png';
import styles from 'assets/jss/modules/store/StoreScreenStyle';
import classNames from 'classnames';
import constants from 'config/constants';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IRootState } from 'redux/reducers';
import { fetchActivity } from 'redux/reducers/activity/actions';
import { clearMessages, showMessageBar, toggleLoading } from 'redux/reducers/app/actions';
import { fetchUserPoints, fetchUserTokens, redeemFromPoints } from 'redux/reducers/ledger/actions';
import { resetSelectedReward } from 'redux/reducers/loyalty/actions';
import { getProfile } from 'redux/reducers/profile/actions';
import { fetchReward } from 'redux/reducers/reward/actions';
import {
  decrementQty,
  getCatalogItems,
  getThresholdStatus,
  incrementQty,
  loadStorage,
  removeProduct,
  submitOrder
} from 'redux/reducers/store/actions';
import ScrollToTopOnMount from 'shared/components/routes/ScrollToTopOnMount';
import { scrollToTheTop } from 'utils/htmlUtil';
import VerificationFlowComponent from '../id-verification/VerificationFlowComponent';

type PropsConnected = ConnectedProps<typeof connector>;

interface IStoreScreenProps extends PropsConnected, RouteComponentProps, WithTranslation, WithStyles<typeof styles> {
  theme: Theme;
}

interface IStoreScreenStore {
  loading: boolean;
  openCheckout: boolean;
  showError: boolean;
  showThresholdMessage: boolean;
  showBuyTokensModal: boolean;
  redeemQuantity: number;
  quantity: number;
  maxRedeemPoints: number;
  showRedeemTokensConfirmationModal: boolean;
  showRedeemTokensError: boolean;
  openIdVerificationModal: boolean;
}

class StoreScreen extends React.Component<IStoreScreenProps, IStoreScreenStore> {
  private readonly cartRef: React.RefObject<any>;
  constructor(props: IStoreScreenProps) {
    super(props);
    this.state = {
      loading: true,
      openCheckout: false,
      showError: false,
      showBuyTokensModal: false,
      redeemQuantity: 0,
      quantity: 0,
      maxRedeemPoints: 0,
      showRedeemTokensConfirmationModal: false,
      showRedeemTokensError: false,
      showThresholdMessage: false,
      openIdVerificationModal: false
    };
    this.cartRef = React.createRef();
  }

  public async componentDidMount() {
    document.title = 'Lucky Lounge Store';
    scrollToTheTop();
    await this.props.getProfile();
    await this.props.fetchUserTokens();
    await this.props.loadStorage();
    await this.props.getCatalogItems();
    await this.props.fetchReward(constants.REDEEM_TOKENS_REWARD);
    await this.props.fetchActivity(constants.REDEEM_TOKENS_ACTIVITY, this.initRedeemQuantity);
  }

  private initRedeemQuantity = (activity: IActivity) => {
    this.setState({ quantity: activity.amount, redeemQuantity: activity.amount });
  };

  private onAddItem = async (data: IShoppingCartItem) => {
    await this.props.incrementQty(data);
    await this.props.clearMessages();
    this.props.showMessageBar({
      message: 'store.added',
      messageParams: { linkText: 'store.jumpToCart', linkAction: this.jumpToCart }
    });
  };

  private onRemoveItem = (data: IShoppingCartItem) => {
    this.props.decrementQty(data);
  };

  private onRemoveAll = (data: IShoppingCartItem) => {
    this.props.removeProduct(data);
  };

  private jumpToCart = () => this.cartRef.current.scrollIntoView();

  private onCloseIdentityVerificationModal = async () => {
    this.setState({ openIdVerificationModal: false });
  };

  private onProfileVerified = async () => {
    this.setState({ openIdVerificationModal: false });
    await this.validateCartItemsLimit();
  };

  private onProfileVerificationFailed = async () => {
    this.setState({ openIdVerificationModal: false });
  };

  private onClickOkayThresHoldMessage = () => {
    this.setState({ showThresholdMessage: false });
  };

  private validateCartItemsLimit = async () => {
    await this.props.getThresholdStatus(this.props.store.total);
    if (this.props.store.thresholdStatus?.isLimitReached) {
      this.setState({ showThresholdMessage: true });
    } else {
      this.props.history.push('/lucky-lounge/store-billing');
    }
  };

  private onOpenCheckout = async () => {
    if (!this.props.profile.userProfile.idologyValid) {
      await this.openIdVerificationModal();
    } else {
      await this.validateCartItemsLimit();
    }
  };

  private openIdVerificationModal = async () => {
    this.setState({ openIdVerificationModal: true });
  };

  private closeThresHoldMessage = () => {
    this.setState({ showThresholdMessage: false });
  };

  private convertPointsToTokens = () => {
    const { activity } = this.props;
    this.setState({ showBuyTokensModal: true, redeemQuantity: activity.amount });
  };

  private requestConvert = async () => {
    const { activity } = this.props;

    this.props.toggleLoading({
      spinning: true
    });

    this.setState({ showBuyTokensModal: false });

    await this.props.redeemFromPoints(
      {
        quantity: this.state.redeemQuantity / (activity?.amount || 1)
      },
      this.showRedeemTokensConfirmationModal,
      this.showConvertError
    );

    setTimeout(async () => {
      this.props.toggleLoading({
        spinning: false
      });
    }, 1000);
  };

  private showRedeemTokensConfirmationModal = () => {
    this.props.fetchUserTokens();
    this.props.fetchUserPoints();
    this.setState({ showRedeemTokensConfirmationModal: true });
  };

  private showConvertError = () => {
    this.setState({ showRedeemTokensError: true });
  };
  private hideConvertError = () => {
    this.setState({ showRedeemTokensError: false });
  };

  private onModalClose = () => {
    this.setState({ showBuyTokensModal: false });
  };

  private onRedeemTokensConfirmationModalClose = () => {
    this.setState({ showRedeemTokensConfirmationModal: false });
  };

  private onRedeemQuantityChange = (quantity: number) => {
    this.setState({ redeemQuantity: quantity });
  };

  private getConvertToTokensModal = (): React.ReactNode => {
    const { activity, selectedReward } = this.props;
    const convertionRate = activity.amount / (selectedReward?.amount || 1);
    const availableTokens = Math.floor(this.props.userBalance.balance * convertionRate);
    const maxTokensToRedeem = Math.floor(availableTokens / activity.amount) * activity.amount;
    return this.state.showBuyTokensModal && this.state.redeemQuantity > 0 ? (
      <ConvertToTokensModal
        maxRedeemAvailable={maxTokensToRedeem}
        onRedeem={this.requestConvert}
        onQuantityChange={this.onRedeemQuantityChange}
        redeemablePoints={this.props.userBalance.balance}
        open={true}
        quantity={this.state.redeemQuantity}
        onClose={this.onModalClose}
        step={activity.amount}
        defaultQuantity={activity.amount}
        minQuantity={activity.amount}
        cost={selectedReward?.amount}
        hideRedeemableQuantity={true}
        disableSlider={false}
      />
    ) : null;
  };

  private confirmRedeemTokensConfirmationModal = (): React.ReactNode => {
    const { classes, t } = this.props;
    return (
      <RedeemConfirmationModal
        isVisible={this.state.showRedeemTokensConfirmationModal}
        onClose={this.onRedeemTokensConfirmationModalClose}
        maxWidth="sm"
        title={t('store.redeem.confirmation.title')}
        buttonStyle={classes.popupButtonStyle}
        textStyle={classes.textStyle}
        text={t('store.redeem.confirmation.message')}
        image={TokenPrimary}
        email={undefined}
      />
    );
  };
  private failedRedeemTokensModal = (): React.ReactNode => {
    const { activity } = this.props;
    return <RedeemFailedModal open={this.state.showRedeemTokensError} onClose={this.hideConvertError} step={activity.amount} />;
  };

  private navigateToHistory = () => {
    this.props.history.push('/lucky-lounge/history');
  };

  private stopPropagation = event => event.stopPropagation();

  public componentWillUnmount(): void {
    this.props.resetSelectedReward();
  }

  public render() {
    const { classes, t, store, history, userTokens, selectedReward, activity, userBalance } = this.props;
    const onNavigate = () => {
      history.push('/lucky-lounge');
    };
    const conversionDisabled = !activity || !selectedReward?.amount || this.state.quantity === 0;
    const paymentEnabled = this.props.authorizedFlags && this.props.authorizedFlags.includes('LL_PAYMENT_ENABLED');
    return (
      <div className={classes.container}>
        {this.getConvertToTokensModal()}
        {this.confirmRedeemTokensConfirmationModal()}
        {this.failedRedeemTokensModal()}
        <Grid container={true} spacing={4}>
          <ScrollToTopOnMount />
          <Grid item={true} xs={12}>
            <div className={classNames(classes.container, classes.header)} onClick={this.stopPropagation}>
              <div className={classes.detailHeader}>
                <Tooltip title={`Lucky Lounge`}>
                  <Link onClick={onNavigate} component={'a'} aria-label={`Go back to Games list`} className={classes.backButton}>
                    <Typography variant="body2" color="textPrimary" className={classes.centered}>
                      {'Lucky Lounge'} <Icon iconType={'material'} iconName={'chevron_right'} />
                    </Typography>
                  </Link>
                </Tooltip>
                <Hidden mdUp={true}>
                  <Typography variant="h6" component={'h1'} color="textPrimary" className={classes.center}>
                    {t('store.title')}
                    <ArcadeCurrency color={'secondary'} size={23} />
                  </Typography>
                </Hidden>
                <Hidden smDown={true}>
                  <Typography variant="h5" component={'h1'} color="textPrimary" className={classes.center}>
                    {t('store.title')}
                    <ArcadeCurrency color={'secondary'} size={31} />
                  </Typography>
                </Hidden>
              </div>
              <div className={classes.tokensContainer}>
                <div className={classes.tokens}>
                  <Typography
                    variant="h5"
                    color={'textPrimary'}
                    style={{ display: 'flex', alignItems: 'center', marginLeft: '22px' }}
                    component={'span'}
                  >
                    {userTokens.balance.toPointsFormat()}
                    <ArcadeCurrency color={'secondary'} size={22} />
                  </Typography>
                  <Typography variant="overline" color="textPrimary" className={classes.tokensTxt}>
                    {t('store.token_plural')}
                  </Typography>
                </div>
                <Button variant={'text'} size={'large'} color={'secondary'} onClick={this.navigateToHistory} className={classes.historyBtn}>
                  <Typography variant="overline" color="secondary" style={{ display: 'flex', alignItems: 'center' }}>
                    <Icon iconType={'material'} iconName={'history'} className={classes.historyIcon} />
                    {t('store.history')}
                  </Typography>
                </Button>
              </div>
            </div>
          </Grid>
          {this.state.showThresholdMessage ? (
            <ThresHoldMessageModal
              open={this.state.showThresholdMessage}
              onClose={this.closeThresHoldMessage}
              onClickOk={this.onClickOkayThresHoldMessage}
              totalAmount={store.thresholdStatus?.totalAmount}
              limit={store.thresholdStatus?.limit}
            />
          ) : null}
          {store.loading ? <AppSpinner label={'Loading'} /> : null}
          {this.state.openIdVerificationModal && (
            <VerificationFlowComponent
              onFlowClosed={this.onCloseIdentityVerificationModal}
              onIdentityVerified={this.onProfileVerified}
              onIdentityFailed={this.onProfileVerificationFailed}
            />
          )}
          <Grid item={true} xs={12}>
            <ConversionContainer
              loading={store.loading}
              disabled={conversionDisabled}
              balance={userBalance.balance || 0}
              tokens={this.state.quantity}
              points={selectedReward?.amount || 0}
              onClick={this.convertPointsToTokens}
            />
          </Grid>
          {store.catalog && store.catalog.length > 0 ? (
            <Grid
              container={true}
              spacing={4}
              className={classNames({ [classes.purchaseComingSoonContainer]: !paymentEnabled, [classes.bundles]: true })}
            >
              {!paymentEnabled && (
                <div className={classes.purchaseComingSoon}>
                  <img alt={'Coming Soon purchase token bundles'} src={constants.PURCHASE_TOKEN_BUNDLE_COMING_SOON} />
                </div>
              )}
              <Grid item={true} xs={12}>
                <Typography variant={'h6'} color={'secondary'} align={'center'} component={'h2'} gutterBottom={true}>
                  {'OR'}
                </Typography>
              </Grid>
              <Grid item={true} xs={12}>
                <Grid container={true} spacing={2}>
                  {store.catalog &&
                    store.catalog.map((item: IShoppingCartItem) => (
                      <Grid key={item.key} item={true} xs={12} md={6} lg={3} className={classes.catalogItem}>
                        <StoreCard
                          onClick={this.onAddItem.bind(this, item)}
                          title={item.title}
                          amount={item.amount}
                          image={item.image}
                          price={item.price}
                        />
                      </Grid>
                    ))}
                </Grid>
              </Grid>
              <Grid ref={this.cartRef} item={true} xs={12}>
                <ShoppingCart
                  items={store.selectedItems}
                  total={store.total}
                  onAddItem={this.onAddItem}
                  onRemoveItem={this.onRemoveItem}
                  onRemoveAll={this.onRemoveAll}
                  proceedCheckout={this.onOpenCheckout}
                />
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = ({
  authentication: { accessToken, account },
  store,
  address: { countries },
  ledger: { userBalance, userTokens, transactionResponse },
  reward: { selectedReward },
  activityState: { activities },
  profile,
  feature: { authorizedFlags }
}: IRootState) => ({
  isLoggedIn: !!accessToken && accessToken.length > 0 && !!account && !!account.email,
  account,
  userTokens,
  store,
  countries,
  userBalance,
  activity: activities && activities[constants.REDEEM_TOKENS_ACTIVITY] ? activities[constants.REDEEM_TOKENS_ACTIVITY] : ({} as IActivity),
  selectedReward,
  transactionResponse,
  profile,
  authorizedFlags
});

const mapDispatchToProps = {
  toggleLoading,
  incrementQty,
  decrementQty,
  removeProduct,
  loadStorage,
  getCatalogItems,
  showMessageBar,
  clearMessages,
  submitOrder,
  fetchUserTokens,
  redeemFromPoints,
  fetchReward,
  fetchUserPoints,
  fetchActivity,
  getThresholdStatus,
  getProfile,
  resetSelectedReward
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default withRouter(connector(withStyles(styles)(withTheme(withTranslation()(StoreScreen)))));
