import { Button, Grid, Theme, Typography, withStyles, WithStyles, withTheme } from '@material-ui/core';
import React, { useState } from 'react';

import TextFieldValidator, { TextFieldValidatorProps } from '@pbl/pbl-react-web-components/lib/field/TextFieldValidator';
import styles from 'assets/jss/modules/settings/account/AccountScreenStyle';

interface IUpdateEmailFormProps extends WithStyles<typeof styles> {
  theme: Theme;
  oldEmail: string;
  onSubmit: (newEmail: string) => (event) => void;
  validationSchema: any;
}

const UpdateEmailForm: React.FunctionComponent<IUpdateEmailFormProps> = ({ classes, theme, oldEmail, validationSchema, onSubmit }) => {
  const [valid, setValid] = useState(false);
  const [email, setEmail] = useState('');

  const handleEmailChange = event => {
    setEmail(event.target.value);
  };

  const handleBlur = () => {
    const isValid = validationSchema.isValidSync(email) && oldEmail !== email;
    setValid(isValid);
  };

  return (
    <Grid container={true} spacing={2} direction="column">
      <Grid item={true}>
        <Typography variant="body2" style={{ marginRight: theme.spacing(3), marginBottom: theme.spacing(2) }}>
          Your email address is used to Sign-in to your account. Once email is changed, use your new email to sign in to your account and to
          receive email notifications and updates from us.
        </Typography>
      </Grid>
      <Grid item={true}>
        <Typography variant="body2">Current Email</Typography>
        <Typography variant="subtitle1">{oldEmail}</Typography>
      </Grid>
      <Grid item={true}>
        <TextFieldValidator
          {...({
            variant: 'filled',
            id: 'newEmail',
            label: 'New Email',
            'aria-label': 'New Email',
            className: classes.field,
            value: email,
            onChange: handleEmailChange,
            onBlur: handleBlur,
            validationSchema
          } as unknown as TextFieldValidatorProps)}
        />
      </Grid>

      <Grid item={true} style={{ display: 'flex', justifyContent: 'center' }}>
        <Button size="large" variant="contained" color="primary" aria-label="Submit" onClick={onSubmit(email)} disabled={!valid}>
          Submit
        </Button>
      </Grid>
    </Grid>
  );
};

export default withTheme(withStyles(styles)(UpdateEmailForm));
