import { RESET_PASSWORD_FINISH, RESET_PASSWORD_STATE } from '@pbl/pbl-react-core/lib/models/password-reset';
import { AccountService } from '@pbl/pbl-react-core/lib/services';
import { SUCCESS } from 'redux/action-type.util';
import { showMessageBar, toggleLoading } from 'redux/reducers/app/actions';

export const passwordResetFinish = (key, newPassword) => async (dispatch: any) => {
  try {
    if (!key) {
      showMessageBar({
        message: 'password.reset',
        type: 'error',
        messageTimeout: 6000
      });
      return;
    }

    dispatch(toggleLoading({ spinning: true }));

    await AccountService.passwordReset(key, newPassword);

    dispatch({ type: SUCCESS(RESET_PASSWORD_FINISH) });
  } catch (e: any) {
    if (e.payload && e.payload.errorKey) {
      const message = e.payload.errorKey;
      switch (message) {
        case 'error.usernotfound':
          dispatch(showErrorMessage('Password reset key is invalid.'));
          break;
        case 'error.expiredKey':
          dispatch(showErrorMessage('Password reset key has expired.'));
          break;
        case 'error.uaaUser.resendActivationLockout':
          dispatch(showErrorMessage('account.locked'));
          break;
        case 'error.uaaUser.resendActivationSuspended':
          dispatch(showErrorMessage('account.suspended'));
          break;
        case 'error.uaaUser.resetPasswordLockout':
          dispatch(showErrorMessage('account.locked'));
          break;
        case 'error.uaaUser.resetPasswordSuspended':
          dispatch(showErrorMessage('account.suspended'));
          break;
        case 'error.email.notfound':
          dispatch(showErrorMessage('account.emailNotFound'));
          break;
        default:
          dispatch(showErrorMessage(e.payload.title ? e.payload.title : 'error.general'));
          break;
      }
    }
  } finally {
    dispatch(toggleLoading({ spinning: false }));
  }
};

const showErrorMessage = (message: string) =>
  showMessageBar({
    message,
    type: 'error'
  });

export const reset = () => ({
  type: RESET_PASSWORD_STATE
});
