import { Button, Hidden } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import { Theme, withStyles, WithStyles, withTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { IFeedbackRequestBody } from '@pbl/pbl-react-core/lib/models/feedback';
import AppSpinner from '@pbl/pbl-react-web-components/lib/app-spinner/AppSpinner';
import InformationDialog from '@pbl/pbl-react-web-components/lib/dialog/InformationDialog';
import TextFieldValidator, { TextFieldValidatorProps } from '@pbl/pbl-react-web-components/lib/field/TextFieldValidator';
import styles from 'assets/jss/modules/feedback/FeedbackScreenStyle';
import classNames from 'classnames';
import constants from 'config/constants';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { showMessageBar } from 'redux/reducers/app/actions';
import { sendFeedback } from 'redux/reducers/feedback/actions';
import ReCaptcha from 'shared/components/input/ReCaptcha';
import { scrollToTheTop } from 'utils/htmlUtil';
import * as yup from 'yup';
import { feedbackSchema } from './validations';

export interface IFeedbackScreenProps extends WithTranslation, StateProps, DispatchProps, WithStyles<typeof styles>, RouteComponentProps {
  theme: Theme;
}

interface IFeedbackScreenState {
  firstName: string;
  lastName: string;
  email: string;
  msgSubject: string;
  msgBody: string;
  recaptcha: string;
  formIsValid: boolean;
  loading: boolean;
  showCaptcha: boolean;
  showPopup: boolean;

  [attr: string]: string | boolean;
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

const mapStateToProps = ({ authentication }) => ({
  authentication
});

const mapDispatchToProps = { sendFeedback, showMessageBar };

class FeedbackScreen extends React.Component<IFeedbackScreenProps, IFeedbackScreenState> {
  private inputHandlers = [];

  constructor(props: IFeedbackScreenProps) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      msgSubject: '',
      msgBody: '',
      recaptcha: '',
      formIsValid: false,
      loading: false,
      showCaptcha: false,
      showPopup: false
    };
    this.resetForm(false);
  }

  public async componentDidMount() {
    this.resetForm(true);
    document.title = 'Send Us Feedback';
    scrollToTheTop();
  }

  public componentWillUnmount() {
    this.resetForm(true);
  }

  private onFieldValueChange = (attr: string) => {
    if (!this.inputHandlers[attr]) {
      this.inputHandlers[attr] = async event => {
        this.setState({ [attr]: event.target.value });
        if (attr === 'msgBody') {
          this.onBlur();
        }
      };
    }

    return this.inputHandlers[attr];
  };

  private onBlur = () => {
    const { firstName, lastName, email, msgSubject, msgBody } = this.state;
    const formIsValid = feedbackSchema.isValidSync({ firstName, lastName, email, msgSubject, msgBody });
    this.setState({ formIsValid });
  };

  public onSubmit = async () => {
    this.setState({ loading: true });
    if (this.state.formIsValid && !this.state.showCaptcha) {
      await this.setState({ showCaptcha: true });
    } else if (this.state.formIsValid) {
      await this.sendFeedback();
    } else {
      this.setState({ loading: false });
    }
  };

  public onOpenContactInformation = () => {
    this.setState({ showPopup: true });
  };

  public onCloseContactInformation = () => {
    this.setState({ showPopup: false });
  };

  public sendFeedback = async () => {
    if (this.state.formIsValid) {
      const data: IFeedbackRequestBody = {
        email: this.state.email,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        subject: this.state.msgSubject,
        body: this.state.msgBody
      };

      await this.props.sendFeedback(data, this.state.recaptcha);
      await this.setState({ showCaptcha: false });
      await setTimeout(async () => {
        await this.setState({ loading: false });
        this.props.history.push('/');
      }, 500);
    }
  };

  public onKeyUp = async event => {
    if (event.key === 'Enter') {
      await this.onSubmit();
    }
  };

  public render() {
    const { classes, theme, authentication, t } = this.props;
    const { firstName, lastName, email, msgSubject, msgBody, showCaptcha, loading, showPopup } = this.state;

    return (
      <div className={classes.root}>
        <InformationDialog open={showPopup} onClose={this.onCloseContactInformation} maxWidth={'xs'} hideButton={true} hideXButton={true}>
          <Typography variant="h6" component="h1" color={'textPrimary'} gutterBottom={true} align={'center'} className={classes.spacing}>
            {'Customer Support'}
          </Typography>
          <Typography variant="body1" component="h1" color={'textPrimary'} gutterBottom={true} align={'center'} className={classes.spacing}>
            {'For further assistance, please phone Customer Support at 1-844-558-0417'}
          </Typography>
          <Button
            size="large"
            color={'primary'}
            aria-label="Close contact information"
            onClick={this.onCloseContactInformation}
            variant={'contained'}
            className={classes.closeBtn}
          >
            {'OK'}
          </Button>
        </InformationDialog>
        <Grid container={true} className={classNames(classes.feedbackContainer, classes.center)}>
          {loading && <AppSpinner label={'Loading...'} />}
          <Grid item={true} xs={12} sm={12} md={10} lg={8} xl={7}>
            <Hidden xsDown={true}>
              <Typography variant="h4" component="h1" gutterBottom={true} color={'textPrimary'} className={classes.feedbackTitle}>
                {t('home.sendUsFeedback')}
              </Typography>
            </Hidden>
            <Hidden smUp={true}>
              <Typography variant="h6" component="h1" gutterBottom={true} color={'textPrimary'}>
                {t('home.sendUsFeedback')}
              </Typography>
            </Hidden>

            <Card className={classes.feedbackCard}>
              <Typography variant="h6" component="h2" gutterBottom={true} color={'textPrimary'} className={classes.spacing}>
                Tell Us Who You Are
              </Typography>
              <TextFieldValidator
                {...({
                  required: true,
                  inputProps: {
                    'aria-required': true
                  },
                  id: 'firstName',
                  label: 'First name',
                  value: firstName,
                  onChange: this.onFieldValueChange('firstName'),
                  className: classes.spacing,
                  variant: 'filled',
                  validationSchema: yup.string().required(),
                  type: 'text',
                  onKeyUp: this.onKeyUp,
                  onBlur: this.onBlur,
                  disabled: !!authentication.accessToken && !!authentication.account?.firstName,
                  autoFocus: !!authentication.accessToken && !!authentication.account?.firstName
                } as unknown as TextFieldValidatorProps)}
              />

              <TextFieldValidator
                {...({
                  required: true,
                  inputProps: {
                    'aria-required': true
                  },
                  id: 'lastName',
                  label: 'Last name',
                  value: lastName,
                  onChange: this.onFieldValueChange('lastName'),
                  className: classes.spacing,
                  variant: 'filled',
                  validationSchema: yup.string().required(),
                  type: 'text',
                  onKeyUp: this.onKeyUp,
                  onBlur: this.onBlur,
                  disabled: !!authentication.accessToken && !!authentication.account?.lastName
                } as unknown as TextFieldValidatorProps)}
              />

              <TextFieldValidator
                {...({
                  required: true,
                  inputProps: {
                    'aria-required': true
                  },
                  id: 'email',
                  label: 'Email',
                  value: email,
                  onChange: this.onFieldValueChange('email'),
                  className: classes.spacing,
                  variant: 'filled',
                  validationSchema: yup.string().required().email(),
                  type: 'text',
                  onKeyUp: this.onKeyUp,
                  onBlur: this.onBlur,
                  disabled: !!authentication.accessToken && !!authentication.account?.email
                } as unknown as TextFieldValidatorProps)}
              />

              <Typography
                variant="h6"
                component="h2"
                gutterBottom={true}
                color={'textPrimary'}
                className={classes.spacing}
                style={{ marginTop: theme.spacing(3) }}
              >
                Provide Feedback
              </Typography>
              <TextFieldValidator
                {...({
                  required: true,
                  id: 'msgSubject',
                  label: 'Subject',
                  value: msgSubject,
                  onChange: this.onFieldValueChange('msgSubject'),
                  className: classes.spacing,
                  variant: 'filled',
                  validationSchema: yup.string().required(),
                  type: 'text',
                  onKeyUp: this.onKeyUp,
                  onBlur: this.onBlur,
                  inputProps: {
                    'aria-required': true,
                    maxLength: 100
                  }
                } as unknown as TextFieldValidatorProps)}
              />

              <TextFieldValidator
                {...({
                  required: true,
                  id: 'msgBody',
                  label: 'Message',
                  value: msgBody,
                  onChange: this.onFieldValueChange('msgBody'),
                  className: classes.spacing,
                  variant: 'filled',
                  multiline: true,
                  rows: 10,
                  rowsMax: 12,
                  validationSchema: yup.string().required(),
                  type: 'text',
                  onBlur: this.onBlur,
                  inputProps: {
                    'aria-required': true,
                    maxLength: 1000
                  }
                } as unknown as TextFieldValidatorProps)}
              />

              <Button
                size="large"
                variant="contained"
                color="primary"
                className={classes.button}
                disabled={!this.state.formIsValid || this.state.loading}
                onClick={this.onSubmit}
                aria-label="SUBMIT feedback"
              >
                SUBMIT
              </Button>
              {constants.SHOW_CONTACT_INFO && (
                <div className={classes.moreContactInfo}>
                  <Typography variant="body1" color={'textPrimary'} component={'span'}>
                    Please submit your feedback. For immediate assistance click
                  </Typography>
                  <Typography
                    component={'a'}
                    color={'textPrimary'}
                    className={classes.link}
                    onClick={this.onOpenContactInformation}
                    aria-label="display contact information, button"
                  >
                    here.
                  </Typography>
                </div>
              )}
              {showCaptcha ? <ReCaptcha action="CONTACT_US" onChange={this.onRecaptchaChange} /> : null}
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }

  private resetForm(forceReload: boolean) {
    const { authentication } = this.props;
    const account = authentication.account || {};

    const initialState = {
      firstName: account.firstName ? account.firstName : '',
      lastName: account.lastName ? account.lastName : '',
      email: account.email ? account.email : '',
      msgSubject: '',
      msgBody: '',
      recaptcha: '',
      formIsValid: false,
      loading: false,
      showCaptcha: false,
      showPopup: false
    };

    if (forceReload) {
      this.setState({ ...initialState });
    } else {
      this.setState({ ...initialState });
    }
  }

  private onRecaptchaChange = async (value: any) => {
    await this.setState({ recaptcha: value });
    await this.sendFeedback();
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-ignore
)(withTheme(withStyles(styles)(withTranslation()(FeedbackScreen))));
