import { Button, Grid, withStyles, WithStyles, withTheme, WithTheme } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import styles from 'assets/jss/modules/home/HomeScreenStyle';
import classNames from 'classnames';
import FeaturedSlide from 'modules/home/components/FeaturedSlide';
import React, { ReactNode } from 'react';
import Carousel, { Settings } from 'react-slick';

export interface IAdvertisementContent {
  id: number;
  title: string;
  image: string;
  btnText: string;
  url: string;
  target: string;
  backgroundImage?: string;
  textColor?: string;
}

interface IProps extends WithStyles<typeof styles>, WithTheme {
  content: IAdvertisementContent[];
}

const FeaturedSlider: React.FunctionComponent<IProps> = ({ classes, content, theme }) => {
  const PrevArrow = (props: any) => {
    const { onClick } = props;
    return (
      <Button className={classes.slickButton} onClick={onClick} aria-label="Previous Slider">
        <ArrowBackIosIcon fontSize="large" style={{ color: theme.palette.common.white }} />
      </Button>
    );
  };

  const NextArrow = (props: any) => {
    const { onClick } = props;
    return (
      <Button className={classNames(classes.slickButton, classes.slickButtonNext)} onClick={onClick} aria-label="Next Slider">
        <ArrowForwardIosIcon style={{ color: theme.palette.common.white }} fontSize="large" />
      </Button>
    );
  };

  const settings: Settings = {
    accessibility: true,
    dots: true,
    infinite: true,
    speed: 500,
    autoplaySpeed: 8000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnDotsHover: true,
    swipeToSlide: true,
    pauseOnFocus: true,
    fade: true,
    lazyLoad: 'ondemand',
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    appendDots: (dots: ReactNode) => <ul>{dots}</ul>,
    customPaging: (index: number) => (
      <Button className={classes.slickDot} aria-label={'Slider number ' + index + 1} disableRipple={true}>
        {'Slider number ' + index + 1}
      </Button>
    )
  };
  const interleaveCards = (): React.ReactNode[] => {
    const interleavedCards: React.ReactNode[] = [];
    content.forEach(item => {
      interleavedCards.push(<FeaturedSlide key={`advertisement-${item.id}`} item={item} />);
    });
    return interleavedCards;
  };

  return (
    <div className={classes.carouselSection} id="carousel">
      <div className={classes.carouselContainer}>
        <Grid container={true}>
          <Grid item={true} xs={12} className={classes.marginAuto}>
            <Carousel {...settings}>{interleaveCards()}</Carousel>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default withStyles(styles)(withTheme(FeaturedSlider));
