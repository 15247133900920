import { Theme, WithStyles, withStyles, withTheme } from '@material-ui/core/styles';
import { IFilters } from '@pbl/pbl-react-core/lib/models/filters';
import { HistoryActivity, HistoryDraw, HistoryReward, TicketEntryHistory } from '@pbl/pbl-react-core/lib/models/history';
import { DateFilterIndex } from '@pbl/pbl-react-core/lib/models/history/types';
import {
  formatLongMonthDayCommaYear,
  now,
  utcDateTimeFor,
  zonedDateTimeFor,
  zonedDateTimeForTimestamp
} from '@pbl/pbl-react-core/lib/utils/TimeUtil';
import { Hideable } from '@pbl/pbl-react-web-components/lib/hideable';
import RewardGroupDialog from '@pbl/pbl-react-web-components/lib/history/RewardGroupDialog';
import History from '@pbl/pbl-react-web-components/lib/history/v2/history-screen';
import { FilterSection, PrizesDialog, SelectedFiltersSection, WinnersDialog } from '@pbl/pbl-react-web-components/lib/package';
import RewardCouponModal from '@pbl/pbl-react-web-components/lib/reward-detail/v2/RewardCouponModalV2';
import styles from 'assets/jss/modules/history/HistoryScreenStyle';
import constants, { RemoteConstants } from 'config/constants';
import { usePointsBalanceData } from 'hooks/providers/usePointsBalanceData';
import _ from 'lodash';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IRootState } from 'redux/reducers';
import { fetchDrawPrizesWithContent } from 'redux/reducers/draws/actions';
import {
  fetchDrawsByIds,
  fetchSecondChanceTicketHistory,
  fetchUserActivitiesHistory,
  fetchUserDrawsHistory,
  fetchUserRewardsHistoryV2,
  fetchUserTicketEntriesHistory,
  getPendingPointsForTicketEntries,
  replaceFilers,
  resetState,
  setSelectedHistoryItem,
  toggleFilter
} from 'redux/reducers/history/actions';
import { DEFAULT_ACTIVITIES_PER_PAGE, DEFAULT_PAGE, DEFAULT_REWARDS_PER_PAGE } from 'redux/reducers/history/pagination/actions';
import { endGame, fetchPlayableDigitalRevealGames, startGame } from 'redux/reducers/interactive-games/actions';
import { getBarcodeData } from 'redux/reducers/loyalty/actions';
import ScrollToTopOnMount from 'shared/components/routes/ScrollToTopOnMount';

export interface IHistoryScreenProps extends WithStyles<typeof styles> {
  theme: Theme;
}

const perPageOptions: number[] = [10, 25, 50, 75, 100];

const HistoryScreen: React.FC<IHistoryScreenProps> = ({ classes }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loyalty = useSelector((state: IRootState) => state.loyalty);
  const { authorizedFlags } = useSelector((state: IRootState) => state.feature);
  const { barcodeData } = loyalty;
  const historyData = useSelector((state: IRootState) => state.history);
  const historyPagination = useSelector((state: IRootState) => state.historyPagination);
  const drawDetail = useSelector((state: IRootState) => state.drawDetail);
  const userData = useSelector((state: IRootState) => state.authentication.userData);
  const { digitalRevealGames, digitalRevealGamesPaging } = useSelector((state: IRootState) => state.interactiveGames);
  const { userPoints } = usePointsBalanceData();
  const [loading, setLoading] = React.useState(false);
  const [hidePagination, setHidePagination] = React.useState(false);
  const [showWinnerDialog, setShowWinnerDialog] = React.useState(false);
  const [showPrizesDialog, setShowPrizesDialog] = React.useState(false);
  const [draw, setDraw] = React.useState({} as HistoryDraw);
  const [rewardsGroup, setRewardsGroup] = React.useState([] as HistoryReward[]);
  const [isOpenRewardsGroupDialog, setIsOpenRewardsGroupDialog] = React.useState(false);
  const [isOpenRedeemCouponDialog, setIsOpenRedeemCouponDialog] = React.useState(false);
  const [selectedRewardData, setSelectedRewardData] = React.useState({} as HistoryReward);
  const usePromotionService = RemoteConstants.getBoolean('USE_PROMOTION_SERVICE_FOR_DRAW_HISTORY');

  React.useEffect(() => {
    document.title = 'Players Club History';

    return () => {
      dispatch(resetState());
    };
  }, [dispatch]);

  const getActivitiesData = React.useCallback(
    async (pageNumber, size, reset = false) => {
      const filters = getFilterDateValue(historyData.filters);

      const newPage = reset ? DEFAULT_PAGE : pageNumber;
      const newActivitiesSize = reset ? DEFAULT_ACTIVITIES_PER_PAGE : size || historyPagination.activities.pageSize;

      if (!filters.startDate || !filters.endDate) {
        dispatch(fetchUserActivitiesHistory(newPage, newActivitiesSize, '', '', reset));

        return;
      }

      dispatch(
        fetchUserActivitiesHistory(
          newPage,
          newActivitiesSize,
          filters.startDate.format('YYYY-MM-DD'),
          filters.endDate.format('YYYY-MM-DD'),
          reset
        )
      );
    },
    [dispatch, historyData.filters, historyPagination.activities.pageSize]
  );

  const getRewardsData = React.useCallback(
    async (pageNumber: number, size: number) => {
      const filters = getFilterDateValue(historyData.filters);
      const newPage = pageNumber ? pageNumber : DEFAULT_PAGE;
      const pageSize = size ?? DEFAULT_REWARDS_PER_PAGE;

      if (filters.startDate && filters.endDate) {
        dispatch(
          fetchUserRewardsHistoryV2(newPage, pageSize, filters.startDate.format('YYYY-MM-DD'), filters.endDate.format('YYYY-MM-DD'))
        );
        return;
      }

      dispatch(fetchUserRewardsHistoryV2(newPage, pageSize));
    },
    [dispatch, historyData.filters]
  );

  const getTicketsData = React.useCallback(
    async (pageNumber, reset = false) => {
      const filters = getFilterDateValue(historyData.filters);
      let dateStart;
      let dateEnd;

      if (filters.startDate !== null && filters.endDate !== null) {
        dateStart = filters.startDate.clone();
        dateEnd = filters.endDate.clone();
      }

      const newPage = reset ? DEFAULT_PAGE : pageNumber;

      dispatch(
        fetchUserTicketEntriesHistory(
          {
            page: newPage,
            dateStart: dateStart
              ? dateStart
                  // @ts-ignore
                  .utc()
                  .startOf('day')
                  .toISOString()
              : undefined,
            dateEnd: dateEnd
              ? dateEnd
                  // @ts-ignore
                  .utc()
                  .endOf('day')
                  .toISOString()
              : undefined
          },
          reset
        )
      );
    },
    [dispatch, historyData.filters]
  );

  React.useEffect(() => {
    const getData = () => {
      setLoading(true);
      getActivitiesData(DEFAULT_PAGE, historyPagination.activities.pageSize);
      getRewardsData(DEFAULT_PAGE, historyPagination.rewards.pageSize);
      getTicketsData(DEFAULT_PAGE);
      dispatch(getPendingPointsForTicketEntries());
      dispatch(fetchUserDrawsHistory(DEFAULT_PAGE, usePromotionService));
      dispatch(fetchPlayableDigitalRevealGames(DEFAULT_PAGE));
      setLoading(false);
    };
    getData();
  }, [
    historyData.filters,
    getActivitiesData,
    getRewardsData,
    getTicketsData,
    dispatch,
    usePromotionService,
    historyPagination.activities.pageSize,
    historyPagination.rewards.pageSize
  ]);

  const getDrawsData = async pageNumber => {
    dispatch(fetchUserDrawsHistory(pageNumber, usePromotionService));
  };

  const getDigitalRevealGames = async pageNumber => {
    dispatch(fetchPlayableDigitalRevealGames(pageNumber));
  };

  const hideWinnersDialog = (): void => {
    setShowWinnerDialog(false);
  };

  const openWinnersPopup = async selectedDraw => {
    dispatch(fetchDrawPrizesWithContent(selectedDraw.id));
    dispatch(fetchSecondChanceTicketHistory(selectedDraw.id, usePromotionService));
    setDraw(selectedDraw);
    setShowWinnerDialog(true);
  };

  const closePrizesDialog = (): void => {
    setShowPrizesDialog(false);
  };

  const openPrizesDialog = (): void => {
    setShowPrizesDialog(true);
  };

  const onViewPrizeDetailsAndImagesClicked = (): void => {
    hideWinnersDialog();
    openPrizesDialog();
  };

  const setSelectedTicketEntry = async (ticket: TicketEntryHistory) => {
    dispatch(setSelectedHistoryItem(ticket));
    dispatch(fetchDrawsByIds(ticket));
    return historyData.selectedHistoryItem;
  };

  const openRewardsGroupDialog = (rewards: HistoryReward[]): void => {
    setRewardsGroup(rewards);
    setIsOpenRewardsGroupDialog(true);
  };

  const getExtraData = (extra_data: any, key: string): string => {
    if (!extra_data) return '';

    return extra_data.find((x: Record<any, string>) => x.key === key)?.value ?? '';
  };

  const openRedeemCouponModal = async (reward: HistoryReward): Promise<void> => {
    if (!reward) return;

    const isCouponType = getExtraData(reward.extra_data, 'type').toLowerCase() === 'coupon';
    if (!isCouponType) return;

    const key = getExtraData(reward.extra_data, 'key');
    const humanReadable = getExtraData(reward.extra_data, 'humanReadable');
    const code = getExtraData(reward.extra_data, 'code');

    if (key) {
      dispatch(getBarcodeData(key, humanReadable, code));
      setSelectedRewardData(reward);
      setIsOpenRedeemCouponDialog(true);
    }
  };

  const hideRedeemCouponCodeModal = () => {
    setIsOpenRedeemCouponDialog(false);
  };

  const hideRewardsGroupDialog = () => {
    setIsOpenRewardsGroupDialog(false);
  };

  const onActivityRowClick = (activity: HistoryActivity) => {
    const { base_extra_data, date_awarded } = activity;

    const collectThemAllSuperBonusKey = base_extra_data?.super_bonus_key;
    const activityRewardedDate = !!date_awarded ? zonedDateTimeForTimestamp(date_awarded * 1000) : null;
    const superBonusStartDate = zonedDateTimeFor(utcDateTimeFor(base_extra_data?.superbonus_start_date).format());
    const superBonusEndDate = zonedDateTimeFor(utcDateTimeFor(base_extra_data?.superbonus_end_date).format());
    const currentDate = now();

    const isCollectThemAllEnded = superBonusEndDate && superBonusEndDate < currentDate;
    const isCollectThemAllStarted = superBonusStartDate && superBonusStartDate < currentDate;
    // Collect them all activity record
    if (!!collectThemAllSuperBonusKey && !isCollectThemAllEnded && base_extra_data?.is_collect_them_all) {
      history.push('/collect-them-all/' + collectThemAllSuperBonusKey);
    }

    // Collect them all related record
    const isRewardedBetweenCollectThemAll =
      activityRewardedDate &&
      superBonusStartDate &&
      superBonusEndDate &&
      activityRewardedDate >= superBonusStartDate &&
      activityRewardedDate <= superBonusEndDate;

    if (!!collectThemAllSuperBonusKey && isRewardedBetweenCollectThemAll && !isCollectThemAllEnded && isCollectThemAllStarted) {
      history.push('/collect-them-all/' + collectThemAllSuperBonusKey);
    }
  };

  const startDigitalRevealGame = (token?: string) => {
    startGame(token || '');
  };

  const endDigitalRevealGame = async (token?: string) => {
    dispatch(endGame(token || ''));

    setTimeout(() => {
      const refresh = async () => {
        dispatch(fetchPlayableDigitalRevealGames(DEFAULT_PAGE));
        dispatch(fetchUserDrawsHistory(DEFAULT_PAGE, usePromotionService));
        dispatch(fetchUserActivitiesHistory(DEFAULT_PAGE, historyPagination.activities.pageSize));
      };
      refresh();
    }, 1000);
  };

  const renderFilterSection = (): React.ReactNode => {
    const filters = historyData.filters;

    if (filters === null) {
      return null;
    }

    return (
      <FilterSection
        entityName="history"
        title="Filter History"
        filterTitle="Filter History"
        filters={filters}
        onOk={onFilterDone}
        onRemoveSelected={onRemoveSelectedFilter}
        onlyFilter={true}
        showClose={undefined}
        darkMode={undefined}
        tabHeader={undefined}
      />
    );
  };

  const renderFilterSelectedSection = (): React.ReactNode => {
    const filters = historyData.filters;
    if (filters === null) {
      return null;
    }

    return <SelectedFiltersSection filters={filters} entityName="history" onRemoveSelected={onRemoveSelectedFilter} darkMode={undefined} />;
  };

  const getFilterDateValue = filters => {
    if (filters !== null && filters[DateFilterIndex].filters.filter(x => x.isSelected).length > 0) {
      const filterSelected = filters[DateFilterIndex].filters.filter(x => x.isSelected)[0];
      if (!!filterSelected.customDateRangeStart && !!filterSelected.customDateRangeEnd) {
        return {
          startDate: filterSelected.customDateRangeStart,
          endDate: filterSelected.customDateRangeEnd
        };
      }
    }
    return {
      startDate: null,
      endDate: null
    };
  };

  const onFilterDone = async (filters: IFilters) => {
    dispatch(replaceFilers(filters));
    filterChanged(filters);
  };

  const filterChanged = (filters: IFilters) => {
    let showIndicator = false;
    if (!filters) {
      return;
    }

    Object.keys(filters).forEach(key => {
      // @ts-ignore
      showIndicator = showIndicator || filters[key].filters.filter(x => x.isSelected).length > 0;
    });
  };

  const onRemoveSelectedFilter = (entityName: string, sectionKey: string, filterKey: string) => {
    dispatch(toggleFilter(entityName, sectionKey, filterKey));
    setHidePagination(false);
    dispatch(fetchUserActivitiesHistory(DEFAULT_PAGE, historyPagination.activities.pageSize));
    getRewardsData(DEFAULT_PAGE, historyPagination.rewards.pageSize);
    dispatch(fetchUserTicketEntriesHistory({ page: DEFAULT_PAGE }));
  };

  const { activitiesLastPage, rewardsLastPage } = historyData;
  const { drawPrizes, drawPrizeContentRetrieved, drawPrizesWithWinners } = drawDetail;
  const drawSecondChanceTicketHistory = _.reverse(_.orderBy(historyData.drawSecondChanceTicketHistory, [o => o.entryTime], ['asc']));
  const showEInstantTab = !!authorizedFlags && authorizedFlags.includes('EINSTANT_V1');

  return (
    <div className={classes.root}>
      <ScrollToTopOnMount />
      <WinnersDialog
        title={draw ? draw.name : ''}
        open={showWinnerDialog}
        drawEndDate={draw && draw.end ? formatLongMonthDayCommaYear(draw.end) : 'TBD'}
        drawDate={draw && draw.draw ? formatLongMonthDayCommaYear(draw.draw) : 'TBD'}
        dateType={'winner'}
        winnerAnnouncement={draw && draw?.winnerAnnouncement ? formatLongMonthDayCommaYear(draw.winnerAnnouncement) : 'TBD'}
        drawPrizesWithWinners={drawPrizesWithWinners}
        onClose={hideWinnersDialog}
        drawStartDate={draw ? formatLongMonthDayCommaYear(draw.start) : ''}
        onViewPrizeDetailsClicked={onViewPrizeDetailsAndImagesClicked}
        isSweepStake={false}
        secondChanceTicketHistory={drawSecondChanceTicketHistory}
        entries={draw.totalPrizeAmount}
      />
      <PrizesDialog
        title={draw ? draw.name + ' Prize Details' : ''}
        open={showPrizesDialog}
        onClose={closePrizesDialog}
        drawPrizeContentRetrieved={drawPrizeContentRetrieved}
        drawPrizes={drawPrizes}
        // @ts-ignore
        draw={draw}
      />
      <RewardGroupDialog
        open={isOpenRewardsGroupDialog}
        drawEntriesOnly={true}
        onClose={hideRewardsGroupDialog}
        historyRewards={rewardsGroup}
      />
      <Hideable show={Boolean(selectedRewardData && isOpenRedeemCouponDialog && barcodeData)}>
        <RewardCouponModal
          open={true}
          barcodeData={barcodeData}
          selectedReward={selectedRewardData || ''}
          historyReward={selectedRewardData || ''}
          onClose={hideRedeemCouponCodeModal}
          handleDownload={undefined}
          maxWidth={undefined}
        />
      </Hideable>
      <History
        loading={loading}
        historyTicketEntries={historyData.historyTicketEntries}
        historyTicketPage={historyData.ticketHistoryPage}
        historyRewardsPage={{
          lastPage: rewardsLastPage,
          currentPage: historyPagination.rewards.pageNumber,
          totalPages: historyPagination.rewards.totalPages,
          number: historyPagination.rewards.pageSize || DEFAULT_REWARDS_PER_PAGE
        }}
        redeemablePoints={userPoints}
        historyActivities={historyData.historyActivities.map(activity => {
          if (activity.extra_data?.bonus && +activity.extra_data.bonus > 0) {
            activity.bonusPoints = +activity.extra_data.bonus;
          }
          return activity;
        })}
        historyRewards={historyData.historyRewards}
        getTicketsData={getTicketsData}
        getActivitiesData={getActivitiesData}
        getRewardsData={getRewardsData}
        getDrawsData={getDrawsData}
        setSelectedHistoryItem={setSelectedTicketEntry}
        historyActivityPage={{
          lastPage: activitiesLastPage,
          currentPage: historyPagination.activities.pageNumber,
          totalPages: historyPagination.activities.totalPages,
          size: historyPagination.activities.pageSize
        }}
        perPageOptions={perPageOptions}
        DEFAULT_ACTIVITIES_PER_PAGE={DEFAULT_ACTIVITIES_PER_PAGE}
        DEFAULT_REWARDS_PER_PAGE={DEFAULT_REWARDS_PER_PAGE}
        HISTORY_ITEMS_PER_PAGE={constants.HISTORY_ITEMS_PER_PAGE}
        showPaginationInRewards={constants.SHOW_PAGINATION_IN_REWARDS}
        renderFilterSection={renderFilterSection}
        renderFilterSelectedSection={renderFilterSelectedSection}
        hidePagination={hidePagination}
        pendingPoints={historyData.pendingPoints}
        historyDraws={historyData.historyDraws}
        historyDrawPage={historyData.drawHistoryPage}
        navigateToDrawDetails={openWinnersPopup}
        navigateToPastRewardHistory={openRewardsGroupDialog}
        navigateToViewAndDownloadBarcode={openRedeemCouponModal}
        drawEntriesOnly={true}
        identifier={'activity'}
        errorMessage={historyData.errorMessage}
        showDigitalRevealTab={true} // this was false in ks
        digitalRevealGames={digitalRevealGames}
        digitalRevealPage={digitalRevealGamesPaging}
        getDigitalRevealGames={getDigitalRevealGames}
        onStartDigitalGame={startDigitalRevealGame}
        onEndDigitalGame={endDigitalRevealGame}
        showEInstantTab={showEInstantTab}
        onActivityRowClick={onActivityRowClick}
        isLegacyUser={(userData as any).isLegacyUser ?? false}
        digitalRevealGame={undefined}
        getEInstantsData={undefined}
        getPastHistory={undefined}
        historyEInstantsPage={undefined}
        historyEInstantsGrouped={undefined}
        navigateToEInstantsHistoryDetails={undefined}
        renderTitle={undefined}
        showRewardEntries={undefined}
        getPastActivitiesData={undefined}
        getPastRewardsData={undefined}
        hideDrawDate={undefined}
        navigateToPastActivityHistory={undefined}
        showPastHistoryFeature={undefined}
      />
    </div>
  );
};

export default withTheme(withStyles(styles)(HistoryScreen));
