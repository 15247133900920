import { AccountService } from '@pbl/pbl-react-core/lib/services';
import { clearLoading, clearMessages, showMessageBar, toggleLoading } from 'redux/reducers/app/actions';
import { getAccount } from 'redux/reducers/authentication/actions';
import NavigationUtils from 'utils/NavigationUtils';

export const changePassword = (email: string, currentPassword: string) => async (dispatch: any) => {
  try {
    dispatch(clearMessages());
    dispatch(toggleLoading({ spinning: true }));

    await AccountService.changeEmail(email, currentPassword);

    await dispatch(getAccount());

    dispatch(showMessageBar({ message: 'email.updated' }));

    NavigationUtils.replace('/settings/account-security', {
      currentRoute: '/settings/account-security',
      currentRouteName: 'Account & Security'
    });
  } catch (e: any) {
    dispatch(showMessageBar({ message: e.payload.title, type: 'error' }));
  } finally {
    dispatch(clearLoading());
  }
};
