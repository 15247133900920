import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
  withStyles,
  WithStyles,
  withTheme
} from '@material-ui/core';
import { ThemedComponentProps } from '@material-ui/core/styles/withTheme';
import { UserRegistrationDataFields } from '@pbl/pbl-react-core/lib/models/user-registration/types';
import { FieldRenderer, Icon } from '@pbl/pbl-react-web-components/lib/package';
import styles from 'assets/jss/modules/user-registration/UserRegistrationScreenStyle';
import classNames from 'classnames';
import { RegistrationFields } from 'config/registration';
import { passwordFails } from 'config/validation';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PasswordStrengthIndicator from 'shared/components/password/PasswordStrengthIndicator';

interface IProps extends ThemedComponentProps, WithStyles<typeof styles> {
  passwordErrors: passwordFails[];
  updateField: (fieldName: UserRegistrationDataFields, value: any) => any;
  accountCredentialsFields: RegistrationFields;
  showInviteCode: boolean;
  updateInviteCodeVisibility: (visibility: boolean) => any;
  tosChecked: boolean;
  handleTosChecked: () => any;
  inviteCodeStatus: string;
}

const AccountCredentials: React.FunctionComponent<IProps> = ({
  updateField,
  accountCredentialsFields,
  updateInviteCodeVisibility,
  showInviteCode,
  classes,
  passwordErrors,
  tosChecked,
  handleTosChecked,
  inviteCodeStatus
}: IProps) => {
  const getPasswordValidations = (): React.ReactNode => {
    const password = accountCredentialsFields.filter(x => x.type === 'password')[0].value;
    return <PasswordStrengthIndicator passwordErrors={passwordErrors} password={password} />;
  };

  const { t } = useTranslation();
  const openTos = () => {
    window.open('/media/terms_conditions');
  };

  const openToPrivacy = () => {
    window.open('/media/privacy');
  };

  const getFields = () => {
    return accountCredentialsFields.map(field => {
      const onFieldChange = (value: any) => {
        updateField(field.key as UserRegistrationDataFields, value);
      };

      let fieldView = (
        <FieldRenderer
          key={field.key}
          field={field}
          onChange={onFieldChange}
          className={classes.field}
          inputProps={{
            ...(field.key === 'inviteCode'
              ? {
                  error: inviteCodeStatus === 'inviteCode.limitReached',
                  helperText: inviteCodeStatus !== 'inviteCode.limitReached' ? '' : t('field.invitationCodeUsage')
                }
              : {}),
            spellCheck: false
          }}
          InputProps={{
            endAdornment: field.type === 'dateOfBirth' && (
              <InputAdornment position="end">
                <IconButton>
                  <Icon iconType={'custom'} iconName={'icon-calendar'} />
                </IconButton>
              </InputAdornment>
            )
          }}
          getGoogleAddressInput={undefined}
          labelProps={undefined}
          onError={undefined}
        />
      );
      if (field.key === 'inviteCode' && !showInviteCode) {
        const onUpdateInviteCodeVisibility = () => {
          updateInviteCodeVisibility(true);
        };
        fieldView = (
          <div style={{ flex: 1, width: '100%', display: 'flex' }}>
            <Button
              color={'primary'}
              variant="text"
              aria-label={'+ Add Invite Code'}
              onClick={onUpdateInviteCodeVisibility}
              style={{ textTransform: 'none' }}
            >
              {`+ Add Invite Code`}
            </Button>{' '}
            <p style={{ flexGrow: 1 }}>&nbsp;</p>
          </div>
        );
      }
      const passwordValidator = field.key === 'password' ? getPasswordValidations() : null;
      return (
        <Grid
          key={field.key}
          className={classNames({
            [classes.fieldContainer]: true,
            [classes.fixedHeight]: field.key === 'firstName' || field.key === 'lastName'
          })}
          item={true}
          xs={12}
          container={true}
          md={field.key === 'firstName' || field.key === 'lastName' ? 6 : 12}
          lg={field.key === 'firstName' || field.key === 'lastName' ? 5 : 10}
        >
          {fieldView}
          {passwordValidator}
        </Grid>
      );
    });
  };
  const terms = () => (
    <Typography variant={'body1'} style={{ pointerEvents: 'auto', paddingTop: '4px' }}>
      By clicking the box to the left, I agree to all
      <Button
        color={'primary'}
        aria-label={'Rules, T&Cs'}
        className={classes.textButton}
        variant="text"
        onClick={openTos}
        style={{ paddingTop: '4px', padding: ' 6px 2px' }}
      >
        Rules, T&Cs
      </Button>
      &nbsp;and the
      <Button
        color={'primary'}
        aria-label={'Privacy Policy'}
        className={classes.textButton}
        variant="text"
        onClick={openToPrivacy}
        style={{ paddingTop: '4px' }}
      >
        Privacy Policy
      </Button>
    </Typography>
  );
  return (
    <Grid item={true} xs={12} container={true} alignItems="center" justify="center">
      {getFields()}
      <Grid container={true} className={classes.fieldContainer} item={true} xs={12} md={12} lg={10}>
        <FormControlLabel
          style={{ pointerEvents: 'none', alignItems: 'flex-start' }}
          control={
            <Checkbox
              checked={tosChecked}
              onChange={handleTosChecked}
              color={'primary'}
              style={{ padding: '4px 4px 4px 12px', pointerEvents: 'auto' }}
            />
          }
          label={terms()}
        />
      </Grid>
    </Grid>
  );
};

export default withTheme(withStyles(styles)(AccountCredentials));
