import {
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Hidden,
  Link,
  Theme,
  Tooltip,
  Typography,
  withTheme
} from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Address } from '@pbl/pbl-react-core/lib/models/forms';
import { AddressType, IProfile, PhoneType } from '@pbl/pbl-react-core/lib/models/profile/types';
import PhoneInputMask from '@pbl/pbl-react-web-components/lib/field/PhoneInputMask';
import TextFieldValidator, { TextFieldValidatorProps } from '@pbl/pbl-react-web-components/lib/field/TextFieldValidator';
import {
  ArcadeCurrency,
  ArcadeMessagePopup,
  FailedTransactionModal,
  GeoLocationMessagePopUp,
  Icon
} from '@pbl/pbl-react-web-components/lib/package';
import CheckoutModal from '@pbl/pbl-react-web-components/lib/store/CheckoutModal';
import OrderConfirmation from '@pbl/pbl-react-web-components/lib/store/OrderConfirmation';
import ShoppingCardTable from '@pbl/pbl-react-web-components/lib/store/ShoppingCartTable';
import WarningModal from '@pbl/pbl-react-web-components/lib/store/WarningModal';
import styles from 'assets/jss/modules/store/StoreScreenStyle';
import classNames from 'classnames';
import { phoneMask } from 'config/validation';
import { addressSchema, billingPhoneNumberValidator, phoneNumberValidator } from 'modules/settings/profile/validations';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { conformToMask } from 'react-text-mask';
import { IRootState } from 'redux/reducers';
import { getAllCountries, getCities, getStates, resetCities, resetStates } from 'redux/reducers/address/actions';
import { showMessageBar } from 'redux/reducers/app/actions';
import { isAuthenticated } from 'redux/reducers/authentication/actions';
import { setIsFormDirty } from 'redux/reducers/forms-metadata/actions';
import { fetchUserTokens } from 'redux/reducers/ledger/actions';
import { getProfile, updateBillingAddress } from 'redux/reducers/profile/actions';
import { clearStorage, submitOrder } from 'redux/reducers/store/actions';
import AddressComponent from 'shared/components/input/Address';
import ScrollToTopOnMount from 'shared/components/routes/ScrollToTopOnMount';
import { scrollToTheTop } from 'utils/htmlUtil';

type PropsConnected = ConnectedProps<typeof connector>;

interface IStoreScreenProps extends PropsConnected, RouteComponentProps<any>, WithTranslation, WithStyles<typeof styles> {
  theme: Theme;
}

const INITIAL_STATE = {
  address: new Address(),
  phone: '',
  formIsValid: false,
  formIsDirty: false,
  stateCode: '',
  checkedBilling: false,
  openPaymentFrame: false,
  showWarning: false,
  loading: false,
  showError: false,
  showThresholdMessage: false,
  mGeoUserInRegion: undefined,
  mGeoLocationBlocked: undefined,
  mGeoLoaded: undefined,
  showGeoFencingMessage: false,
  showGeoLocationNotAvailableMessage: false,
  noLocationAvailable: undefined
};

class StoreBilling extends React.Component<IStoreScreenProps> {
  private inputHandlers = [];
  public state = INITIAL_STATE;
  public async componentDidMount() {
    await this.props.getAllCountries();
    document.title = 'Billing details';
    await this.props.isAuthenticated();
    await this.loadGeoJS();
    scrollToTheTop();
    this.props.fetchUserTokens();
    await this.props.getProfile();
    if (!this.props.profile.userProfile.idologyValid) {
      this.props.history.push('/lucky-lounge/store');
    }
    this.setBillingAddress();
    window.addEventListener('message', this.receiveMessage, false);
  }

  private stopJS = () => {
    if (window.mGeoJS) {
      console.warn('Unsubscribing from mGeoJS');
      window.mGeoJS.stop();
    }
  };
  private startGeoJS = () => {
    window.mGeoJS.start({ ping: true }).then(x => {
      console.warn('mGeoJS started', x);
      if (this.state.mGeoUserInRegion === undefined) {
        this.setState({ mGeoUserInRegion: true });
      }
    });
  };
  private loadGeoJS = async () => {
    const mGeo = require('../../utils/mGeoJS');
    mGeo.default(async () => {
      if (window.mGeoJS) {
        this.setState({ mGeoLoaded: true });

        this.startGeoJS();

        window.mGeoJS.subscribe('outOfRegion', param => {
          console.warn('outOfRegion', param);
          this.stopJS();
          this.setState({ mGeoUserInRegion: false });
        });

        window.mGeoJS.subscribe('noGeoLocation', reason => {
          console.warn('noGeoLocation event', reason);
          this.stopJS();
          if ('DENIED' !== reason) {
            this.setState({ noLocationAvailable: true });
          } else {
            this.setState({ mGeoUserInRegion: false });
          }
        });

        window.mGeoJS.subscribe('noGeoLocation', reason => {
          console.warn('noGeoLocation event', reason);
          if ('DENIED' !== reason) {
            this.setState({ mGeoUserInRegion: false, geoLocationAvailable: false });
          }
        });
      }
    });
  };

  private validateGeoFencing = async () => {
    const { mGeoUserInRegion, mGeoLoaded, noLocationAvailable } = this.state;
    if (!mGeoLoaded || mGeoUserInRegion === undefined) {
      this.setState({ mGeoLocationBlocked: true });
      return;
    }

    // status can be GRANTED, DENIED OR  UNKNOWN
    const geoStatus = window.mGeoJS.getStatus();
    if (geoStatus !== 'GRANTED') {
      if (geoStatus === 'DENIED') {
        this.setState({ mGeoLocationBlocked: true });
      } else {
        this.setState({ showGeoLocationNotAvailableMessage: true });
      }
      return;
    }
    // if noGeoLocation was raised then show the proper message.
    if (noLocationAvailable) {
      this.setState({ showGeoLocationNotAvailableMessage: true });
      return;
    }

    // if outOfRegion or permissionDenied was raised then show the proper message.
    if (!mGeoUserInRegion) {
      this.setState({ mGeoLocationBlocked: false, showGeoFencingMessage: true });
      return;
    }
    // if everything is good so far, then geo fencing passed OK and must continue the the next stage
    this.proccedToPayment();
  };

  public componentWillUnmount() {
    this.props.setIsFormDirty(false);
    window.removeEventListener('message', this.receiveMessage);
    this.stopJS();
  }

  private receiveMessage = async (event: any) => {
    const { data } = event;
    if (!data || data.type !== 'PaymentResponseScreen') return;

    switch (data.result) {
      case 'success': {
        this.props.history.push({
          pathname: `/lucky-lounge/store-payment-success`,
          state: { transactionId: data.parameters.TransactionID }
        });
        console.warn('***** success', data);
        break;
      }
      case 'failed': {
        if (data.parameters.Error === '[billinginfo.validation.invalid.checksum]') {
          await this.props.showMessageBar({
            message: 'Invalid billing information provided, please update and try again.',
            type: 'error',
            messageTimeout: 5000
          });
        }
        break;
      }
      default:
        this.props.history.push('/lucky-lounge/store');
    }
    await this.setState({ openPaymentFrame: false });
  };

  private navigateToHistory = (): void => {
    this.props.history.push('/lucky-lounge/history');
  };

  private onCloseWarningMessage = () => {
    this.setState({ showWarning: false, checkedBilling: false });
  };

  private onClosePaymentFrame = () => {
    this.setState({ openPaymentFrame: false });
  };

  private onPurchaseSuccess = () => {
    this.setState({ showError: false, openPaymentFrame: false });
    this.props.history.push('/lucky-lounge/store-payment-success');
  };

  private setBillingAddress = () => {
    const { profile } = this.props;
    const userProfile = profile.userProfile || ({} as IProfile);
    const billingAddress = userProfile.addresses.filter(x => x.addressTypeId === AddressType.BILLING)[0];
    const billingPhone = userProfile.phones.filter(p => p.phoneTypeId === PhoneType.BILLING);
    this.setState({
      address: new Address(billingAddress),
      firstName: userProfile.firstName || '',
      lastName: userProfile.lastName || '',
      dateOfBirth: userProfile.birthDate,
      phone: billingPhone?.[0]?.phone
    });
  };

  public onNavigate = (): void => {
    this.props.history.push('/lucky-lounge');
  };

  private handleTextFieldChange = (attr: string) => {
    if (!this.inputHandlers[attr]) {
      this.inputHandlers[attr] = async event => {
        const value = event.target.value;
        this.setState({ [attr]: value, formIsDirty: true }, () => {
          const formIsValid = this.validateForm();
          this.setState({ formIsValid });
          this.props.setIsFormDirty(true);
        });
      };
    }
    return this.inputHandlers[attr];
  };

  private validateForm = () => {
    const { phone, address } = this.state;
    const formIsValid = phoneNumberValidator.required().isValidSync(phone);
    const addressIsValid = addressSchema.isValidSync({
      address1: address.address1,
      city: address.city,
      zipCode: address.zipCode,
      country: address.country,
      state: address.state
    });
    return formIsValid && addressIsValid;
  };

  private onBlur = () => {
    const formIsValid = this.validateForm();
    this.setState({ formIsValid });
  };

  private handleAddressSelect = (field: string, value: string) => {
    const { address } = this.state;
    if (address.hasOwnProperty(field)) {
      address[field] = value;
    }
    if (field === 'stateCode') {
      this.setState({ stateCode: value });
    }
    const formIsValid = this.validateForm();
    this.setState({ address, formIsValid, formIsDirty: true }, () => {
      this.props.setIsFormDirty(true);
    });
  };

  private useHomeAsBillingAddress = async () => {
    if (!this.state.checkedBilling) {
      if (this.state.formIsValid) {
        this.setState({ showWarning: true });
      } else {
        await this.overrideBillingDetails();
      }
    } else {
      this.setState(INITIAL_STATE);
      this.setState({ address: new Address() });
    }
  };

  private overrideBillingDetails = async () => {
    const { profile } = this.props;
    const userProfile = profile.userProfile || ({} as IProfile);
    const { firstName, lastName, birthDate, addresses, phones } = userProfile;

    await this.setState({ address: new Address() });
    const addressObject =
      addresses.filter(x => x.addressTypeId === AddressType.HOME).length > 0
        ? addresses.filter(x => x.addressTypeId === AddressType.HOME)[0]
        : new Address();

    this.setState({
      firstName: firstName || '',
      lastName: lastName || '',
      dateOfBirth: birthDate,
      address: addressObject,
      phone: phones?.[0]?.phone || '',
      checkedBilling: true,
      showWarning: false
    });
  };

  private onSubmitProceedToPay = async () => {
    await this.validateGeoFencing();
  };

  private proccedToPayment = async () => {
    const { store, profile } = this.props;
    const playerDetails = {
      firstName: profile.userProfile.firstName,
      lastName: profile.userProfile.lastName,
      email: profile.userProfile.email,
      address1: this.state.address.address1,
      address2: this.state.address.address2,
      city: this.state.address.city,
      country: this.state.address.countryCode,
      state: this.state.stateCode,
      zip: this.state.address.zipCode,
      phone1: this.state.phone?.replace(/\D/g, ''),
      dateOfBirth: profile.userProfile.birthDate
    };
    if (!this.state.checkedBilling) {
      await this.props.updateBillingAddress(this.state);
    }
    await this.props.submitOrder({ items: store.selectedItems, playerDetails }, () => this.setState({ openPaymentFrame: true }));
  };

  private getAddressString = (): string => {
    const {
      profile: { userProfile }
    } = this.props;
    const address = userProfile?.addresses?.filter(x => x.addressTypeId === AddressType.HOME)?.[0];
    return address
      ? address.address1
          .concat(', ')
          .concat(address.city)
          .concat(', ')
          .concat(address.state)
          .concat(', ')
          .concat(address.country)
          .concat(', ')
          .concat(address.zipCode)
      : '';
  };
  private closeError = () => {
    this.setState({ showError: false, showThresholdMessage: false });
  };

  private onClickFaq = () => {
    this.props.history.push('/media/faq');
  };

  private onClickTerms = () => {
    this.props.history.push('/media/terms_conditions');
  };

  public navigateToBuyScreen = (): void => {
    this.props.history.push('/lucky-lounge/store');
  };

  private hideGeoFencingMessage = () => {
    this.startGeoJS();
    return this.setState({ mGeoLocationBlocked: false, showGeoFencingMessage: false });
  };

  private failedGeoFencingModal = (): React.ReactNode => {
    const { t } = this.props;
    return (
      <GeoLocationMessagePopUp
        open={this.state.showGeoFencingMessage}
        title={t('store.geofencing.title')}
        closeText={t('store.geofencing.close')}
        onClickFaq={this.onClickFaq}
        onClickTerms={this.onClickTerms}
        onSubmit={this.navigateToBuyScreen}
        onClose={this.hideGeoFencingMessage}
      />
    );
  };

  private geoLocationBlockedModal = (): React.ReactNode => {
    const { t } = this.props;
    return (
      <ArcadeMessagePopup
        open={!!this.state.mGeoLocationBlocked}
        title={t('store.geofencing.blockedTitle')}
        text={t('store.geofencing.blockedMessage')}
        closeText={t('store.geofencing.close')}
        onClose={this.hideGeoFencingMessage}
        disableBackdropClick={false}
      />
    );
  };
  private geoLocationUnknownModal = (): React.ReactNode => {
    const { t } = this.props;
    return (
      <ArcadeMessagePopup
        open={this.state.showGeoLocationNotAvailableMessage}
        title={t('store.geofencing.titleGeoLocationNotAvailable')}
        text={t('store.geofencing.geoLocationNotAvailable')}
        closeText={t('arcade.reward.geofencing.close')}
        onClose={this.hideGeoFencingMessage}
        disableBackdropClick={false}
      />
    );
  };

  private checkoutIframe = (): React.ReactNode => {
    const { response } = this.props.store;
    return (
      this.state.openPaymentFrame &&
      response?.paymentUrl && (
        <CheckoutModal
          paymentUrl={response.paymentUrl}
          onPurchase={this.onClosePaymentFrame}
          open={this.state.openPaymentFrame}
          onClose={this.onPurchaseSuccess}
        />
      )
    );
  };

  private warningModal = (): React.ReactNode => {
    const { t } = this.props;
    return (
      this.state.showWarning && (
        <WarningModal
          open={this.state.showWarning}
          onClickNo={this.onCloseWarningMessage}
          onClickYes={this.overrideBillingDetails}
          title={t('store.billing.overwriteTitle')}
          message={t('store.billing.overwriteMessage')}
        />
      )
    );
  };

  private failedTransactionModal = (): React.ReactNode => {
    const { response } = this.props.store;
    return (
      this.state.showError && (
        <FailedTransactionModal
          transactionNumber={response ? response.transactionId : ''}
          open={this.state.showError}
          onClose={this.closeError}
          proceedToPay={this.onSubmitProceedToPay}
        />
      )
    );
  };

  public render() {
    const { phone, checkedBilling, formIsValid } = this.state;
    const { classes, store, userTokens, profile, t } = this.props;
    const items = store.selectedItems || [];
    const total = store.total || 0;
    const userProfile = profile.userProfile;
    const addressString = this.getAddressString();
    let phoneMaskedValue = '';
    if (!!phone && phone.toString().length > 0) {
      phoneMaskedValue = conformToMask(phone, phoneMask, {}).conformedValue;
    }
    return (
      <div className={classes.container}>
        {this.checkoutIframe()}
        {this.warningModal()}
        {this.failedTransactionModal()}
        {this.failedGeoFencingModal()}
        {this.geoLocationBlockedModal()}
        {this.geoLocationUnknownModal()}
        <Grid container={true} spacing={4}>
          <ScrollToTopOnMount />
          <div className={classNames(classes.container, classes.header)}>
            <div className={classes.detailHeader}>
              <Tooltip title={`Lucky Lounge`}>
                <Link onClick={this.onNavigate} component={'a'} aria-label={`Go back to Games list`} className={classes.backButton}>
                  <Typography variant="body2" color="textPrimary" className={classes.centered}>
                    {'Lucky Lounge'} <Icon iconType={'material'} iconName={'chevron_right'} />
                  </Typography>
                </Link>
              </Tooltip>
              <Hidden mdUp={true}>
                <Typography variant="h6" component={'h1'} color="textPrimary" className={classes.center}>
                  {t('store.title')}
                  <ArcadeCurrency color={'secondary'} size={23} />
                </Typography>
              </Hidden>
              <Hidden smDown={true}>
                <Typography variant="h5" component={'h1'} color="textPrimary" className={classes.center}>
                  {t('store.title')}
                  <ArcadeCurrency color={'secondary'} size={31} />
                </Typography>
              </Hidden>
            </div>
            <div className={classes.tokensContainer}>
              <div className={classes.tokens}>
                <Typography
                  variant="h5"
                  color={'textPrimary'}
                  style={{ display: 'flex', alignItems: 'center', marginLeft: '22px' }}
                  component={'span'}
                >
                  {userTokens.balance.toPointsFormat()}
                  <ArcadeCurrency color={'secondary'} size={22} />
                </Typography>
                <Typography variant="overline" color="textPrimary" className={classes.tokensTxt}>
                  {t('store.token_plural')}
                </Typography>
              </div>
              <Button variant={'text'} size={'large'} color={'secondary'} onClick={this.navigateToHistory} className={classes.historyBtn}>
                <Typography variant="overline" color="secondary" style={{ display: 'flex', alignItems: 'center' }}>
                  <Icon iconType={'material'} iconName={'history'} className={classes.historyIcon} />
                  {t('store.history')}
                </Typography>
              </Button>
            </div>
          </div>
          <Grid item={true} xs={12}>
            <OrderConfirmation userProfile={userProfile} address={addressString} />
          </Grid>
          <Grid item={true} xs={12} className={classes.billing}>
            <Container maxWidth={'md'}>
              <Grid spacing={4} xs={12}>
                <Grid item={true} xs={12}>
                  <Typography variant="h6" align={'center'} className={classes.colorBlack}>
                    {t('store.billing.billingDetailsTitle')}
                  </Typography>
                </Grid>
                <Grid item={true} xs={12}>
                  <Typography variant="body2" align={'center'} className={classes.colorBlackforSecondary}>
                    {t('store.billing.fieldsRequired')}
                  </Typography>
                </Grid>
                <Grid item={true} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        className={classes.checkBox}
                        checked={checkedBilling}
                        onChange={this.useHomeAsBillingAddress}
                        inputProps={{
                          'aria-label': 'primary checkbox'
                        }}
                      />
                    }
                    label={t('store.billing.useAsBilling')}
                  />
                </Grid>
                <AddressComponent
                  contentClass={classes.addressContent}
                  fieldClass={classes.fieldClass}
                  fieldAutocompleteClass={classes.fieldAutocompleteClass}
                  address={this.state.address}
                  handleTextFieldChange={this.handleAddressSelect}
                  fieldVariant={'filled'}
                  getStates={this.props.getStates}
                  getCities={this.props.getCities}
                  countries={this.props.address.countries}
                  states={this.props.address.states}
                  cities={this.props.address.cities}
                  resetStates={this.props.resetStates}
                  resetCities={this.props.resetCities}
                  loadingCities={this.props.address.loading}
                  showMandatory={true}
                  disable={checkedBilling}
                />
              </Grid>
              <Grid container={true} spacing={4} className={classes.fieldContainer}>
                <Grid item={true} xs={12} sm={12} md={6}>
                  <TextFieldValidator
                    {...({
                      id: 'phone',
                      label: 'Phone Number',
                      'aria-label': 'Phone',
                      className: classes.field,
                      value: phoneMaskedValue,
                      onBlur: this.onBlur,
                      onChange: this.handleTextFieldChange('phone'),
                      InputProps: { inputComponent: PhoneInputMask as any },
                      validationSchema: billingPhoneNumberValidator,
                      variant: 'filled',
                      disabled: checkedBilling,
                      required: true
                    } as unknown as TextFieldValidatorProps)}
                  />
                </Grid>
              </Grid>
            </Container>
          </Grid>
          <Grid item={true} xs={12} className={classes.card}>
            <ShoppingCardTable
              items={items}
              total={total}
              onAddItem={undefined}
              onRemoveAll={undefined}
              onRemoveItem={undefined}
              proceedCheckout={undefined}
            />
          </Grid>
        </Grid>
        <Grid className={classes.buttonContainer}>
          <Typography variant="body2" align={'center'} color="textPrimary">
            {t('store.billing.info')}
          </Typography>
          <div className={classes.proceedButton}>
            <Button
              color="primary"
              onClick={this.onSubmitProceedToPay}
              aria-label={t('store.proceedToPay')}
              title={t('store.proceedToPay')}
              disabled={!formIsValid}
              variant={'contained'}
            >
              {t('store.proceedToPay')}
            </Button>
          </div>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = ({ authentication: { accessToken, account }, store, ledger: { userTokens }, profile, address }: IRootState) => ({
  isLoggedIn: !!accessToken && accessToken.length > 0 && !!account && !!account.email,
  store,
  userTokens,
  profile,
  address
});

const mapDispatchToProps = {
  clearStorage,
  fetchUserTokens,
  setIsFormDirty,
  getStates,
  getCities,
  resetCities,
  resetStates,
  submitOrder,
  getProfile,
  updateBillingAddress,
  showMessageBar,
  isAuthenticated,
  getAllCountries
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default withRouter(connector(withStyles(styles)(withTheme(withTranslation()(StoreBilling)))));
