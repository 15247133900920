import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Theme, withStyles, WithStyles, withTheme } from '@material-ui/core/styles';
import { IActivity } from '@pbl/pbl-react-core/lib/models/activity/types';
import { IErrorDTO } from '@pbl/pbl-react-core/lib/models/app';
import { IResponse, ISubmitResponse, ISurveyResult } from '@pbl/pbl-react-core/lib/models/survey/pbl/types';
import { SurveyService } from '@pbl/pbl-react-core/lib/services';
import AppSpinner from '@pbl/pbl-react-web-components/lib/app-spinner/AppSpinner';
import { Hideable } from '@pbl/pbl-react-web-components/lib/hideable';
import { EarnActivityHeader, EarnPointsModal, InfoModal } from '@pbl/pbl-react-web-components/lib/package';
import SurveyContent from '@pbl/pbl-react-web-components/lib/survey/pbl/SurveyContent';
import colors from 'assets/jss/colors';
import styles from 'assets/jss/modules/poll/PollScreenStyle';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { IRootState } from 'redux/reducers';
import { fetchActivities, fetchUserActivities } from 'redux/reducers/activity/actions';
import { showMessageBar } from 'redux/reducers/app/actions';
import { isAuthenticated } from 'redux/reducers/authentication/actions';
import { setIsFormDirty } from 'redux/reducers/forms-metadata/actions';
import {
  fetchUserPoints,
  resetActivityCompleted,
  resetState,
  setActivityCompleted,
  setSelectedUserActivity
} from 'redux/reducers/loyalty/actions';
import { fetchPoll, reset, resetError } from 'redux/reducers/poll/action';
import { submitPoll } from 'redux/reducers/survey/action';
import { scrollToTheTop } from 'utils/htmlUtil';

interface IMatchParams {
  activityId: string;
}

interface IPollScreenProps extends RouteComponentProps<IMatchParams>, WithStyles<typeof styles>, WithTranslation {
  theme: Theme;
}

interface IPollScreenState {
  displayImage: boolean;
  answerId: number | null;
  loading: boolean;
  activity: IActivity[] | null;
  selectedActivity: IActivity | null;
  userResponse: ISubmitResponse;
  pollPostResponse: IPollPostResponse;
  pollResult: ISurveyResult;
  showSuccessModal: boolean;
  showErrorModal: boolean;
  poll: any;
}

interface IPollPostOption {
  id: number;
  optionText: string;
  optionType: string;
  totalResponse: number;
}

interface IPollPostResponse {
  id: number;
  questionText: string;
  questionType: string;
  optional: boolean;
  surveyId: number;
  options: IPollPostOption[];
  playersAnswerId: number;
  totalResponse: number;
}

type Props = ConnectedProps<typeof connector> & IPollScreenProps;

class PollScreen extends React.Component<Props, IPollScreenState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      displayImage: false,
      loading: true,
      answerId: null,
      activity: [],
      selectedActivity: null,
      userResponse: { surveyId: 0, responses: [] },
      pollResult: {} as ISurveyResult,
      showErrorModal: false,
      showSuccessModal: false,
      poll: null,
      pollPostResponse: {
        id: 0,
        questionText: '',
        questionType: '',
        optional: false,
        surveyId: 0,
        playersAnswerId: 0,
        options: [],
        totalResponse: 0
      }
    };
  }

  public async componentDidMount() {
    document.title = 'Poll Activity';
    scrollToTheTop();
    const {
      match: { params }
    } = this.props;
    if (params.activityId.trim().length > 0) {
      await this.getData();
    } else {
      await this.showInvalidActivityError();
    }
  }

  public getBackgroundColors = (index: number, pollPostResponse: IPollPostResponse) => {
    const selectedIndex = pollPostResponse.options.findIndex(item => item.id === pollPostResponse.playersAnswerId);
    if (index === selectedIndex) {
      return colors.themeColors.cornerColors.game.toString();
    } else return colors.themeColors.cornerColors.reward.toString();
  };

  public componentWillUnmount() {
    this.props.reset();
    this.props.setIsFormDirty(false);
  }

  public async getData() {
    const {
      match: {
        params: { activityId }
      }
    } = this.props;

    await this.props.resetState();
    let data: IActivity[] | undefined;
    const list = this.props.isLoggedIn ? await fetchUserActivities(false) : await fetchActivities(false);
    const featured = this.props.isLoggedIn ? await fetchUserActivities(true) : await fetchActivities(true);
    data = featured && list ? [...featured, ...list] : list;
    this.setState((prevState: IPollScreenState) => ({
      ...prevState,
      loading: true,
      activity: data!
    }));

    const selectedActivity = (data || []).find(activity => activity.id.toString() === activityId);
    if (selectedActivity) {
      try {
        const poll = await SurveyService.getSurvey(selectedActivity.key);
        this.setState((prevState: IPollScreenState) => ({
          ...prevState,
          selectedActivity,
          poll,
          loading: false
        }));
      } catch (e) {
        throw e;
      }
    } else {
      this.setState((prevState: IPollScreenState) => ({
        ...prevState,
        loading: false
      }));
      await this.showInvalidActivityError();
    }
  }

  private showInvalidActivityError = async () => {
    const { history } = this.props;
    await history.push('/earn');
  };

  public onChange = (answer: IResponse) => {
    let currentResponses = this.state.userResponse.responses ?? [];
    const { poll } = this.state;
    currentResponses = currentResponses.filter(x => x.questionId !== answer.questionId);
    currentResponses.push(answer);
    this.setState((prevState: IPollScreenState) => ({
      ...prevState,
      userResponse: { surveyId: poll && poll['id'], responses: currentResponses }
    }));
  };

  public postPoll = async () => {
    const { isLoggedIn } = this.props;
    if (!isLoggedIn) {
      this.props.history.push('/login', { from: this.props.location });
      return;
    }
    this.setState((prevState: IPollScreenState) => ({
      ...prevState,
      loading: true
    }));
    const userResponse = this.state.userResponse as ISubmitResponse;
    const responses = userResponse.responses;
    responses.forEach(item => (item.responseText ? (item.options = undefined) : item));
    const submitResponse = await submitPoll(userResponse);
    this.validateResponse(submitResponse);
    this.props.fetchUserPoints();
    const pollPostResponse = submitResponse.userResponses && submitResponse.userResponses[0];
    if (pollPostResponse) {
      pollPostResponse.playersAnswerId = submitResponse.results && submitResponse.results[0].answerId;
      this.setState((prevState: IPollScreenState) => ({
        ...prevState,
        pollPostResponse,
        loading: false
      }));
    }
  };

  public validateResponse = async (data: ISurveyResult | IErrorDTO) => {
    if (data && 'errorKey' in data && data.errorKey) {
      this.props.showMessageBar({
        message: data.errorKey,
        type: 'error',
        messageTimeout: 10000
      });
    } else if ((data as any)?.detail?.includes('invalidAccess')) {
      this.props.showMessageBar({
        message: this.props.t('error.activity.invalidAccess'),
        type: 'error',
        messageTimeout: 10000
      });
    } else if (data && this.state.selectedActivity) {
      this.setState((prevState: IPollScreenState) => ({
        ...prevState,
        pollResult: data as ISurveyResult
      }));

      if (data && 'message' in data && data.message && /^error:.*/i.test(data.message)) {
        this.setState((prevState: IPollScreenState) => ({
          ...prevState,
          showErrorModal: true
        }));
        return;
      }

      if ('points' in data && data.points === 0) {
        this.props.showMessageBar({ message: 'poll.submitted' });
      } else {
        this.setState((prevState: IPollScreenState) => ({
          ...prevState,
          showSuccessModal: true
        }));
      }
    }
  };

  public dismissModals = (): void => {
    this.setState((prevState: IPollScreenState) => ({
      ...prevState,
      showErrorModal: false,
      showSuccessModal: false
    }));
  };

  public percentage = (partialValue: number, totalValue: number) => {
    if (totalValue === 0) {
      return totalValue;
    }
    return Math.round((100 * partialValue) / totalValue);
  };

  public getContent() {
    const { classes, theme } = this.props;
    const { selectedActivity, userResponse, loading, pollPostResponse, poll } = this.state;
    return poll ? (
      <Grid item={true} xs={12}>
        {poll ? (
          pollPostResponse.id ? (
            pollPostResponse.options.map((result: IPollPostOption, index: number) => (
              <div className={classes.pollOption} key={index}>
                <Typography
                  variant={'body2'}
                  className={classes.pollAnswer}
                  style={{
                    color: theme.palette.text.primary
                  }}
                >
                  {`${this.percentage(result.totalResponse, pollPostResponse.totalResponse)}% ${result.optionText}`}
                </Typography>
                <div
                  className={classes.pollBackground}
                  style={{
                    width: `${
                      this.percentage(result.totalResponse, pollPostResponse.totalResponse) > 5
                        ? this.percentage(result.totalResponse, pollPostResponse.totalResponse)
                        : 5
                    }%`,
                    backgroundColor:
                      pollPostResponse.playersAnswerId === result.id
                        ? colors.themeColors.cornerColors.game.toString()
                        : this.getBackgroundColors(index, pollPostResponse),
                    opacity: 1
                  }}
                />
              </div>
            ))
          ) : (
            <SurveyContent
              onSaveSurvey={this.postPoll}
              onChange={this.onChange}
              survey={poll}
              playerResponses={userResponse.responses}
              isLoading={loading}
              isSystemSurvey={false}
              bonusPoints={selectedActivity?.bonusAmount}
              amount={selectedActivity?.amount!}
              limitReached={0}
              handleContactUs={undefined}
              isRestricted={undefined}
              quizResponse={undefined}
              showSummaryAnswers={undefined}
              showSignInWarning={undefined}
              isLoggedIn={undefined}
            />
          )
        ) : null}
      </Grid>
    ) : null;
  }

  private navigateTo = (): void => {
    this.props.history.push('/earn');
  };

  public render() {
    const { classes, t } = this.props;
    const { selectedActivity, pollResult, showErrorModal, showSuccessModal, poll } = this.state;
    const isLoadingData = this.state.loading;
    return (
      <div>
        <Hideable show={Boolean(pollResult && pollResult.points && showSuccessModal)}>
          <EarnPointsModal
            pointEarned={pollResult.points}
            isVisible={true}
            onClose={this.dismissModals}
            successHeadingMsg={poll?.message}
          />
        </Hideable>
        <Hideable show={showErrorModal}>
          <InfoModal
            isVisible={true}
            heading={t('poll.infoErrorModal.heading')}
            message={t('poll.infoErrorModal.message')}
            buttonText={t('poll.infoErrorModal.buttonText')}
            onClose={this.dismissModals}
          />
        </Hideable>
        <div className={classes.container}>
          {isLoadingData ? (
            <AppSpinner label={t('poll.loading')} />
          ) : (
            poll && (
              <Grid className={classes.container} container={true} spacing={2}>
                <Grid item={true} xs={12}>
                  <EarnActivityHeader
                    data={{
                      image: selectedActivity?.imageUrl,
                      title: selectedActivity?.title,
                      description: selectedActivity?.description || '',
                      points: selectedActivity?.amount || 0,
                      preview_url: selectedActivity?.videoUrl ? selectedActivity.videoUrl : undefined,
                      isCoverImage: true,
                      extraData: selectedActivity?.extraData
                    }}
                    bonusPoints={!!selectedActivity ? selectedActivity.bonusAmount : 0}
                    navigateTo={this.navigateTo}
                  />
                  {this.getContent()}
                </Grid>
              </Grid>
            )
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  poll,
  loyalty: { selectedActivity, loading, userPoints, activityCompleted, userActivities },
  authentication: { accessToken, account },
  formMetadata
}: IRootState) => ({
  isLoggedIn: !!accessToken && accessToken.length > 0 && !!account && !!account.email,
  poll,
  selectedActivity,
  loading,
  userPoints,
  accessToken,
  activityCompleted,
  userActivities,
  formMetadata
});

const mapDispatchToProps = {
  fetchPoll,
  resetError,
  setActivityCompleted,
  resetActivityCompleted,
  resetState,
  reset,
  setSelectedUserActivity,
  fetchActivities,
  fetchUserActivities,
  isAuthenticated,
  showMessageBar,
  setIsFormDirty,
  fetchUserPoints
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(withStyles(styles)(withTheme(withTranslation()(PollScreen))));
