import {
  CREDIT,
  DISMISS_DAILY_BONUS,
  GET_ACTIVITY,
  GET_ACTIVITY_FEATURED,
  GET_ACTIVITY_LIST_V2,
  GET_ACTIVITY_WITH_FREQ_CAP,
  GET_BONUS_ACTIVITY,
  IActivity,
  IBonusCampaign,
  ICodeSubmitRequest,
  ICodeSubmitResponse
} from '@pbl/pbl-react-core/lib/models/activity/types';
import { IErrorDTO } from '@pbl/pbl-react-core/lib/models/app';
import { ActivityService, CodeRedemptionService } from '@pbl/pbl-react-core/lib/services';
import constants from 'config/constants';
import moment from 'moment';
import { FAILURE, SUCCESS } from 'redux/action-type.util';
import { GetStateMethod } from 'redux/reducers';
import { fetchUserPoints } from '../ledger/actions';

export const fetchActivities = async (featured: boolean): Promise<IActivity[] | undefined> => {
  try {
    const data = await ActivityService.getActivities(false, featured);

    return data as IActivity[];
  } catch (e) {
    console.error(e);
  }
};
export const getBonusCampaign = async (id: number): Promise<IBonusCampaign | undefined> => {
  try {
    return await ActivityService.getBonusCampaign(id);
  } catch (e) {
    console.error(e);
  }
};

export const fetchUserActivities = async (featured: boolean): Promise<IActivity[] | undefined> => {
  try {
    const data = await ActivityService.getUserActivities(false, featured);

    return data as IActivity[];
  } catch (e) {
    console.error(e);
  }
};

export const fetchActivity = (key: string, successCallback: (IActivity) => void) => async (dispatch: any, getState: GetStateMethod) => {
  try {
    const activity = await ActivityService.fetchActivity(key);
    const activities = getState().activityState.activities ?? {};
    activities[activity.key] = activity;
    await dispatch({
      type: SUCCESS(GET_ACTIVITY),
      payload: { activities }
    });
    successCallback(activity);
  } catch (error) {
    dispatch({
      type: FAILURE(GET_ACTIVITY),
      payload: error
    });
    console.error(error);
  }
};

export const credit = (key: string, onSuccess: () => void) => async (dispatch: any, getState: GetStateMethod) => {
  try {
    const activity = await ActivityService.credit(key);
    const activities = getState().activityState.activities ?? {};
    activities[activity.key] = activity;
    await dispatch({
      type: SUCCESS(CREDIT),
      payload: { activities }
    });
    onSuccess();
  } catch (error) {
    dispatch({
      type: FAILURE(CREDIT),
      payload: error
    });
    console.error(error);
  }
};

export const fetchActivityWithFrecCap = (key: string) => async (dispatch: any, getState: GetStateMethod) => {
  try {
    const activity = await ActivityService.fetchActivityWithFreqCap(key);
    const activities = getState().activityState.activities ?? {};
    activities[activity.key] = activity;
    await dispatch({
      type: SUCCESS(GET_ACTIVITY_WITH_FREQ_CAP),
      payload: { activities }
    });
  } catch (error) {
    dispatch({
      type: FAILURE(GET_ACTIVITY_WITH_FREQ_CAP),
      payload: error
    });
    console.error(error);
  }
};

export const dismissDailyBonus =
  (dismissed = true) =>
  async (dispatch: any) => {
    await dispatch({
      type: DISMISS_DAILY_BONUS,
      payload: {
        dailyBonusRedemption: {
          dismissed,
          dateDismissed: moment.now()
        }
      }
    });
  };
export const getActivity = async (id: number): Promise<IActivity | undefined> => {
  try {
    return await ActivityService.getActivity(id);
  } catch (e) {
    console.error(e);
  }
};
export const getActivityWithFreqCap = async (id: number): Promise<IActivity | undefined> => {
  try {
    const data = await ActivityService.getActivityWithFreqCap(id);
    // @ts-ignore
    if (data && data.frequencyCap && data.frequencyCap.length > 0) {
      data.frequencyCap = data.frequencyCap[0];
    }
    return data;
  } catch (e) {
    console.error(e);
  }
};
export const getPromoCodeActivity = async (isLoggedIn: boolean) => {
  try {
    return await getActivityByKey(constants.HOME_PROMO_CODE_ACTIVITY_KEY, isLoggedIn);
  } catch (e) {
    console.error(e);
  }
};

export const getActivityByKey = async (activityKey: string, isLoggedIn: boolean): Promise<IActivity | undefined> => {
  try {
    const payload = isLoggedIn
      ? await ActivityService.fetchActivityWithFreqCap(activityKey)
      : await ActivityService.fetchActivity(activityKey);
    // @ts-ignore
    if (payload && payload.frequencyCap && payload.frequencyCap.length > 0) {
      payload.frequencyCap = payload.frequencyCap[0];
    }
    return payload;
  } catch (e) {
    console.error(e);
  }
};

export const credits =
  (key: string, onSuccess: () => void, onError: (error: any) => void) => async (dispatch: any, getState: GetStateMethod) => {
    try {
      const activity = await ActivityService.credit(key);
      const activities = getState().activityState.activities ?? {};
      activities[activity.key] = activity;
      await dispatch({
        type: SUCCESS(CREDIT),
        payload: { activities }
      });
      dispatch(fetchUserPoints());
      onSuccess();
    } catch (error) {
      dispatch({
        type: FAILURE(CREDIT),
        payload: error
      });
      console.error(error);
      onError(error);
    }
  };

export const fetchHomePageActivities = () => async (dispatch: any) => {
  try {
    dispatch({
      type: GET_ACTIVITY_LIST_V2,
      payload: ActivityService.getActivities(false, false, false)
    });
  } catch (error) {
    dispatch({
      type: FAILURE(GET_ACTIVITY_LIST_V2),
      payload: error
    });
    console.error(error);
  }
};

export const fetchHomePageFeaturedActivity = () => async (dispatch: any) => {
  try {
    dispatch({
      type: GET_ACTIVITY_FEATURED,
      payload: ActivityService.getActivities(false, true)
    });
  } catch (error) {
    dispatch({
      type: FAILURE(GET_ACTIVITY_FEATURED),
      payload: error
    });
    console.error(error);
  }
};

export const fetchHomePageBonus = () => async (dispatch: any) => {
  try {
    dispatch({
      type: GET_BONUS_ACTIVITY,
      payload: ActivityService.getActivities(false, false, true)
    });
  } catch (error) {
    dispatch({
      type: FAILURE(GET_BONUS_ACTIVITY),
      payload: error
    });
    console.error(error);
  }
};

export const fetchHomePageUserActivities = () => async (dispatch: any) => {
  try {
    dispatch({
      type: GET_ACTIVITY_LIST_V2,
      payload: ActivityService.getUserActivities(false, false, false)
    });
  } catch (error) {
    dispatch({
      type: FAILURE(GET_ACTIVITY_LIST_V2),
      payload: error
    });
    console.error(error);
  }
};

export const fetchHomePageUserFeaturedActivity = () => async (dispatch: any) => {
  try {
    dispatch({
      type: GET_ACTIVITY_FEATURED,
      payload: ActivityService.getUserActivities(false, true)
    });
  } catch (error) {
    dispatch({
      type: FAILURE(GET_ACTIVITY_FEATURED),
      payload: error
    });
    console.error(error);
  }
};

export const redeemCodeRedemption = async (data: ICodeSubmitRequest): Promise<ICodeSubmitResponse | IErrorDTO | undefined> => {
  try {
    return await CodeRedemptionService.redeem(data);
  } catch (e: any) {
    console.error(e);
    return e?.payload;
  }
};
