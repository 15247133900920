import { GET_TRANSACTION_HISTORY, REDEEM_FROM_POINTS, USER_BALANCE, USER_BALANCE_EXPIRY, USER_TOKENS } from '@pbl/pbl-react-core/lib/models/ledger/types';
import { LedgerService } from '@pbl/pbl-react-core/lib/services';
import { IRequestParams } from '@pbl/pbl-react-core/lib/services/types';
import { FAILURE } from 'redux/action-type.util';

export const fetchUserTokens = () => async (dispatch: any) => {
  await dispatch({
    type: USER_TOKENS,
    payload: LedgerService.getUserTokens()
  }).catch(error => {
    console.error(error);
  });
};

export const getTransactionHistory = (params: IRequestParams) => async (dispatch: any) => {
  try {
    await dispatch({
      type: GET_TRANSACTION_HISTORY,
      payload: LedgerService.getTransactionHistory(params)
    });
  } catch (error) {
    console.error(error);
  }
};

export const redeemFromPoints = (payload: any, successCallback: () => void, failedCallback: (error: string) => void) => async (dispatch: any) => {
  try {
    dispatch({
      type: REDEEM_FROM_POINTS,
      payload: await LedgerService.convertPointsToTokensV2(payload)
    });
    successCallback();
  } catch (error: any) {
    console.error(error);
    failedCallback(error);
    dispatch({
      type: FAILURE(REDEEM_FROM_POINTS),
      payload: error
    });
  }
};

export const fetchUserPoints = () => async (dispatch: any) => {
  await dispatch({
    type: USER_BALANCE,
    payload: LedgerService.getLoyaltyPointsBalance()
  }).catch((error: any) => {
    console.error(error);
  });
};

export const fetchUserPointsWithExpiry = () => async (dispatch: any) => {
  try {
    const balance = await LedgerService.getLoyaltyPointsBalance();
    const pointsExpiring = await LedgerService.getLoyaltyPointsExpiring();
    balance.expiryAmount = pointsExpiring;

    dispatch({
      type: USER_BALANCE_EXPIRY,
      payload: balance
    });
  } catch (error) {
    console.error(error);
  }
};
