import Grid from '@material-ui/core/Grid';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { IErrorDTO, IErrorParams } from '@pbl/pbl-react-core/lib/models/app';
import { IResponse, ISubmitResponse, ISurveyResult } from '@pbl/pbl-react-core/lib/models/survey/pbl/types';
import AppSpinner from '@pbl/pbl-react-web-components/lib/app-spinner/AppSpinner';
import EarnActivityHeader from '@pbl/pbl-react-web-components/lib/earn/earn-activity-header';
import { EarnPointsModal } from '@pbl/pbl-react-web-components/lib/package';
import SurveyContent from '@pbl/pbl-react-web-components/lib/survey/pbl/SurveyContent';
import styles from 'assets/jss/modules/survey/SurveyScreenStyle';
import constants from 'config/constants';
import { useActivityDetailsData } from 'modules/earn/hooks/useActivityDetailsData';
import { useSurveyDetails } from 'modules/survey/hooks/useSurveyDetails';
import React, { useCallback, useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Prompt, useHistory, useLocation } from 'react-router-dom';
import { showMessageBar } from 'redux/reducers/app/actions';
import { fetchUserPoints } from 'redux/reducers/ledger/actions';
import { submit } from 'redux/reducers/survey/action';
import { useIsLoggedIn } from '../../hooks/useIsLoggedIn';
import { useScrollToTop } from '../../hooks/useScrollToTop';

interface IProps extends WithStyles<typeof styles>, WithTranslation {}

const SurveyScreen: React.FunctionComponent<IProps> = ({ classes, t }) => {
  const dispatch = useDispatch();
  const { activityId }: Record<string, string> = useParams();
  const { pathname } = useLocation();
  const { push: navigate } = useHistory();

  const onNavigate = useCallback(() => navigate('/earn', { from: pathname }), [navigate, pathname]);
  const navigateToActivities = useCallback((message?: IErrorParams) => navigate('/earn', { message }), [navigate]);

  const showInvalidError = useCallback(() => {
    navigateToActivities({
      message: t('activities.activityUnavailable'),
      type: 'warning',
      messageTimeout: 3000
    });
  }, [navigateToActivities, t]);

  const { activity } = useActivityDetailsData(activityId, showInvalidError);
  const { survey, playerResponses } = useSurveyDetails(showInvalidError, activity?.key);
  const loggedIn: boolean = useIsLoggedIn();

  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
  const [surveyResult, setSurveyResult] = useState<ISurveyResult>({} as ISurveyResult);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [userResponse, setUserResponse] = useState<ISubmitResponse>({} as ISubmitResponse);

  useEffect(() => {
    setUserResponse({ surveyId: survey?.id ?? 0, responses: playerResponses ?? [] });
  }, [survey, playerResponses]);

  useScrollToTop();

  const postSurvey = async () => {
    if (!loggedIn) {
      navigate('/login', { from: pathname });
      return;
    }
    setLoading(true);
    const responses = userResponse.responses;
    responses.forEach(item => (item.responseText ? (item.options = undefined) : item));
    await submit(userResponse).then(data => validateResponse(data));
    dispatch(fetchUserPoints());
    setLoading(false);
  };

  const validateResponse = async (data: ISurveyResult | IErrorDTO) => {
    if (data && 'errorKey' in data && data.errorKey) {
      dispatch(
        showMessageBar({
          message: data.errorKey,
          type: 'error',
          messageTimeout: 10000
        })
      );
      setErrorMessage(data.errorKey);
    } else if ((data as any)?.detail?.includes('invalidAccess')) {
      dispatch(
        showMessageBar({
          message: t('error.activity.invalidAccess'),
          type: 'error',
          messageTimeout: 10000
        })
      );
    } else if (data && activity) {
      setSurveyResult(data as ISurveyResult);
      setIsFormDirty(false);
      if (constants.INTERESTS_SURVEY.ACTIVITY_KEY === activity.key && 'points' in data && data.points === 0) {
        dispatch(showMessageBar({ message: 'interests.updated' }));
      } else if ('points' in data && data.points === 0) {
        dispatch(showMessageBar({ message: 'survey.submitted' }));
      } else {
        setShowModal(true);
      }
    }
  };

  if (!activity || !survey) {
    return null;
  }

  const onChange = (answer: IResponse) => {
    let currentResponses = userResponse.responses ?? [];
    currentResponses = currentResponses.filter(x => x.questionId !== answer.questionId);
    currentResponses.push(answer);
    setUserResponse({ surveyId: survey.id, responses: currentResponses });
    setIsFormDirty(true);
  };

  const onDismissEarnPointsModal = (): void => {
    navigate('/earn');
  };

  const getContent = () => {
    const bonusPoints = activity.bonusAmount ? activity.bonusAmount + activity.amount : 0;
    return survey ? (
      <Grid item={true} xs={12}>
        {survey ? (
          <SurveyContent
            onSaveSurvey={postSurvey}
            onChange={onChange}
            survey={survey}
            playerResponses={userResponse.responses}
            isLoading={loading}
            isSystemSurvey={constants.INTERESTS_SURVEY.ACTIVITY_KEY === activity?.key}
            bonusPoints={bonusPoints}
            amount={activity.amount}
            limitReached={Boolean(
              constants.INTERESTS_SURVEY.ACTIVITY_KEY !== activity?.key && playerResponses && playerResponses.length > 0
            )}
            handleContactUs={undefined}
            isRestricted={undefined}
            quizResponse={undefined}
            showSummaryAnswers={undefined}
            showSignInWarning={undefined}
            isLoggedIn={undefined}
          />
        ) : null}
      </Grid>
    ) : null;
  };

  return (
    <div>
      {loggedIn && !errorMessage && <Prompt when={isFormDirty} message={t('interests.promptMessage')} />}
      {surveyResult && surveyResult.points ? (
        <EarnPointsModal pointEarned={surveyResult.points} isVisible={showModal} onClose={onDismissEarnPointsModal} />
      ) : null}
      {survey && (
        <div className={classes.container}>
          {loading ? (
            <AppSpinner label={'Loading Data'} />
          ) : (
            <Grid className={classes.container} container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <EarnActivityHeader
                  data={{
                    image: activity.imageUrl,
                    title: activity.title,
                    description: activity.description,
                    points: activity.amount,
                    preview_url: activity.videoUrl ? activity.videoUrl : undefined,
                    isCoverImage: true,
                    extraData: activity?.extraData
                  }}
                  bonusPoints={activity.bonusAmount ? activity.bonusAmount + activity.amount : 0}
                  limitReached={playerResponses && playerResponses.length > 0}
                  navigateTo={onNavigate}
                />
                {getContent()}
              </Grid>
            </Grid>
          )}
        </div>
      )}
    </div>
  );
};

export default withTranslation()(withStyles(styles)(SurveyScreen));
