import { Theme, Typography, WithStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';
import withTheme from '@material-ui/core/styles/withTheme';
import { INGLPlayData } from '@pbl/pbl-react-core/lib/models/arcades/types';
import { GameVendor, IGame } from '@pbl/pbl-react-core/lib/models/games/types';
import { IDigitalRevealTokenDetails } from '@pbl/pbl-react-core/lib/models/ticket/types';
import { AppSpinner, InformationDialog } from '@pbl/pbl-react-web-components/lib/package';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface IArcadeGameProps extends RouteComponentProps {
  game?: IGame;
  gameNGL?: INGLPlayData | null;
  isLoggedIn: boolean;
  launchGame: boolean;
  onCloseGame: () => any;
  gameData: IDigitalRevealTokenDetails | null;
  spinResults?: string;
  gamePoints: number;
  topPrize: number;
  gameType?: string;
  isPBLGame: boolean;
  selectedIndex: number;
  wager: number;
  onGameReplayHandler?: () => any;
  onGameStartHandler?: () => any;
  onGameReadyHandler?: () => any;
  onGameEndHandler?: () => any;
  onGameExitHandler?: () => any;
  onRelaunch?: () => any;
  launchToken?: string;
  selectedNGLGame?: any;
}

interface IArcadeGameState {
  loading: boolean;
  isPortrait: boolean;
}

const INITIAL_STATE: IArcadeGameState = {
  loading: false,
  isPortrait: true
};

interface IProps extends IArcadeGameProps, WithStyles<typeof styles>, WithTranslation {
  theme: Theme;
}

class ArcadeGame extends React.Component<IProps, IArcadeGameState> {
  constructor(props: IProps) {
    super(props);
    this.state = { ...INITIAL_STATE };
    window.addEventListener('orientationchange', this.orientationChange);
  }
  private orientationChange = () => {
    this.setState({ isPortrait: window.screen.orientation.type.includes('portrait') });
  };
  public componentDidMount() {
    if (this.props.isPBLGame) {
      window.addEventListener('message', this.receiveMessagePBL, false);
    } else {
      window.addEventListener('message', this.receiveMessage, false);
    }
  }

  public componentWillUnmount() {
    if (this.props.isPBLGame) {
      window.removeEventListener('message', this.receiveMessagePBL);
    } else {
      window.removeEventListener('message', this.receiveMessage);
    }
  }

  private receiveMessagePBL = async (event: any) => {
    try {
      const data = JSON.parse(event.data);
      console.warn('***** poGame', data);
      if (!data || data.type !== 'poGame') return;
      const gameData = this.getGameData();
      return await this.processGameMessage(data, gameData);
    } catch (e) {
      console.error('Error processing game message', e);
    }
  };

  private receiveMessage = async (event: any) => {
    try {
      const data = typeof event.data === 'string' ? JSON.parse(event.data) : event.data;
      console.warn('***** poGame', data);

      if (!data) {
        return;
      }
      // If the event name comes from data, then process the message
      if (data.name) {
        return await this.processGameMessage(data);
      }

      // handle game relaunch
      if (data.action?.type === 'relaunch' && data.from === 'GAME' && data.to === 'WRAPPER' && this.props.onRelaunch) {

        await this.props.onRelaunch();
        console.warn('***** ngl relaunch');
      }
    } catch (e) {
      console.error('Error processing message', event, e);
    }
  };

  private hideSpinner = () => {
    this.setState({
      loading: false
    });
  };

  private renderIframe = () => {
    const { classes, game, isPBLGame, gameType, selectedIndex, launchToken } = this.props;
    const { isPortrait } = this.state;
    let url = game?.externalLink ? game?.externalLink : '';
    if (!isPBLGame) {
      url = `${game?.externalLink}?playMode=${gameType && gameType === 'fun' ? 'demo' : 'money'}&playLevel=1&betLevel=${selectedIndex + 1}`;
      url = gameType === 'fun' ? url : `${url}&token=${launchToken}`;
    }
    if (!isPBLGame || (isPortrait && isPBLGame)) {
      return (
        <iframe
          src={url}
          id={'arcade-game-container'}
          className={classes.iframe}
          onLoad={this.hideSpinner}
          title={game?.name}
          allow="autoplay"
        />
      );
    } else {
      return (
        <Typography variant={'subtitle1'} color={'error'}>
          {this.props.t('error.interactiveGame.onlyPortraitMode')}
        </Typography>
      );
    }
  };

  private getGameData = () => {
    if (this.props.game?.vendor === GameVendor.PBL) {
      return {
        points: this.props.gamePoints,
        spinResults: this.props.spinResults ? this.props.spinResults : null,
        gameFace: this.props.gameData ? this.props.gameData.face : null,
        gameConfig: this.props.gameData ? this.props.gameData.gameConfig : null,
        type: this.props.gameType ? this.props.gameType : 'prize',
        topPrize: this.props.topPrize
      };
    } else if (this.props.gameNGL) {
      return {
        points: this.props.gameNGL.prizeInfo?.totalPrizeAmount ? this.props.gameNGL.prizeInfo?.totalPrizeAmount : this.props.gameNGL.totalPrizeAmount,
        type: this.props.gameType ? this.props.gameType : 'prize',
        wager: this.props.wager,
        gameFace: this.props.gameNGL.outcome ? this.props.gameNGL.outcome : this.props.gameNGL.playData,
        gameConfig: this.props.selectedNGLGame,
        topPrize: this.props.topPrize
      };
    }
    return undefined;
  };
  private processGameMessage = async (data: any, gameData?: any) => {
    switch (data.name) {
      case 'onGameReplayHandler': {
        if (this.props.onGameReplayHandler) await this.props.onGameReplayHandler();
        if (data.data === null || data.data.reload === false) {
          // @ts-ignore
          document.getElementById('arcade-game-container').contentWindow.postMessage(
            JSON.stringify({
              type: 'poGameParent',
              name: 'setGameConfig',
              data: gameData
            }),
            '*'
          );
        }
        return console.warn('***** onGameReplayHandler');
      }
      case 'onGameStartHandler': {
        if (this.props.onGameStartHandler) this.props.onGameStartHandler();
        return console.warn('***** onGameStartHandler');
      }
      case 'onGameReadyHandler': {
        if (this.props.onGameReadyHandler) this.props.onGameReadyHandler();
        // @ts-ignore
        document.getElementById('arcade-game-container').contentWindow.postMessage(
          JSON.stringify({
            type: 'poGameParent',
            name: 'setGameConfig',
            data: gameData
          }),
          '*'
        );
        return console.warn('***** onGameReadyHandler gameData:', gameData);
      }
      case 'onGameEndHandler': {
        if (this.props.onGameEndHandler) await this.props.onGameEndHandler();
        return console.warn('***** onGameEndHandler');
      }
      case 'onGameExitHandler': {
        if (this.props.onGameExitHandler) await this.props.onGameExitHandler();
        this.props.onCloseGame();
        return console.warn('***** onGameExitHandler');
      }
      default:
        return;
    }
  };

  public render() {
    return (
      <React.Fragment>
        <InformationDialog
          open={this.props.launchGame}
          hideButton={true}
          onClose={this.props.onCloseGame}
          maxWidth={'lg'}
          noSpaces={true}
          gameView={true}
          disableBackdropClick={true}
        >
          {this.state.loading ? <AppSpinner label={'Loading Game...'} /> : null}
          {this.renderIframe()}
        </InformationDialog>
      </React.Fragment>
    );
  }
}

const styles: any = (theme: Theme) =>
  createStyles({
    iframe: {
      height: '100%',
      width: '100%',
      maxWidth: '100%',
      [theme.breakpoints.down('md')]: {
        height: '100%',
        width: '100%',
        maxWidth: '100%',
        margin: 0,
        maxHeight: '95%',
        position: 'absolute'
      }
    }
  });

export default withRouter(withTheme(withTranslation()(withStyles(styles)(ArcadeGame))));
