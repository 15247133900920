import { IReward } from '@pbl/pbl-react-core/lib/models/reward/types';
import { ICornerIconProps } from '@pbl/pbl-react-web-components/lib/card/CornerIcon';
import ListCard from '@pbl/pbl-react-web-components/lib/card/ListCard';
import colors from 'assets/jss/colors';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface IBanner {
  showBanner?: boolean;
  bannerText?: string;
  styleType?: string;
}

export interface IRewardCardProps extends RouteComponentProps {
  reward: IReward;
  onPress?: (event: React.MouseEvent<HTMLElement>) => void;
  banner?: IBanner;
  categoryName?: string;
  preview: string;
}

const RewardCard: React.FunctionComponent<IRewardCardProps> = ({ reward, history, banner, categoryName, preview }) => {
  const cornerIcon: ICornerIconProps = {
    icon: 'icon-rewards',
    iconType: 'custom',
    contentColor:
      banner && banner.styleType && banner.styleType === 'GrayedOut'
        ? colors.themeColors.black.alpha(0.35).toString()
        : colors.themeColors.cornerColors.rewardContrast.hex(),
    trianglePosition: { top: 0, left: 0 },
    triangleType: 'topLeft',
    triangleBackgroundColor:
      banner && banner.showBanner && banner.styleType === 'GrayedOut'
        ? colors.themeColors.white.toString()
        : colors.themeColors.cornerColors.reward.hex(),
    triangleSize: 88
  };
  const navigateToReward = () => {
    history.push({ pathname: `/rewards/${reward.id}`, state: { category_name: categoryName } });
  };

  return (
    <ListCard
      onClick={navigateToReward}
      image={reward.imageUrl}
      imageTitle={reward.title}
      text={reward.title}
      textDescription={preview}
      title={`Redeem for: ${reward.amount.toPointsFormatWithUnit().toUpperCase()}`}
      titleColor={'primary'}
      cornerIcon={cornerIcon}
      showBanner={banner && banner.showBanner}
      bannerText={banner && banner.bannerText ? banner.bannerText : undefined}
      bannerStyleType={banner && banner.styleType ? banner.styleType : undefined}
    />
  );
};

export default withRouter(RewardCard);
