import { IActivity } from '@pbl/pbl-react-core/lib/models/activity/types';
import { ICornerIconProps } from '@pbl/pbl-react-web-components/lib/card/CornerIcon';
import ListCard from '@pbl/pbl-react-web-components/lib/card/ListCard';
import { assets } from '@pbl/pbl-react-web-components/lib/config/assets';
import colors from 'assets/jss/colors';
import React from 'react';

export interface IActivityCardProps {
  activity: IActivity;
  onPress?: (event: React.MouseEvent<HTMLElement>) => void;
  isInformationOnly?: boolean;
  isPlayForFunOnly?: boolean;
}

const ActivityListItem: React.FunctionComponent<IActivityCardProps> = ({
  activity,
  onPress,
  isPlayForFunOnly = false,
  isInformationOnly = false
}) => {
  const { extraData } = activity;
  const cardTitle = extraData ? extraData.find(item => item.key === 'cardTitle') : null;

  const cornerIcon: ICornerIconProps = {
    icon: isInformationOnly ? undefined : 'icon-earn',
    iconType: 'custom',
    contentColor: colors.themeColors.cornerColors.activityContrast.hex(),
    trianglePosition: { top: 0, left: 0 },
    triangleType: 'topLeft',
    triangleBackgroundColor: isInformationOnly ? colors.themeColors.white.hex() : colors.themeColors.cornerColors.activity.hex(),
    triangleSize: 88,
    image: isInformationOnly ? assets.internalAssets.starImg : undefined
  };

  let title = 'EARN POINTS';
  if (isPlayForFunOnly) {
    title = 'EARN: 0 POINTS';
  } else if (activity.amount && activity.amount > 0) {
    title = `EARN: ${activity.amount.toPointsFormatWithUnit().toUpperCase()}`;
  } else if (isInformationOnly) {
    title = `SPECIAL OFFERS!`;
  }

  // Allow customize title
  title = cardTitle ? cardTitle.value : title;

  return (
    <ListCard
      image={activity.imageUrl}
      imageTitle={`${activity.title}`}
      text={activity.title}
      title={title}
      textFooter={activity.description}
      titleColor={'primary'}
      cornerIcon={cornerIcon}
      onClick={onPress}
      bonusPoints={activity.bonusAmount ? activity.bonusAmount + activity.amount : undefined}
      noneBonusPoints={activity.amount}
    />
  );
};

export default ActivityListItem;
