import { Button, Grid, Theme, Typography, WithStyles } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import withTheme from '@material-ui/core/styles/withTheme';
import { IDraw, IDrawDetailState, PromotionType } from '@pbl/pbl-react-core/lib/models/draws/types';
import { IGame } from '@pbl/pbl-react-core/lib/models/games/types';
import { DrawDetail, RelatedGames, RelatedGamesDialog } from '@pbl/pbl-react-web-components/lib/package';
import DetailTitle from '@pbl/pbl-react-web-components/lib/title/DetailTitle';
import colors from 'assets/jss/colors';
import styles from 'assets/jss/modules/draws/DrawDetailScreenStyle';
import clsx from 'clsx';
import constants from 'config/constants';
import _ from 'lodash';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IRootState } from 'redux/reducers';
import { showMessageBar } from 'redux/reducers/app/actions';
import {
  fetchDetailsPageProgressivePrizes,
  fetchDrawEntries,
  fetchDrawPrizes,
  fetchDrawPrizesWithContent,
  fetchDrawPrizesWithWinners,
  fetchDrawsByPromotion,
  fetchDrawWithAdditionalInformation,
  fetchEligibleGames,
  fetchLatestDrawHistory,
  fetchPromotionDetails,
  resetPromotion,
  selectDraw
} from 'redux/reducers/draws/actions';
import ScrollToTopOnMount from 'shared/components/routes/ScrollToTopOnMount';
import { scrollToTheTop } from 'utils/htmlUtil';

interface IMatchParams {
  drawId: string;
}

type PropsConnected = ConnectedProps<typeof connector>;

export interface IDrawDetailScreenProps extends PropsConnected, WithTranslation, WithStyles<typeof styles> {
  theme: Theme;
  drawDetail: IDrawDetailState;
}

interface IDrawDetailScreenState {
  showModal: boolean;
  showError: boolean;
  loading: boolean;
}

type Props = IDrawDetailScreenProps & RouteComponentProps<IMatchParams>;

class DrawDetailScreen extends React.Component<Props, IDrawDetailScreenState> {
  private prizeCheck: any | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      showModal: false,
      showError: false,
      loading: false
    };
  }

  public async componentDidMount() {
    document.title = 'Promotion Details';
    await this.getEntities();
    this.prizeCheck = setInterval(this.updateProgressivePrize, constants.HOME_SCREEN_FETCH_DRAW_INTERVAL);
  }

  public async componentWillUnmount() {
    await this.props.resetPromotion();
    if (!!this.prizeCheck) {
      clearInterval(this.prizeCheck);
    }
    this.prizeCheck = null;
  }

  public updateProgressivePrize = async () => {
    if (!!this.props.selectedPromotion) {
      if (this.props.selectedPromotion.promotionType === PromotionType.PROGRESSIVE) {
        await this.props.fetchDetailsPageProgressivePrizes();
      }
    }
  };

  public async getEntities() {
    const identifier = !!this.props.selectedPromotion.id ? this.props.selectedPromotion.id : this.props.match.params.drawId;
    await this.setState({ loading: true });
    await Promise.all([
      this.props.fetchPromotionDetails(Number(identifier)),
      this.props.fetchDrawsByPromotion(Number(identifier)),
      this.props.fetchEligibleGames(Number(identifier)),
      this.props.fetchDrawWithAdditionalInformation(Number(identifier))
    ]);
    if (this.props.errorMessage) {
      await this.setState({ showError: true });
    } else {
      await this.setState({ showError: false });
    }
    await this.setState({ loading: false });
  }

  public navigateToTicketEntry = () => {
    this.props.history.push('/ticket-entry', { from: this.props.location.pathname });
  };

  public selectGame = async (game: IGame) => {
    scrollToTheTop();
    this.props.history.push(`/games/${game.id}`);
  };

  public render() {
    const { drawDetail, isLoggedIn, classes } = this.props;

    const filteredEligibleGames = drawDetail.eligibleGames.slice(0, constants.NUM_DRAW_ELIGIBLE_GAMES);
    return !_.isEmpty(this.props.selectedPromotion) ? (
      <>
        <ScrollToTopOnMount />
        <Grid className={clsx(classes.container)} container={true} spacing={2}>
          <RelatedGamesDialog
            title={'Eligible Games'}
            relatedGames={drawDetail.eligibleGames}
            selectGame={this.selectGame}
            handleClose={this.onModalClose}
            openDialog={this.state.showModal}
          />
          <DrawDetail
            drawDetail={drawDetail}
            isLoggedIn={isLoggedIn}
            fetchDrawPrizesWithContent={this.retrieveContentForDrawPrizes}
            fetchDrawPrizesWithWinners={this.retrieveWinnersForDrawPrizes}
            renderTitle={this.renderPromotionTitle}
            rulesIcon={'open_in_new'}
            draws={this.props.draws}
            selectDraw={this.selectDraw}
            selectedPromotion={this.props.selectedPromotion}
            onPreferenceChange={undefined}
            optOutPreferences={undefined}
            activeEntries={undefined}
            optOutEntries={undefined}
            superBonus={undefined}
            giveawayDraws={undefined}
            giveawayEntries={undefined}
            spaceBelowWinnersBtn={undefined}
            hideDrawDate={undefined}
            focusVisibility={undefined}
            isGiveaway={undefined}
            selectGiveawayDraw={undefined}
          />
          {this.renderTicketButton()}

          <Grid className={classes.eligibleGames} item={true} container={true} xs={12} spacing={4}>
            {this.renderEligibleGames(filteredEligibleGames)}
          </Grid>
        </Grid>
      </>
    ) : null;
  }

  private renderPromotionTitle = (): React.ReactNode => {
    const { selectedPromotion, classes, history, t } = this.props;
    if (!selectedPromotion) {
      return null;
    }
    const comingSoon = selectedPromotion.isComingSoon();
    const promotionEnded = selectedPromotion.isEnded();
    const endingSoon = selectedPromotion.isEndingSoon();
    const alert = promotionEnded ? 'Promotion Ended' : endingSoon ? 'Ending Soon' : comingSoon ? 'Coming Soon' : null;
    const onNavigate = () => {
      history.push('/promotion');
    };
    return (
      <>
        <DetailTitle
          title={this.props.selectedPromotion.name}
          linkText={'Promotions'}
          buttonText={this.props.selectedPromotion.isActive() ? t('games.enterTicket') : undefined}
          buttonTextTitle={t('games.ticketEntry')}
          navigateTo={onNavigate}
          onPress={this.navigateToTicketEntry}
        />
        <Grid className={classes.subtitle} item={true} xs={12}>
          <Typography variant="body1" style={{ color: colors.themeColors.accessibilityWarningColor.hex() }}>
            {alert}
          </Typography>
        </Grid>
      </>
    );
  };

  private renderTicketButton = () => {
    const { classes, t, selectedPromotion } = this.props;
    const promotionEnded = selectedPromotion.isEnded();

    return (
      <Grid className={classes.enterTicketButton} item={true} xs={12}>
        <Button
          size="large"
          color="primary"
          variant="contained"
          aria-label="Enter Ticket to Get Drawing Entry"
          onClick={this.onEnterTicketClicked}
          disabled={promotionEnded}
        >
          {t('drawDetails.enterTicket')}
        </Button>
      </Grid>
    );
  };

  public onEligibleGameClicked(game: IGame) {
    if (game.isActive) {
      this.props.history.push(`/games/${game.id}`);
    } else {
      this.props.showMessageBar({ message: `game.unavailable`, type: 'warning', messageTimeout: 3000 });
    }
  }

  public onEnterTicketClicked = (): void => {
    this.props.history.push(`/ticket-entry`);
  };

  private onModalOpen = () => {
    this.setState({ showModal: true });
  };
  private onModalClose = () => {
    this.setState({ showModal: false });
  };

  private renderEligibleGames = (filteredEligibleGames: IGame[]) => {
    const { t } = this.props;

    if (!filteredEligibleGames || filteredEligibleGames.length === 0) return null;
    return (
      <>
        <RelatedGames
          relatedGames={filteredEligibleGames}
          // tslint:disable-next-line:jsx-no-bind
          selectGame={this.onEligibleGameClicked.bind(this)}
          showRelatedGames={this.onModalOpen}
          title={t('drawDetails.relatedGamesTitle')}
        />
      </>
    );
  };

  public selectDraw = async (item: IDraw) => {
    if (item) {
      this.props.fetchLatestDrawHistory(item.id);
      this.props.fetchDrawPrizes(item.id);
      this.props.selectDraw(item);
      if (this.props.isLoggedIn) {
        this.props.fetchDrawEntries(item.id);
      }
    }
  };

  private retrieveContentForDrawPrizes = async (draw: IDraw): Promise<void> => {
    if (draw) {
      await this.props.fetchDrawPrizesWithContent(draw.id);
    }
  };

  private retrieveWinnersForDrawPrizes = async (draw: IDraw): Promise<void> => {
    if (draw) {
      await this.props.fetchDrawPrizesWithWinners(draw.id);
    }
  };
}

const mapStateToProps = ({
  drawDetail,
  drawDetail: { loading, errorMessage },
  authentication: { accessToken, account },
  draws: { selectedPromotion, draws }
}: IRootState) => ({
  isLoggedIn: !!accessToken && accessToken.length > 0 && !!account && !!account.email,
  drawDetail,
  loading,
  selectedPromotion,
  draws,
  errorMessage
});

const mapDispatchToProps = {
  fetchDrawWithAdditionalInformation,
  fetchDrawPrizes,
  fetchDrawPrizesWithContent,
  fetchDrawPrizesWithWinners,
  fetchLatestDrawHistory,
  fetchDrawEntries,
  selectDraw,
  fetchPromotionDetails,
  fetchDrawsByPromotion,
  fetchEligibleGames,
  showMessageBar,
  resetPromotion,
  fetchDetailsPageProgressivePrizes
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(withTheme(withTranslation()(withStyles(styles)(DrawDetailScreen)))));
