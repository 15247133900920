import { IFilters } from '@pbl/pbl-react-core/lib/models/filters';
import { TicketEntryHistory } from '@pbl/pbl-react-core/lib/models/history';
import {
  FETCH_DRAWS_BY_IDS,
  GET_TOTAL_PENDING_POINTS,
  RESET_SELECTED_HISTORY_ITEM,
  RESET_STATE,
  RESET_USER_ACTIVITY_HISTORY,
  RESET_USER_TICKETS_ENTRY_HISTORY,
  SECOND_CHANCE_TICKET_GAME_HISTORY,
  SET_SELECTED_HISTORY_ITEM,
  UPDATE_HISTORY_FILTER,
  USER_ACTIVITY_HISTORY_FILTER,
  USER_DRAWS_HISTORY,
  USER_REWARDS_HISTORY,
  USER_TICKETS_ENTRY_HISTORY
} from '@pbl/pbl-react-core/lib/models/history/types';
import { HistoryService, LedgerService, PromotionsService } from '@pbl/pbl-react-core/lib/services';
import constants from 'config/constants';
import { FAILURE, REQUEST, SUCCESS } from 'redux/action-type.util';
import { showMessageBar } from 'redux/reducers/app/actions';
import * as FilterUtils from 'shared/components/filters/filters.actions';
import { updatePaging } from './pagination/actions';

/*
export const fetchUserActivitiesHistory = (pageNumber: number, pageSize: number, reset = false) => async (dispatch: any) => {
  if (reset) {
    dispatch({
      type: RESET_USER_ACTIVITY_HISTORY
    });
  }
  await dispatch({
    type: USER_ACTIVITY_HISTORY,
    payload: HistoryService.getUserActivitiesHistory(pageNumber + 1, pageSize)
  }).catch((error: Error) => {
    console.error(error);
  });
};
*/

export const fetchUserActivitiesHistory =
  (pageNumber: number, pageSize: number, dateStart = '', dateEnd = '', reset = false) =>
  async (dispatch: any) => {
    if (reset) {
      dispatch({
        type: RESET_USER_ACTIVITY_HISTORY
      });
    }

    try {
      dispatch({
        type: REQUEST(USER_ACTIVITY_HISTORY_FILTER)
      });

      const response = await LedgerService.getLoyaltyTransactionHistory(pageNumber, pageSize, dateStart, dateEnd);

      dispatch({
        type: SUCCESS(USER_ACTIVITY_HISTORY_FILTER),
        payload: response
      });
      dispatch(updatePaging('activities', pageNumber, pageSize, response.totalPages));
    } catch (e) {
      dispatch({
        type: FAILURE(USER_ACTIVITY_HISTORY_FILTER),
        payload: e
      });
    }
  };

export const filterUserActivitiesHistory = (
  dateStart: number,
  dateEnd: number,
  pageNumber: number,
  pageSize: number,
  reset = false
) => async (dispatch: any) => {
  if (reset) {
    await dispatch({
      type: RESET_USER_ACTIVITY_HISTORY
    });
  }
  await dispatch({
    type: USER_ACTIVITY_HISTORY_FILTER,
    payload: HistoryService.filterUserActivitiesHistory(dateStart, dateEnd, { pageNumber: pageNumber + 1, pageSize })
  }).catch((error: Error) => {
    console.error(error);
  });
};

export const fetchUserTicketEntriesHistory = (
  {
    page,
    sort,
    dateStart,
    dateEnd
  }: {
    page?: number;
    sort?: string;
    dateStart?: string;
    dateEnd?: string;
  },
  reset = false
) => async (dispatch: any) => {
  if (reset) {
    await dispatch({
      type: RESET_USER_TICKETS_ENTRY_HISTORY
    });
  }
  await dispatch({
    type: USER_TICKETS_ENTRY_HISTORY,
    payload: HistoryService.getUserTicketEntriesHistory(constants.HISTORY_ITEMS_PER_PAGE, page, sort, dateStart, dateEnd)
  }).catch((error: Error) => {
    console.error(error);
  });
};

export const fetchUserRewardsHistoryV2 =
  (pageNumber: number, pageSize: number, dateStart = '', dateEnd = '') =>
  async (dispatch: any) => {
    try {
      dispatch({
        type: REQUEST(USER_REWARDS_HISTORY)
      });

      const response = await LedgerService.getLoyaltyDebitTransactionHistory(pageNumber, pageSize, dateStart, dateEnd);

      dispatch({
        type: SUCCESS(USER_REWARDS_HISTORY),
        payload: {
          content: response.content.map(reward => ({
            dateCreated: reward.date,
            total_points_redeemed: reward.amount,
            reward_title: reward.description,
            extra_data: [...(reward.extraData ?? []), { id: 0, key: 'key', value: (reward as any).data }]
          })),
          page: response.page
        }
      });
      dispatch(updatePaging('rewards', pageNumber, pageSize, response.page.totalPages));
    } catch (e) {
      dispatch({
        type: FAILURE(USER_REWARDS_HISTORY),
        payload: e
      });
    }
  };

// export const fetchUserRewardsHistory = ({ page, dateStart, dateEnd }: { page: number; dateStart?: number; dateEnd?: number }) => async (
  export const fetchUserRewardsHistory = ({ size, page, dateStart, dateEnd }: { size: number , page: number; dateStart?: number; dateEnd?: number }) => async (
dispatch: any
) => {
  await dispatch({
    type: USER_REWARDS_HISTORY,
    payload: HistoryService.getUserRewardsHistoryV2(size, page, dateStart, dateEnd)
  }).catch((error: Error) => {
    console.error(error);
  });
};

export const resetState = () => (dispatch: any) => {
  dispatch({
    type: RESET_STATE
  });
};

export const resetSelectedHistoryItem = () => (dispatch: any) => {
  dispatch({
    type: RESET_SELECTED_HISTORY_ITEM
  });
};

export const setSelectedHistoryItem = (ticket: TicketEntryHistory) => (dispatch: any) => {
  dispatch({
    type: SET_SELECTED_HISTORY_ITEM,
    payload: ticket
  });
};

export const fetchDrawsByIds = (ticket: TicketEntryHistory) => async (dispatch: any) => {
  await dispatch({
    type: FETCH_DRAWS_BY_IDS,
    payload: HistoryService.fetchDrawsByIds(ticket)
  }).catch((error: Error) => {
    console.error(error);
  });
};

export const updateActivityHisToryFilter = (filters: IFilters) => (dispatch: any) => {
  dispatch({
    type: UPDATE_HISTORY_FILTER,
    payload: filters
  });
};

export const updateTicketHistoryFilter = (filters: IFilters) => (dispatch: any) => {
  dispatch({
    type: UPDATE_HISTORY_FILTER,
    payload: filters
  });
};

export const toggleFilter = (entityName: string, sectionKey: string, filterKey: string) => (dispatch: any) => {
  dispatch(FilterUtils.toggleFilter(entityName, sectionKey, filterKey));
};

export const replaceFilers = (filters: IFilters) => (dispatch: any) => {
  dispatch(FilterUtils.replaceFilers('history', filters));
};

export const getPendingPointsForTicketEntries = () => async (dispatch: any) => {
  await dispatch({
    type: GET_TOTAL_PENDING_POINTS,
    payload: HistoryService.getPendingPointsForTicketEntries()
  }).catch((error: Error) => {
    console.error(error);
  });
};

export const fetchUserDrawsHistory = (page: number, usePromotionService = false) => async (dispatch: any) => {
  const methodDrawHistory = usePromotionService ? PromotionsService.fetchSecondChanceDrawHistory : HistoryService.getUserDrawsHistory;
  await dispatch({
    type: USER_DRAWS_HISTORY,
    payload: methodDrawHistory(constants.HISTORY_ITEMS_PER_PAGE, page)
  }).catch((error: Error) => {
    console.error(error);
  });
};

export const fetchSecondChanceTicketHistory = (id: string, usePromotionService = false) => async (dispatch: any) => {
  const methodSecondChanceDrawEntries = usePromotionService ? PromotionsService.fetchSecondChanceDrawEntries : HistoryService.getSecondChanceTicketHistory;
  await dispatch({
    type: SECOND_CHANCE_TICKET_GAME_HISTORY,
    payload: methodSecondChanceDrawEntries(id as never)
  }).catch((error: Error) => {
    dispatch(showMessageBar({ message: error.message, type: 'error' }));
  });
};
