import Grid from '@material-ui/core/Grid';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { IErrorDTO, IErrorParams } from '@pbl/pbl-react-core/lib/models/app';
import { IResponse, ISubmitResponse, ISurveyResult } from '@pbl/pbl-react-core/lib/models/survey/pbl/types';
import AppSpinner from '@pbl/pbl-react-web-components/lib/app-spinner/AppSpinner';
import EarnActivityHeader from '@pbl/pbl-react-web-components/lib/earn/earn-activity-header';
import InfoModal from '@pbl/pbl-react-web-components/lib/earn/InfoModal';
import { Hideable } from '@pbl/pbl-react-web-components/lib/hideable';
import { EarnPointsModal } from '@pbl/pbl-react-web-components/lib/package';
import SurveyContent from '@pbl/pbl-react-web-components/lib/survey/pbl/SurveyContent';
import styles from 'assets/jss/modules/survey/SurveyScreenStyle';
import { useIsLoggedIn } from 'hooks/useIsLoggedIn';
import { useScrollToTop } from 'hooks/useScrollToTop';
import { useActivityDetailsData } from 'modules/earn/hooks/useActivityDetailsData';
import { useSurveyDetails } from 'modules/survey/hooks/useSurveyDetails';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import { showMessageBar } from 'redux/reducers/app/actions';
import { fetchUserPoints } from 'redux/reducers/ledger/actions';
import { submitQuiz } from 'redux/reducers/survey/action';

interface IProps extends WithStyles<typeof styles> {}

const QuizScreen: React.FunctionComponent<IProps> = ({ classes }) => {
  useScrollToTop();

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { activityId }: Record<string, string> = useParams();
  const { pathname } = useLocation();
  const { push: navigate } = useHistory();

  const onNavigate = useCallback(() => navigate('/earn', { from: pathname }), [navigate, pathname]);
  const navigateToActivities = useCallback((message?: IErrorParams) => navigate('/earn', { message }), [navigate]);

  const showUnavailableActivityError = useCallback(() => {
    navigateToActivities({
      message: `Something went wrong. Try again!`,
      type: 'warning',
      messageTimeout: 3000
    });
  }, [navigateToActivities, t]);

  const { activity } = useActivityDetailsData(activityId, showUnavailableActivityError);
  const { survey: quiz, playerResponses, fetchData: refreshQuizDetails } = useSurveyDetails(showUnavailableActivityError, activity?.key);
  const isLoggedIn = useIsLoggedIn();

  const [loading, setLoading] = useState<boolean>(false);
  const [quizResult, setQuizResult] = useState<ISurveyResult>({} as ISurveyResult);
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [userResponse, setUserResponse] = useState<ISubmitResponse>({} as ISubmitResponse);

  const limitReached = useMemo(() => Boolean(playerResponses?.length && !quiz?.updateResponses), [playerResponses, quiz]);
  const bonusPoints = useMemo(() => (activity?.bonusAmount ? activity.bonusAmount + activity.amount : 0), [activity]);

  useEffect(() => {
    setUserResponse({
      surveyId: quiz?.id ?? 0,
      responses: playerResponses ? playerResponses : userResponse.responses ? userResponse.responses : []
    });
  }, [quiz, playerResponses]);

  const postQuiz = async () => {
    if (!isLoggedIn) {
      navigate('/login', { from: pathname });
      return;
    }
    setLoading(true);
    const responses = userResponse.responses;
    responses.forEach(item => (item.responseText ? (item.options = undefined) : item));
    const submitResponse = await submitQuiz(userResponse);
    validateResponse(submitResponse);

    if (quiz?.quizConfig !== 'COMPLETE_ALL_CORRECT') refreshQuizDetails();
    dispatch(fetchUserPoints());
    setLoading(false);
  };

  const validateResponse = async (data: ISurveyResult | IErrorDTO) => {
    if (data && 'errorKey' in data && data.errorKey) {
      dispatch(
        showMessageBar({
          message: data.errorKey,
          type: 'error',
          messageTimeout: 10000
        })
      );
    } else if ((data as any)?.detail?.includes('invalidAccess')) {
      dispatch(
        showMessageBar({
          message: t('error.activity.invalidAccess'),
          type: 'error',
          messageTimeout: 10000
        })
      );
    } else if (data && activity) {
      setQuizResult(data as ISurveyResult);

      if (data && 'message' in data && data.message && /^error:.*/i.test(data.message)) {
        setShowErrorModal(true);
        return;
      }

      if ('points' in data && data.points === 0) {
        dispatch(showMessageBar({ message: 'quiz.submitted' }));
      } else {
        setShowSuccessModal(true);
        refreshQuizDetails();
      }
    }
  };

  if (!activity || !quiz) {
    return null;
  }

  const onChange = (answer: IResponse) => {
    let currentResponses = userResponse.responses ?? [];
    currentResponses = currentResponses.filter(x => x.questionId !== answer.questionId);
    currentResponses.push(answer);
    setUserResponse({ surveyId: quiz.id, responses: currentResponses });
  };

  const dismissSuccessModal = (): void => {
    setShowSuccessModal(false);
    setUserResponse({} as ISubmitResponse);
  };

  const dismissErrorModal = (): void => {
    setShowErrorModal(false);
  };

  if (loading) {
    return <AppSpinner label={'Loading Data'} />;
  }

  return (
    <div className={classes.container}>
      <Hideable show={Boolean(quizResult && quizResult.points && showSuccessModal)}>
        <EarnPointsModal pointEarned={quizResult.points} isVisible={true} onClose={dismissSuccessModal} message={quiz.message} />
      </Hideable>
      <Hideable show={showErrorModal}>
        <InfoModal
          isVisible={true}
          heading={t('quiz.infoErrorModal.heading')}
          message={t('quiz.infoErrorModal.message')}
          buttonText={
            quiz?.quizConfig === 'COMPLETE_ALL_CORRECT' ? t('quiz.infoErrorModal.buttonTextRetry') : t('quiz.infoErrorModal.buttonText')
          }
          onClose={dismissErrorModal}
        />
      </Hideable>

      <Grid className={classes.container} container={true} spacing={2}>
        <Grid item={true} xs={12}>
          <EarnActivityHeader
            data={{
              image: activity.imageUrl,
              title: activity.title,
              description: activity.description,
              points: activity.amount,
              preview_url: activity.videoUrl ? activity.videoUrl : undefined,
              isCoverImage: true,
              extraData: activity?.extraData
            }}
            bonusPoints={bonusPoints}
            limitReached={limitReached}
            navigateTo={onNavigate}
          />
          <Grid item={true} xs={12}>
            <SurveyContent
              onSaveSurvey={postQuiz}
              onChange={onChange}
              survey={quiz}
              playerResponses={userResponse.responses}
              isLoading={loading}
              isSystemSurvey={false}
              bonusPoints={bonusPoints}
              amount={activity.amount}
              limitReached={limitReached}
              quizResponse={quizResult.quizResponse}
              showSummaryAnswers={quiz.showSummaryAnswers}
              handleContactUs={undefined}
              isRestricted={undefined}
              showSignInWarning={undefined}
              isLoggedIn={undefined}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(QuizScreen);
