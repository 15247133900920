import { IFieldUpdateData } from '@pbl/pbl-react-core/lib/models/authentication';
import { GET_FASTPLAY_GAMES_BY_IDS } from '@pbl/pbl-react-core/lib/models/fast-play-ids/types';
import {
  CLEAR_ERROR,
  CLEAR_PRIZES,
  ENTER_TICKET,
  FETCH_DRAWS_BY_IDS,
  RESET,
  UPDATE_ATTR
} from '@pbl/pbl-react-core/lib/models/ticket/types';
import { GamesService, PromotionsService } from '@pbl/pbl-react-core/lib/services';
import { FAILURE, REQUEST, SUCCESS } from 'redux/action-type.util';
import { clearMessages } from 'redux/reducers/app/actions';
import { DispatchMethod, GetStateMethod } from '..';

export const enterTicket = (ticketNumber?: string, validationCode?: string, fastPlayIds?: string[]) => async (dispatch: any) => {
  dispatch({
    type: REQUEST(ENTER_TICKET)
  });
  try {
    const gameId = ticketNumber?.substring(0, 3);
    let validationCodeToPass;
    if (fastPlayIds?.some(id => id === gameId) && validationCode && validationCode.length > 0) {
      const firstDigit = validationCode[0];
      const restOfString = validationCode?.slice(1);
      validationCodeToPass = restOfString?.slice(0, 6) + firstDigit + restOfString?.slice(6);
    } else {
      validationCodeToPass = validationCode;
    }
    const ticketSubmissionDTO = await GamesService.enterTicketV2(ticketNumber as string, validationCodeToPass as string);
    const game = await GamesService.getPublishedGamesByGameIds(ticketSubmissionDTO.gameNumber);

    dispatch({
      type: SUCCESS(ENTER_TICKET),
      payload: { graphicImageUrl: ticketSubmissionDTO.graphicImageUrl, prizes: ticketSubmissionDTO.prizes, gameName: game[0]?.name }
    });
  } catch (e: any) {
    dispatch({
      type: FAILURE(ENTER_TICKET),
      payload: e.payload ? e.payload : e
    });
  }
};

export const fetchGameIdsByGameType = (gameTypeId: number) => async (dispatch: any) => {
  await dispatch({
    type: GET_FASTPLAY_GAMES_BY_IDS,
    payload: GamesService.fetchGameIdsByGameType(gameTypeId)
  });
};

export const clearErrors = () => (dispatch: any) => {
  dispatch(clearMessages());
  dispatch({
    type: CLEAR_ERROR
  });
};

export const clearPrizes = () => (dispatch: any) => {
  dispatch({
    type: CLEAR_PRIZES
  });
};

export const reset = () => (dispatch: any) => {
  dispatch({
    type: RESET
  });
};

export const updateFieldData = (payload: IFieldUpdateData) => (dispatch: DispatchMethod) => {
  dispatch({
    type: UPDATE_ATTR,
    payload
  });
};

export const fetchDrawsByIds = () => async (dispatch: any, getState: GetStateMethod) => {
  const {
    ticket: { prizes }
  } = getState();
  if (prizes && prizes.length > 0) {
    try {
      const draws = await GamesService.fetchDrawsByIdsForTicket(prizes);
      const promotionId = draws[0].promotionId;
      let promotionName;
      if (promotionId) {
        const promotion = await PromotionsService.fetchPromotionDetails(promotionId);
        promotionName = promotion.name;
      }

      await dispatch({
        type: SUCCESS(FETCH_DRAWS_BY_IDS),
        payload: { prizes: draws, promotionName }
      });
    } catch (error) {
      console.error(error);
    }
  }
};
