import { IErrorDTO, IErrorParams } from '@pbl/pbl-react-core/lib/models/app';
import { ContentType, IContent } from '@pbl/pbl-react-core/lib/models/upload/types';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { showMessageBar } from 'redux/reducers/app/actions';
import { getContentConfig } from 'redux/reducers/upload/actions';
import { useActivityDetailsData } from '../hooks/useActivityDetailsData';
import UploadPhotoScreenv2 from './upload-photo-screen';

const UploadContentScreen: React.FunctionComponent = () => {
  const { activityId }: Record<string, string> = useParams();
  const { push: navigate } = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [content, setContent] = useState<IContent | null>(null);
  const navigateToActivities = useCallback((message?: IErrorParams) => navigate('/earn', { message }), [navigate]);

  const showInvalidError = useCallback(() => {
    navigateToActivities({
      message: t('activities.activityUnavailable'),
      type: 'warning',
      messageTimeout: 3000
    });
  }, [navigateToActivities, t]);

  const { activity } = useActivityDetailsData(activityId, showInvalidError);

  useEffect(() => {
    if (activityId.length > 0 && activity) {
      getUploadConfig(activity?.key);
    }
  }, [activity, activityId.length]);

  const getUploadConfig = async activityKey => {
    if (!!activityKey) {
      await getContentConfig(activityKey).then(data => validateResponse(data));
    }
  };

  const validateResponse = async (data: IContent | IErrorDTO) => {
    if (data && 'errorKey' in data && data.errorKey) {
      dispatch(
        showMessageBar({
          message: data.errorKey,
          type: 'error',
          messageTimeout: 10000
        })
      );
    } else if (data) {
      setContent(data as IContent);
    }
  };

  const UploadPhotoScreen = <UploadPhotoScreenv2 content={content as IContent} />;

  switch (content?.contentType) {
    case ContentType.IMAGE:
      return UploadPhotoScreen;
    // TODO:: Handling for UploadVideo & UploadDoc
    // case ContentType.DOCUMENT:
    //   return UploadDocumentScreen;
    // case ContentType.VIDEO:
    //   return UploadVideoScreen;
    default:
      return null;
  }
};

export default UploadContentScreen;
