import { AppBar, Hidden, Link, Toolbar } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { IUserBalance } from '@pbl/pbl-react-core/lib/models/ledger/types';
import logo from 'assets/img/az_players_club_logo.svg';
import styles from 'assets/jss/shared/components/menu/AppBarStyle';
import color from 'color';
import mainMenu from 'config/menu';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import UserPointBalance from '../../../shared/components/menu/UserPointBalance';
import MessageBar from '../../../shared/components/message-bar/MessageBar';
import getTheme from '../../../shared/theme/custom';
import MainMenu from './MainMenu';
import PopupMenu from './PopupMenu';

interface IPrimaryAppBarProps {
  isAuthenticated: boolean;
  checkLogin?: any;
  userPoints: number;
  userTokens: IUserBalance;
  userFullName?: string;
  onLogout: () => any;
  showMessage: boolean;
  loadingFlags: boolean;
  loadingPoints: boolean;
  authorizedFlags: string[];
  storeItems: number;
}

interface IProps extends IPrimaryAppBarProps, WithStyles<typeof styles>, RouteComponentProps {}

const PrimaryAppBar: React.FunctionComponent<IProps> = ({
  classes,
  isAuthenticated,
  location,
  history,
  userPoints,
  userTokens,
  userFullName,
  onLogout,
  showMessage,
  authorizedFlags,
  loadingFlags,
  loadingPoints,
  storeItems
}) => {
  const menu = {
    profile: mainMenu.profile.map(x => {
      if (x.key === 'profile') {
        x.title = userFullName || '';
      } else if (x.key === 'sign-out') {
        x.onClick = onLogout;
      }

      x.isActive = x.route === location.pathname;
      return x;
    }),
    sideMenu: mainMenu.sideMenu.map(x => {
      x.isActive = x.route === location.pathname;
      return x;
    }),
    mobileSideMenu: mainMenu.mobileSideMenu.map(x => {
      x.isActive = !!x.route && location.pathname.includes(x.route);
      return x;
    }),
    mainMenu: mainMenu.mainMenu.map(x => {
      x.isActive = !!x.route && location.pathname.includes(x.route);
      return x;
    })
  };
  const renderMenuItems = (): React.ReactNode => {
    return (
      <MainMenu isAuthenticated={isAuthenticated} mainMenu={menu.mainMenu} authorizedFlags={authorizedFlags} storeItems={storeItems} />
    );
  };

  const renderSideMenuItems = (): React.ReactNode => {
    return (
      <React.Fragment>
        <Hidden smDown={true}>
          <PopupMenu
            title="Side Menu"
            icon="icon-menu"
            menuName="side-menu"
            isAuthenticated={isAuthenticated}
            popupMenu={menu.sideMenu}
            authorizedFlags={authorizedFlags}
          />
        </Hidden>
        <Hidden mdUp={true}>
          <PopupMenu
            authorizedFlags={authorizedFlags}
            title="Side Menu"
            icon="icon-menu"
            menuName="side-menu"
            isAuthenticated={isAuthenticated}
            popupMenu={menu.mobileSideMenu}
          />
        </Hidden>
      </React.Fragment>
    );
  };

  const navigateToHome = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    history.push('/');
  };

  const renderProfileMenuItems = (): React.ReactNode => {
    return (
      <PopupMenu
        title="Profile Menu"
        icon="icon-user"
        menuName="profile-menu"
        isAuthenticated={isAuthenticated}
        popupMenu={menu.profile}
        authorizedFlags={authorizedFlags}
      />
    );
  };
  const isLuckyLounge = window.location.pathname.includes('lucky-lounge');
  const theme = isLuckyLounge ? getTheme('sweepstakesTheme') : getTheme();
  const backgroundColor = isLuckyLounge ? theme.palette.primary.dark : theme.palette.primary.main;
  const profileBackground = isAuthenticated ? 'unset' : isLuckyLounge ? color(backgroundColor).darken(0.4).rgb().string() : '#063a61';
  const showTokens = !!authorizedFlags && authorizedFlags.includes('SWEEPSTAKES_V1');
  return (
    <div className={classes.root}>
      <AppBar role="navigation" position="sticky" style={{ background: backgroundColor }}>
        <a role="main" className={classes.skipLink} href="#main" aria-label="Skip to main content">
          Skip to main content
        </a>
        <Toolbar className={classes.toolBar}>
          {renderSideMenuItems()}

          <Link href="/" onClick={navigateToHome} title="Go to HOME Page" aria-label="Go to HOME Page" component={'button'}>
            <img alt={'AZ Lottery and Players Club Logo'} className={classes.logo} src={logo} />
          </Link>
          <div className={classes.grow} />
          {loadingFlags ? null : renderMenuItems()}

          <div className={classes.sectionDesktop} style={{ backgroundColor: profileBackground }}>
            {renderProfileMenuItems()}
          </div>
          <div className={classes.sectionMobile}>{renderProfileMenuItems()}</div>
        </Toolbar>
      </AppBar>
      {isAuthenticated && !location.pathname.includes('lucky-lounge') ? (
        <UserPointBalance
          onClickForYou={''}
          points={userPoints}
          isHomeScreen={location.pathname === '/'}
          showTokens={showTokens}
          tokens={userTokens?.balance}
          loadingPoints={loadingPoints}
        />
      ) : null}
      {showMessage ? <MessageBar /> : null}
    </div>
  );
};
export default withStyles(styles)(withRouter(PrimaryAppBar));
