import * as yup from 'yup';

export const loginEmailSchema = yup
  .string()
  .trim()
  .nullable()
  .notRequired()
  .test('is-valid-if-not-empty', 'Invalid email format', value => {
    if (!value) return true;
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
  });

export const loginPasswordSchema = yup
  .string()
  .notRequired()
  .test('is-valid-if-not-empty', 'Password must be at least 1 character', value => {
    if (!value) return true;
    return value.length >= 1;
  });
export const loginRememberMeSchema = yup.boolean().notRequired();

export const loginSchema = yup.object().shape({
  email: loginEmailSchema,
  password: loginPasswordSchema,
  rememberMe: loginRememberMeSchema
});
