import { Theme, WithStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import withTheme from '@material-ui/core/styles/withTheme';
import { IActivity } from '@pbl/pbl-react-core/lib/models/activity/types';
import AppSpinner from '@pbl/pbl-react-web-components/lib/app-spinner/AppSpinner';
import EarnActivityHeader from '@pbl/pbl-react-web-components/lib/earn/earn-activity-header';
import { EarnPointsModal } from '@pbl/pbl-react-web-components/lib/package';
import noImage from 'assets/img/no-image.svg';
import styles from 'assets/jss/modules/earn/EarnScreenStyle';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IRootState } from 'redux/reducers';
import { credits, fetchActivities, fetchUserActivities } from 'redux/reducers/activity/actions';
import { showMessageBar } from 'redux/reducers/app/actions';
import { isAuthenticated } from 'redux/reducers/authentication/actions';
import { creditUserPoints, resetActivityCompleted } from 'redux/reducers/loyalty/actions';
import { scrollToTheTop } from 'utils/htmlUtil';

interface IMatchParams {
  activityId: string;
}

export interface IEmailSignUpScreenProps extends PropsConnected, RouteComponentProps<IMatchParams>, WithStyles<typeof styles> {
  theme: Theme;
}

type PropsConnected = ConnectedProps<typeof connector>;

const EmailSignUpScreen: React.FC<IEmailSignUpScreenProps> = ({
  classes,
  match,
  history,
  location,
  loggedIn,
  loyalty
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState<IActivity | null>(null);
  const [activityComplete, setActivityCompleted] = React.useState(false);

  const { maximumLimitReached } = loyalty;
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = 'Email Sign Up Activity';
    scrollToTheTop();
    const { params } = match;

    if (params.activityId.trim().length > 0) {
      getData(params.activityId);
    } else {
      showInvalidActivityError();
    }
  }, []);

  const getData = async (activityId: string) => {
    setLoading(true);

    try {
      const activities = loggedIn ? await fetchUserActivities(false) : await fetchActivities(false);
      const activity = activities?.find(a => a.id.toString() === activityId);

      if (activity) {
        setSelectedActivity(activity);
      } else {
        await showInvalidActivityError();
      }
    } catch (error) {
      await showInvalidActivityError();
    } finally {
      setLoading(false);
    }
  };

  const showInvalidActivityError = async () => {
    await history.push('/earn', {
      message: {
        message: 'This activity is not available.',
        type: 'error',
        messageTimeout: 10000
      }
    });
  };

  const onDismissEarnPointsModal = (): void => {
    resetActivityCompleted();
    setActivityCompleted(false);
    history.push('/earn');
  };

  const goToEmailNotification = async () => {
    await isAuthenticated();
    if (!selectedActivity) return;
    if (!loggedIn) {
      history.push('/login', { from: location });
      return;
    }

    dispatch(
      credits(
        selectedActivity.key,
        () => {
          setActivityCompleted(true);
          history.push('/settings/preferences', { from: location });
        },
        error => {
          history.push('/settings/preferences', { from: location });
          dispatch(
            showMessageBar({
              message: error?.payload?.detail?.includes('invalidAccess')
                ? 'Invalid access to activity'
                : error?.payload?.errorKey || 'Failed to complete activity',
              type: 'error'
            })
          );
        }
      )
    );
  };

  const navigate = () => {
    history.push('/earn');
  };

  if (!selectedActivity) {
    return null;
  }

  return (
    <div>
      {loading && <AppSpinner label={'Loading...'} />}
      {activityComplete && (
        <EarnPointsModal
          pointEarned={selectedActivity.bonusAmount ?? 0}
          isVisible={activityComplete}
          onClose={onDismissEarnPointsModal}
          campaignTitle={selectedActivity.title}
        />
      )}
      <Grid className={classes.container} container={true} spacing={2}>
        <Grid item={true} xs={12}>
          <EarnActivityHeader
            data={{
              image: selectedActivity.imageUrl || noImage,
              title: selectedActivity.title,
              description: selectedActivity.description,
              points: selectedActivity.amount,
              preview_url: selectedActivity.videoUrl,
              isCoverImage: true
            }}
            buttonText={'View'}
            bonusPoints={selectedActivity.bonusAmount ?? 0}
            limitReached={maximumLimitReached}
            navigateTo={navigate}
            onPress={goToEmailNotification}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = ({ authentication: { account, accessToken }, loyalty }: IRootState) => ({
  loyalty,
  loggedIn: !!accessToken && accessToken.length > 0 && !!account && !!account.email
});

const mapDispatchToProps = {
  resetActivityCompleted,
  creditUserPoints,
  isAuthenticated,
  credits
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(withTheme(withTranslation()(withStyles(styles)(EmailSignUpScreen)))));
