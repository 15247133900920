import { GET_RECOMMENDATIONS, RecommendationsType, RESET_STATE } from '@pbl/pbl-react-core/lib/models/recommendations/types';
import { RewardType } from '@pbl/pbl-react-core/lib/models/reward/types';
import { ActivityService, CodeService, RewardService } from '@pbl/pbl-react-core/lib/services';
import { RecommendationsService } from '@pbl/pbl-react-core/lib/services/recommendations-service';
import { nowUtc } from '@pbl/pbl-react-core/lib/utils/TimeUtil';
import { FAILURE, REQUEST, SUCCESS } from 'redux/action-type.util';

export const fetchRecommendations = (filter?: string[]) => async (dispatch: any) => {
  let filterParams: string[] = [];
  if (filter && filter.length > 0) {
    filterParams = filter;
  } else {
    filterParams.push('publishedState.equals=PUBLISHED');
    filterParams.push('tag.equals=WEB_FEATURED');
    filterParams.push(`start.lessThanOrEqual=${nowUtc().toISOString()}`);
  }
  try {
    dispatch({
      type: REQUEST(GET_RECOMMENDATIONS)
    });
    const payload = await RecommendationsService.getRecommendations(filterParams);
    const recommendationsList = await getRecommendations(payload);
    dispatch({
      type: SUCCESS(GET_RECOMMENDATIONS),
      payload: recommendationsList
    });
  } catch (error) {
    dispatch({
      type: FAILURE(GET_RECOMMENDATIONS),
      payload: error
    });
    console.error(error);
  }
};

const getRecommendations = async payload => {
  const recommendationsList: any = [];
  try {
    if (payload && payload.length > 0) {
      await Promise.all(
        payload.map(async p => {
          if (!!p.end && p.end < nowUtc().toISOString()) {
            return;
          }
          if (p.redirectUrl) {
            const redirectUrlParts = p.redirectUrl.split('/');
            const id = redirectUrlParts.length > 1 ? redirectUrlParts[redirectUrlParts.length - 1] : '';
            if (id.length > 0) {
              if (p.recommendationType === RecommendationsType.ACTIVITY) {
                const activity = await ActivityService.getActivityWithFreqCap(parseInt(id, 10));
                if (!!activity && !!activity.frequencyCap && !!activity.frequencyCap.limitReached) {
                  return;
                }
              }
              if (p.recommendationType === RecommendationsType.REWARD) {
                const reward = await RewardService.getRewardWithFrequencyCap(id);
                if (!!reward && reward.type === RewardType.COUPON) {
                  const coupon = await CodeService.getDetails(reward.key);
                  if (!!coupon && coupon.remaining === 0) {
                    return;
                  }
                }
              }
            }
            recommendationsList.push(p);
          }
        })
      );
    }
  } catch (error) {
    console.error(error);
  }
  return recommendationsList;
};

export const reset = () => ({
  type: RESET_STATE
});
