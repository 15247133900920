import constants from 'config/constants';

const TIMEOUT = 60 * 1000;

const apiNGLConfiguration = {
  baseURL: constants.NGL_BASE_URL,
  timeout: TIMEOUT,
  withCredentials: true
};

const apiNGLConfigurationv2 = {
  baseURL: constants.NGL_v2_BASE_URL,
  timeout: TIMEOUT,
  withCredentials: true
};

const addNGLRequestTransform = (request: any) => {
  request.headers['X-CSRFToken'] = 'nocheck';
};

const addNGL2ResponseTransform = (response: any) => {
  response.data = {
    ...response.data,
    headers: response.headers
  };
};

export { apiNGLConfiguration, apiNGLConfigurationv2, addNGLRequestTransform, addNGL2ResponseTransform };
