import { Theme, WithStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import withTheme from '@material-ui/core/styles/withTheme';
import { IActivity } from '@pbl/pbl-react-core/lib/models/activity/types';
import AppSpinner from '@pbl/pbl-react-web-components/lib/app-spinner/AppSpinner';
import EarnActivityHeader from '@pbl/pbl-react-web-components/lib/earn/earn-activity-header';
import { EarnPointsModal } from '@pbl/pbl-react-web-components/lib/package';
import styles from 'assets/jss/modules/earn/EarnScreenStyle';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IRootState } from 'redux/reducers';
import { credits, fetchActivities, fetchUserActivities, getActivityByKey } from 'redux/reducers/activity/actions';
import { showMessageBar } from 'redux/reducers/app/actions';
import { isAuthenticated } from 'redux/reducers/authentication/actions';
import { setIsFormDirty } from 'redux/reducers/forms-metadata/actions';
import {
  creditUserPoints,
  fetchUserPoints,
  resetActivityCompleted,
  resetSelectedActivity,
  setActivityCompleted,
  setSelectedUserActivity
} from 'redux/reducers/loyalty/actions';
import { scrollToTheTop } from 'utils/htmlUtil';

interface IMatchParams {
  activityId: string;
}
export interface IProfileCompletionScreenProps extends PropsConnected, RouteComponentProps<IMatchParams>, WithStyles<typeof styles> {
  theme: Theme;
}

interface IProfileCompletionScreenState {
  loading: boolean;
  selectedActivity: IActivity | null;
}

type PropsConnected = ConnectedProps<typeof connector>;

class ProfileCompletionScreen extends React.Component<IProfileCompletionScreenProps, IProfileCompletionScreenState> {
  constructor(props: IProfileCompletionScreenProps) {
    super(props);
    this.state = {
      loading: false,
      selectedActivity: null
    };
  }

  public componentWillUnmount() {
    this.props.resetSelectedActivity();
    this.props.setIsFormDirty(false);
  }

  public async componentDidMount() {
    document.title = 'Profile Completion Activity';
    scrollToTheTop();
    const {
      match: { params }
    } = this.props;
    if (params.activityId.trim().length > 0) {
      await this.getData();
    } else {
      await this.showInvalidActivityError();
    }
  }

  public async getData() {
    const {
      match: {
        params: { activityId }
      }
    } = this.props;

    let data: IActivity[] | undefined;
    const list = this.props.loggedIn ? await fetchUserActivities(false) : await fetchActivities(false);
    data = list;
    const selectedActivity = (data || []).find(activity => activity.id.toString() === activityId);
    console.warn('selectedActivity', selectedActivity);

    if (selectedActivity) {
      this.setState((prevState: IProfileCompletionScreenState) => ({
        ...prevState,
        selectedActivity,
        loading: false
      }));
    } else {
      this.setState((prevState: IProfileCompletionScreenState) => ({
        ...prevState,
        loading: false
      }));
      await this.showInvalidActivityError();
    }
  }

  private showInvalidActivityError = async () => {
    const { history } = this.props;
    await history.push('/earn', {
      message: {
        message: `This activity is not available.`,
        type: 'error',
        messageTimeout: 10000
      }
    });
  };

  private onDismissEarnPointsModal = (): void => {
    this.props.resetActivityCompleted();
    this.props.history.push('/earn');
  };

  private goToProfileScreen = async () => {
    await this.props.isAuthenticated();
    if (!this.props.loggedIn) {
      this.props.history.push('/login', { from: this.props.location });
      return;
    }

    this.props.credits(
      this.state.selectedActivity?.key ?? '',
      () => {
        this.props.history.push('/settings/profile', { from: this.props.location });
      },
      error => {
        this.props.history.push('/settings/profile', { from: this.props.location });
        this.props.showMessageBar({
          message: error?.payload?.detail?.includes('invalidAccess')
            ? 'Invalid access to activity'
            : error?.payload?.errorKey || 'Failed to complete activity',
          type: 'error'
        });
      }
    );
  };

  public render() {
    const { classes } = this.props;
    const { loading } = this.state;
    const { activityCompleted, maximumLimitReached } = this.props.loyalty;
    const { selectedActivity } = this.state;

    if (!selectedActivity) {
      return null;
    }

    const navigate = () => {
      this.props.history.push('/earn');
    };

    return (
      <div>
        {loading && <AppSpinner label={'Loading...'} />}
        {activityCompleted !== null ? (
          <EarnPointsModal
            pointEarned={!!selectedActivity ? selectedActivity.bonusAmount ?? 0 : activityCompleted.pointsEarned}
            isVisible={activityCompleted !== null}
            onClose={this.onDismissEarnPointsModal}
            campaignTitle={undefined}
          />
        ) : null}
        <Grid className={classes.container} container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <EarnActivityHeader
              data={{
                image: selectedActivity.imageUrl,
                title: selectedActivity.title,
                description: selectedActivity.description,
                points: selectedActivity?.amount,
                preview_url: selectedActivity?.videoUrl ? selectedActivity.videoUrl : undefined,
                isCoverImage: true
              }}
              buttonText={'View'}
              bonusPoints={!!selectedActivity ? selectedActivity.bonusAmount : 0}
              limitReached={maximumLimitReached}
              navigateTo={navigate}
              onPress={this.goToProfileScreen}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = ({ authentication: { account, accessToken }, loyalty }: IRootState) => {
  const loggedIn: boolean = !!accessToken && accessToken.length > 0 && !!account && !!account.email;
  return {
    loyalty,
    loggedIn
  };
};

const mapDispatchToProps = {
  setSelectedUserActivity,
  setActivityCompleted,
  resetActivityCompleted,
  creditUserPoints,
  fetchUserPoints,
  resetSelectedActivity,
  isAuthenticated,
  fetchActivities,
  fetchUserActivities,
  getActivityByKey,
  setIsFormDirty,
  showMessageBar,
  credits
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default withRouter(connector(withStyles(styles)(withTheme(ProfileCompletionScreen))));
