import { ERROR_RESET, FETCH_POLL_DETAILS, RESET, SUBMIT_POLL } from '@pbl/pbl-react-core/lib/models/poll/types';
import { LoyaltyService } from '@pbl/pbl-react-core/lib/services';
import { clearMessages, showMessageBar } from '../app/actions';
import { DispatchMethod } from '../index';

export const fetchPoll = (activityId: string) => async (dispatch: any) => {
  resetError();
  try {
    dispatch(clearMessages());

    await dispatch({
      type: FETCH_POLL_DETAILS,
      payload: LoyaltyService.getPoll(activityId)
    });
  } catch (e: any) {
    dispatch(showMessageBar({ message: e.payload.error, type: 'error' }));
  }
};

export const submitPoll = (pollResponse: number, activityId: string) => async (dispatch: any) => {
  resetError();
  try {
    dispatch(clearMessages());
    await dispatch({
      type: SUBMIT_POLL,
      payload: LoyaltyService.submitPoll(pollResponse, activityId)
    });
  } catch (exception: any) {
    dispatch(showMessageBar({ message: exception.payload.title, type: 'error' }));
  }
};

export const resetError = () => (dispatch: DispatchMethod) => {
  dispatch({
    type: ERROR_RESET,
    payload: null
  });
};

export const reset = () => (dispatch: DispatchMethod) => {
  dispatch({
    type: RESET,
    payload: null
  });
};
