import { GET_FASTPLAY_GAMES_BY_IDS } from '@pbl/pbl-react-core/lib/models/fast-play-ids/types';
import { TicketSubmissionPrizeDTOList } from '@pbl/pbl-react-core/lib/models/ticket';
import {
  CLEAR_ERROR,
  CLEAR_PRIZES,
  ENTER_TICKET,
  FETCH_DRAWS_BY_IDS,
  ITicketInitialState,
  RESET,
  TicketState,
  UPDATE_ATTR
} from '@pbl/pbl-react-core/lib/models/ticket/types';
import { FAILURE, REQUEST, SUCCESS } from '../../action-type.util';

const INITIAL_STATE: ITicketInitialState = {
  ticketNumber: '',
  validationCode: '',
  loading: false,
  errorMessage: null,
  prizes: new TicketSubmissionPrizeDTOList(),
  graphicImageUrl: null,
  drawTicketValidation: null,
  instantTicketValidation: null,
  instantCashResponse: null,
  onlineCashResponse: null,
  ticketValidation: null,
  gameName: undefined,
  promotionName: undefined,
  fastPlayGamesIds: [],
  tabKey: ''
};

export default (state: TicketState = INITIAL_STATE, action: any): TicketState => {
  switch (action.type) {
    case REQUEST(ENTER_TICKET):
    case REQUEST(GET_FASTPLAY_GAMES_BY_IDS):
      return {
        ...state,
        loading: true
      };
    case SUCCESS(ENTER_TICKET):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        prizes: action.payload && action.payload.prizes ? action.payload.prizes : state.prizes,
        graphicImageUrl: action.payload && action.payload.graphicImageUrl ? action.payload.graphicImageUrl : state.graphicImageUrl,
        gameName: action.payload.gameName
      };
    case SUCCESS(GET_FASTPLAY_GAMES_BY_IDS):
      return {
        ...state,
        loading: false,
        fastPlayGamesIds: action.payload
      };
    case FAILURE(GET_FASTPLAY_GAMES_BY_IDS):
      return {
        ...state,
        loading: false,
        fastPlayGamesIds: []
      };
    case FAILURE(ENTER_TICKET):
    case FAILURE(FETCH_DRAWS_BY_IDS):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload && action.payload.payload ? action.payload.payload : action.payload,
        graphicImageUrl: null
      };
    case SUCCESS(FETCH_DRAWS_BY_IDS):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        prizes: action.payload && action.payload.length > 0 ? action.payload.prizes : state.prizes,
        promotionName: action.payload?.promotionName
      };
    case UPDATE_ATTR:
      return {
        ...state,
        [action.payload.attr]: action.payload.value
      };
    case RESET:
      return {
        ...INITIAL_STATE
      };
    case CLEAR_ERROR:
      return {
        ...state,
        errorMessage: null
      };
    case CLEAR_PRIZES:
      return {
        ...state,
        prizes: new TicketSubmissionPrizeDTOList(),
        graphicImageUrl: null
      };
    default:
      return state;
  }
};
